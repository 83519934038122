const state = {
  userid: "",
  username: "",
  name: "",
  level: "",
  role: "",
  permissions: []
};
const mutations = {
  set_user(state, payload) {
    state.userid = payload.userid || "";
    state.username = payload.username || "";
    state.name = payload.name || "";
    state.level = payload.level || "";
    state.role = payload.role || "";
    state.permissions = payload.permissions || [];
  },
};
const actions = {
  setUser({ commit }, payload) {
    commit('set_user', payload);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
