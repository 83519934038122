<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="skus" placeholder="输入库存SKU/线上SKU查询，多个SKU空格隔开" enter-button="搜索" allow-clear
      @search="getData()" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="getData()">刷新数据</a-button>
    </a-space>
    <a-space>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导入">
              <a-menu-item key="bulkimport">模板导入库存SKU</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导入导出
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-button type="danger" @click="addProduct" :loading="initing">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增库存SKU
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'cover'">
        <div class="d-flex flex-column align-center">
          <div class="text-center" style="width:50px;">
            <a-tooltip v-if="record.cover" placement="rightBottom" color="#fff"
              :overlayStyle="{ width: '500px', height: '500px' }">
              <template #title>
                <img :src="record.cover" style="width:500px;height:500px;object-fit: contain;" />
              </template>
              <div class="image-header">
                <img :src="record.cover" style="object-fit: contain;" />
              </div>
            </a-tooltip>
            <div v-else class="image-header">
              <img :src="image_placeholder" style="object-fit: contain;" />
            </div>
          </div>
          <a v-if="record.product_url" :href="record.product_url" target="_blank" class="underline">
            {{ record.default_supplier?.platform }}
          </a>
        </div>
      </template>
      <template v-if="column.dataIndex === 'info'">
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="doCopy(record.sku)">复制</a>
          </template>
          <a href="javascript:;" @click="editProduct(record._id)">{{ record.sku }}</a>
        </a-tooltip>
        <p>{{ record.name_cn }}</p>
        <p style="color:#a0a3a6;">{{ record.number }}</p>
      </template>
      <template v-if="column.dataIndex === 'default_supplier'">
        <a v-if="record.default_supplier?.url" :href="record.default_supplier.url" target="_blank">
          {{ record.default_supplier?.name }}
          <LinkOutlined />
        </a>
        <p v-else>{{ record.default_supplier?.name }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editProduct(record._id)">编辑</a>
          <a href="javascript:;" class="text-warning" @click="copyProduct(record._id)">复制</a>
          <a-popconfirm title="确定要删除此SKU?" @confirm="removeProduct(record._id)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-drawer :title="`${productForm._id !== '' ? '编辑' : '新增'}库存SKU`" placement="right" v-model:visible="productDrawer"
    @close="cancelSave" width="70%" :closable="!saving" :maskClosable="!saving">
    <template #extra>
      <a-space>
        <a-button @click="cancelSave">取消</a-button>
        <a-button type="primary" @click="saveProduct">保存</a-button>
      </a-space>
    </template>
    <a-form ref="formRef" name="productForm" :model="productForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-row>
        <a-col :span="12">
          <a-card title="基本信息" size="small">
            <a-form-item label="库存SKU" name="sku" :rules="[{ required: true, message: '请输入库存SKU' }]">
              <a-input v-model:value="productForm.sku" placeholder="输入库存SKU编号" show-count :maxlength="100"
                :disabled="(productForm._id !== '' && !productForm.copy) || saving" />
            </a-form-item>
            <a-form-item label="中文名称" name="name_cn" :rules="[{ required: true, message: '请输入库存SKU中文名称' }]">
              <a-input v-model:value="productForm.name_cn" show-count :maxlength="100" :disabled="saving" />
            </a-form-item>
            <a-form-item label="英文名称" name="name_en">
              <a-input v-model:value="productForm.name_en" show-count :maxlength="100" :disabled="saving" />
            </a-form-item>
            <a-form-item label="线上SKU" name="vsku">
              <div class="d-flex flex-column">
                <a-input-group compact>
                  <a-input v-model:value="vsku" placeholder="输入线上SKU编号" show-count :maxlength="100" :disabled="saving"
                    style="width: calc(100% - 32px)" @keyup.enter="addVSku" />
                  <a-button type="primary" :disabled="saving" @click="addVSku">
                    <template #icon>
                      <PlusCircleOutlined />
                    </template>
                  </a-button>
                </a-input-group>
                <div class="d-flex flex-wrap" style="height:50px;border:1px solid #ddd;overflow-y:auto;">
                  <a-tag v-for="sku in productForm.vskus" :key="sku" color="purple" :closable="!saving"
                    @close="removeVSku(sku)" :disabled="saving" style="height:20px;">
                    {{ sku }}
                  </a-tag>
                </div>
              </div>
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="产品图片" size="small">
            <a-row>
              <a-col :span="14">
                选择图片(<span class="text-error">{{ productForm.images.length }}</span>/{{ MAX_IMAGE_COUNT }})
                <a-dropdown>
                  <template #overlay>
                    <ImagePicker :max_count="MAX_IMAGE_COUNT" :images="productForm.images"
                      @local-upload-success="onLocalUploadSuccess" @network-input-success="onNetworkInputSuccess"
                      @space-pick-success="onSpacePickSuccess" />
                  </template>
                  <a-button>
                    选择图片
                    <DownOutlined />
                  </a-button>
                </a-dropdown>
              </a-col>
              <a-col :span="10">
                <div class="d-flex flex-column justify-center align-center">
                  <span>剩余<span>{{ leftSize }}</span>MB</span>
                  <a-progress :percent="leftPercent" :showInfo="false" :strokeColor="leftColor" />
                </div>
              </a-col>
            </a-row>
            <a-row style="height:230px;overflow-y:auto;">
              <a-col :span="4" v-for="image in productForm.images" :key="image">
                <div style="width:105px;height:105px;position:relative;border:1px solid #e8e8e8;padding:2px;">
                  <a-image width="101px" height="101px" :src="image" style="object-fit: contain;height:100%;">
                    <template #placeholder>
                      <a-image :src="image_placeholder" width="101px" height="101px" :preview="false" />
                    </template>
                  </a-image>
                  <svg-icon v-if="image === productForm.cover" iconClass="zhutu"
                    style="position:absolute;top:0px;left:0px;height:17px;width:40px;" />
                  <div style="position:absolute;bottom:0;left:0;width:101px;height:20px;"
                    class="d-flex justify-space-between">
                    <template v-if="image !== productForm.cover">
                      <a-tooltip>
                        <template #title>设为主图</template>
                        <HomeOutlined @click="setCover(image)" style="font-size:20px;color:#1890ff;" />
                      </a-tooltip>
                      <a-tooltip>
                        <template #title>删除图片</template>
                        <DeleteOutlined @click="removeImage(image)" style="font-size:20px;color:#ff4d4f;" />
                      </a-tooltip>
                    </template>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-card title="申报信息" size="small">
            <a-form-item label="申报中文名" name="declare_name_cn">
              <a-input v-model:value="productForm.declare_name_cn" show-count :maxlength="100" :disabled="saving" />
            </a-form-item>
            <a-form-item label="申报英文名" name="declare_name_en">
              <a-input v-model:value="productForm.declare_name_en" show-count :maxlength="100" :disabled="saving" />
            </a-form-item>
            <a-form-item label="申报价值" name="declare_amount">
              <a-input v-model:value="productForm.declare_amount" type="number" addon-after="USD" :disabled="saving" />
            </a-form-item>
            <a-form-item label="申报重量" name="declare_weight">
              <a-input v-model:value="productForm.declare_weight" type="number" addon-after="克" :disabled="saving" />
            </a-form-item>
            <a-form-item label="报关编码" name="hs_code">
              <a-input v-model:value="productForm.hs_code" show-content :maxlength="10" :disabled="saving" />
            </a-form-item>
            <a-form-item label="申报类型">
              <a-checkbox-group v-model:value="productForm.declare_options">
                <a-checkbox value="has_battery">带电池</a-checkbox>
                <a-checkbox value="has_magnet">带磁</a-checkbox>
                <a-checkbox value="is_liquid">液体</a-checkbox>
                <a-checkbox value="is_powder">粉末</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card title="辅助信息" size="small">
            <a-form-item label="尺寸(长)" name="length">
              <a-input v-model:value="productForm.length" type="number" placeholder="长" addon-after="cm"
                :disabled="saving" />
            </a-form-item>
            <a-form-item label="尺寸(宽)" name="width">
              <a-input v-model:value="productForm.width" type="number" placeholder="宽" addon-after="cm"
                :disabled="saving" />
            </a-form-item>
            <a-form-item label="尺寸(高)" name="height">
              <a-input v-model:value="productForm.height" type="number" placeholder="高" addon-after="cm"
                :disabled="saving" />
            </a-form-item>
            <a-form-item label="采购价" name="purchase_price">
              <a-input v-model:value="productForm.purchase_price" type="number" placeholder="采购价" addon-after="元"
                :disabled="saving" />
            </a-form-item>
            <a-form-item label="销售价" name="sale_price">
              <a-input v-model:value="productForm.sale_price" type="number" placeholder="销售价" addon-after="元"
                :disabled="saving" />
            </a-form-item>
          </a-card>
        </a-col>
      </a-row>
      <a-row v-if="productForm._id">
        <a-col :span="24">
          <a-card size="small">
            <template #title>
              <div class="d-flex justify-space-between align-center">
                <span>供应商信息</span>
                <div>
                  <a href="javascript:;" @click="manageSupplier">
                    <SettingOutlined />
                    <span class="underline ml-1">供应商管理</span>
                  </a>
                  <a-button type="primary" @click="showSuppliers" :loading="supplierLoading" class="ml-2">
                    <template #icon>
                      <PlusCircleOutlined />
                    </template>增加关联供应商
                  </a-button>
                </div>
              </div>
            </template>
            <a-row :gutter="16" class="mb-2">
              <a-col :span="6"></a-col>
              <a-col :span="12">
                <div class="d-flex align-center">
                  <span class="font-weight-bold" style="width: 150px;">添加供货产品链接：</span>
                  <a-input-search v-model:value="purchase_product_url" placeholder="仅支持添加1688采购链接" enter-button="添加"
                    @search="addPurchase" :disabled="purchaseAdding" :loading="purchaseAdding" style="flex:1" />
                </div>
              </a-col>
              <a-col :span="6"></a-col>
            </a-row>
            <a-radio-group v-model:value="productForm.default_supplier_id" style="width:100%">
              <a-table :columns="supplierColumns" :data-source="productForm.suppliers" bordered
                :scroll="{ x: supplierWidth }" :loading="purchaseLoading" size="small">
                <template #bodyCell="{ record, column }">
                  <template v-if="column.dataIndex === 'product_url'">
                    <a-input v-if="record.platform !== '1688'" v-model:value="record.product_url" placeholder="采购链接" />
                    <a v-else-if="record.product_url" :href="record.product_url" target="_blank">
                      {{ record.product_url }}
                    </a>
                  </template>
                  <template v-if="column.dataIndex === 'product_specvalue'">
                    {{ record.product_specvalue?.join(' ') }}
                  </template>
                  <template v-if="column.dataIndex === 'is_default'">
                    <a-radio :value="record._id"></a-radio>
                  </template>
                  <template v-if="column.dataIndex === 'operation'">
                    <a v-if="productForm.default_supplier_id !== record._id" href="javascript:;" class="text-error"
                      @click="removeSupplier(record)">移除</a>
                    <a v-else-if="record.platform === '1688'" href="javascript:;" @click="editPurchase(record)">编辑</a>
                  </template>
                </template>
              </a-table>
            </a-radio-group>
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
  <a-modal v-model:visible="supplierModal" title="关联供应商" :footer="null" width="600px">
    <div class="d-flex align-center mb-2">
      <span style="width:80px;">供应商：</span>
      <a-input-search v-model:value="supplier_name" placeholder="请输入供应商名称" enter-button="搜索" allow-clear
        @search="getSuppliers" />
    </div>
    <a-table :columns="suppliercolumns" :data-source="suppliers" bordered :scroll="{ x: supplier_width }"
      :loading="supplierLoading"
      :pagination="{ current: supplierPage, pageSize: supplierLimit, total: supplierCount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { supplierPage = pagination.current; supplierLimit = pagination.pageSize; }"
      size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'operation'">
          <a href="javascript:;" @click="selectSupplier(record)">选择</a>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="purchaseModal" title="供应商采购信息详情" :maskClosable="false" :closable="!purchaseVerifying"
    width="800px" @cancel="() => purchaseModal = false">
    <a-row :gutter="16">
      <a-col :span="3">
        <a-tooltip placement="rightBottom" color="#fff" :overlayStyle="{ width: '500px', height: '500px' }">
          <template #title>
            <img :src="purchaseForm.cover" style="width:500px;height:500px;object-fit: contain;">
          </template>
          <div class="image-header">
            <img :src="purchaseForm.cover" style="object-fit: contain;" />
          </div>
        </a-tooltip>
      </a-col>
      <a-col :span="21">
        <div><span class="font-weight-bold">库存SKU：</span>{{ purchaseForm.sku }}</div>
        <div><span class="font-weight-bold">中文名称：</span>{{ purchaseForm.name_cn }}</div>
      </a-col>
      <a-divider style="margin:12px 0;" />
      <a-col :span="24">
        <div class="d-flex align-center">
          <span class="font-weight-bold" style="width: 80px;">供应商：</span>
          <span style="flex:1">{{ purchaseForm.supplier }}</span>
        </div>
      </a-col>
      <a-divider style="margin:12px 0;" />
      <a-col :span="24">
        <div class="d-flex align-center">
          <span class="font-weight-bold" style="width: 80px;">商品链接：</span>
          <a-input-search v-model:value="purchaseForm.product_url" placeholder="仅支持1688采购链接" enter-button="验证"
            @search="verifyPurchase" :disabled="purchaseVerifying" :loading="purchaseVerifying" style="flex:1" />
        </div>
      </a-col>
      <a-divider style="margin:12px 0;" />
      <a-col :span="24">
        <span class="font-weight-bold"> 1688商品子属性 </span>
      </a-col>
      <a-divider style="margin:12px 0;" />
      <template v-if="aeproductForm">
        <a-col :span="4">
          <a-tooltip placement="rightBottom" color="#fff" :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="aeproductForm.cover" style="width:500px;height:500px;object-fit: contain;" />
            </template>
            <div class="image-header">
              <img :src="aeproductForm.cover" style="object-fit: contain;" />
            </div>
          </a-tooltip>
        </a-col>
        <a-col :span="20">
          <p class="font-weight-bold">{{ aeproductForm.subject }}</p>
          <a-divider style="margin:12px 0;" />
          <div class="d-flex align-center">
            <span class="font-weight-bold" style="width: 80px;">已选属性：</span>
            <span style="flex:1">{{ aeproductForm.label }}</span>
          </div>
          <template v-for="(item, index) in aeproductForm.skus" :key="index">
            <a-divider style="margin:12px 0;" />
            <div class="d-flex align-center">
              <span class="font-weight-bold" style="width: 80px;">{{ item.cat }}：</span>
              <div class="d-flex flex-wrap" style="flex:1">
                <div v-for="sitem in item.list" :key="sitem.label"
                  :class="{ 'h3_border': true, 'h3_First': aeproductForm.labels.indexOf(sitem.label) > -1 }"
                  @click="selectPurchase(sitem, index)">
                  {{ sitem.label }}
                </div>
              </div>
            </div>
          </template>
        </a-col>
      </template>
    </a-row>
    <template #footer>
      <a-space class="d-flex align-center">
        <a-button @click="savePurchase" type="primary">确定</a-button>
        <a-button @click="() => purchaseModal = false">取消</a-button>
      </a-space>
    </template>
  </a-modal>
  <UploadTask ref="uploadTask" />
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, PlusCircleOutlined, HomeOutlined, UploadOutlined, DeleteOutlined, LinkOutlined, SettingOutlined, } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import ImagePicker from '@/components/ImagePicker.vue';
import UploadTask from '@/components/UploadTask.vue';
const columns = [
  { title: '缩略图', dataIndex: 'cover', width: 60 },
  { title: '商品信息', dataIndex: 'info', width: 200 },
  { title: '默认供应商', dataIndex: 'default_supplier', width: 200 },
  { title: '创建时间', dataIndex: 'create_time', width: 150 },
  { title: '操作', dataIndex: 'operation', width: 180, align: 'center', fixed: 'right' },
];
const supplierColumns = [
  { title: "平台", dataIndex: "platform", width: 100, },
  { title: "供应商", dataIndex: "name", width: 200, },
  { title: "商品链接", dataIndex: "product_url", width: 200, },
  { title: "商品属性", dataIndex: "product_specvalue", width: 150, },
  { title: "默认供应商", dataIndex: "is_default", width: 100 },
  { title: "操作", dataIndex: "operation", width: 120 },
];
const suppliercolumns = [
  { title: "平台", dataIndex: "platform", width: 100, },
  { title: "供应商名称", dataIndex: "name" },
  { title: "操作", dataIndex: "operation", width: 80 },
];
const MAX_IMAGE_COUNT = 20;
export default defineComponent({
  name: 'ProductSku',
  components: { DownOutlined, PlusCircleOutlined, HomeOutlined, UploadOutlined, DeleteOutlined, LinkOutlined, SettingOutlined, ImagePicker, UploadTask, },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref();
    const uploadTask = ref();
    const state = reactive({
      skus: "",
      productDrawer: false,
      initing: false,
      productForm: {},
      selectedRowKeys: [],
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      saving: false,
      choosing: false,
      vsku: "",
      supplierLoading: false,
      supplier_name: "",
      supplierPage: 1,
      supplierLimit: 10,
      supplierLength: 0,
      supplierCount: 0,
      suppliers: [],
      supplierModal: false,
      purchaseLoading: false,
      purchaseVerifying: false,
      purchaseModal: false,
      purchaseForm: {},
      aeproductForm: null,
      purchaseAdding: false,
      purchase_product_url: "",
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const supplierWidth = computed(() => proxy.$utils.twidth(supplierColumns));
    const supplier_width = computed(() => proxy.$utils.twidth(suppliercolumns));
    const totalSize = computed(() => proxy.$utils.d((store.state.imagepicker.total_size) / 1024 / 1024).toFixed(2));
    const usedSize = computed(() => proxy.$utils.d((store.state.imagepicker.used_size) / 1024 / 1024).toFixed(2));
    const leftSize = computed(() => proxy.$utils.d(totalSize.value).minus(usedSize.value).toFixed(2));
    const leftPercent = computed(() => Number(proxy.$utils.d(leftSize.value).dividedBy(totalSize.value).times(100).toFixed(2)));
    const leftColor = computed(() => {
      if (Number(leftPercent.value < 10)) return '#ff4d4f';
      else if (Number(leftPercent.value < 30)) return '#faad14';
      else if (Number(leftPercent.value < 50)) return '#1890ff';
      else return '#52c41a';
    });
    watch(() => state.skus, () => {
      if (!state.skus) getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.supplierPage, () => getSuppliers());
    watch(() => state.supplierLimit, () => {
      if (state.supplierPage !== 1) state.supplierPage = 1;
      else getSuppliers();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData(route.query.sku);
    });
    const getData = (sku) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/skus", { page: state.page, limit: state.limit, skus: sku || state.skus, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          if (sku && res.data.list.length === 1) {
            getProduct(res.data.list[0]._id);
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addProduct = () => {
      getProduct();
      getSpaceUsage();
    };
    const getProduct = (productid) => {
      state.initing = true;
      message.loading("", 0);
      proxy.$api.doAPI("/product/init", { productid }).then((res) => {
        state.initing = false;
        message.destroy();
        if (res.code === 0) {
          state.productForm = res.data.productForm;
          state.productDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.initing = false;
        message.destroy();
        console.error(err);
      });
    }
    const saveProduct = async () => {
      try {
        await formRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/product/save", { productForm: state.productForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.productDrawer = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const editProduct = (_id) => {
      getProduct(_id);
      getSpaceUsage();
    };
    const copyProduct = (productid) => {
      state.initing = true;
      message.loading("", 0);
      proxy.$api.doAPI("/product/copy", { productid }).then((res) => {
        state.initing = false;
        message.destroy();
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.initing = false;
        message.destroy();
        console.error(err);
      });
    }
    const removeProduct = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/remove", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          message.success("删除成功");
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
      state.productDrawer = false;
    }
    const addVSku = () => {
      if (state.vsku) {
        if (state.productForm.vskus.indexOf(state.vsku) === -1) {
          state.productForm.vskus = [...state.productForm.vskus, state.vsku];
          state.vsku = "";
        } else {
          message.error("线上SKU重复");
        }
      }
    }
    const removeVSku = (sku) => {
      state.productForm.vskus = _.filter(state.productForm.vskus, n => n !== sku);
    }
    const onLocalUploadSuccess = (data) => {
      console.log('onLocalUploadSuccess', data);
      let images = toRaw(state.productForm.images)
      images.push(data.url);
      state.productForm.images = _.uniq(images);
      if (!state.productForm.cover) state.productForm.cover = images[0];
    }
    const onNetworkInputSuccess = (data) => {
      console.log('onNetworkInputSuccess', data);
      let images = toRaw(state.productForm.images)
      images.push(...data.images);
      state.productForm.images = _.uniq(images);
      if (!state.productForm.cover) state.productForm.cover = images[0];
    }
    const onSpacePickSuccess = (data) => {
      console.log('onSpacePickSuccess', data);
      let images = toRaw(state.productForm.images)
      images.push(...data.images);
      state.productForm.images = _.uniq(images);
      if (!state.productForm.cover) state.productForm.cover = images[0];
    }
    const setCover = (image) => {
      let new_images = [], old_images = toRaw(state.productForm.images);
      for (let i = 0; i < old_images.length; i++) {
        if (old_images[i] !== image) {
          new_images.push(old_images[i]);
        }
      }
      state.productForm.images = _.uniq([image, ...new_images]);
      state.productForm.cover = image;
    }
    const removeImage = (image) => {
      state.productForm.images = _.filter(state.productForm.images, n => n !== image);
    }
    const getSpaceUsage = () => {
      store.dispatch("imagepicker/getSpaceUsage");
    }
    const manageSupplier = () => {
      state.productDrawer = false;
      router.push({ path: "/purchase/supplier/list" }).catch(console.error);
    }
    const showSuppliers = () => {
      state.supplier_name = "";
      state.supplierModal = true;
      getSuppliers();
    }
    const getSuppliers = () => {
      state.supplierLoading = true;
      proxy.$api.doAPI("/product/suppliers", { page: state.supplierPage, limit: state.supplierLimit, name: state.supplier_name }).then((res) => {
        state.supplierLoading = false;
        if (res.code === 0) {
          state.suppliers = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.supplierLength = res.data.length;
          state.supplierCount = res.data.count;
          if (state.supplierLength > 0 && state.supplierPage > state.supplierLength) state.supplierPage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.supplierLoading = false;
        console.error(err);
      });
    }
    const selectSupplier = (item) => {
      let existed = _.findWhere(state.productForm.suppliers, { _id: item._id });
      if (existed) return message.error("供应商已存在，请勿重复关联");
      state.productForm.suppliers.push({ _id: item._id, platform: item.platform, name: item.name });
      if (state.productForm.suppliers.length === 1) {
        state.productForm.default_supplier_id = item._id;
      }
      state.productForm.supplierids = _.pluck(state.productForm.suppliers, "_id");
      state.supplierModal = false;
    }
    const removeSupplier = (item) => {
      state.productForm.suppliers = _.filter(state.productForm.suppliers, n => n._id !== item._id);
      state.productForm.supplierids = _.filter(state.productForm.supplierids, n => n !== item._id);
    }
    const addPurchase = () => {
      if (!state.purchase_product_url) return message.error("1688采购链接不能为空");
      state.purchaseAdding = true;
      proxy.$api.doAPI("/product/ae1688add", { productid: state.productForm._id, product_url: state.purchase_product_url }).then((res) => {
        state.purchaseAdding = false;
        if (res.code === 0) {
          message.success("添加成功");
          state.purchase_product_url = "";
          getProduct(state.productForm._id);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.purchaseAdding = false;
        console.error(err);
      });
    }
    const editPurchase = (item) => {
      if (item.platform === '1688') {
        state.purchaseLoading = true;
        proxy.$api.doAPI("/product/ae1688get", { productid: state.productForm._id, supplier: item }).then((res) => {
          state.purchaseLoading = false;
          if (res.code === 0) {
            state.purchaseForm = res.data.purchaseForm;
            state.aeproductForm = res.data.aeproductForm;
            state.purchaseModal = true;
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.purchaseLoading = false;
          console.error(err);
        });
      }
    }
    const verifyPurchase = () => {
      state.purchaseVerifying = true;
      proxy.$api.doAPI("/product/ae1688set", state.purchaseForm).then((res) => {
        state.purchaseVerifying = false;
        if (res.code === 0) {
          state.purchaseForm = {
            ...state.purchaseForm,
            ...res.data.purchaseForm,
          };
          state.aeproductForm = res.data.aeproductForm;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.purchaseVerifying = false;
        console.error(err);
      });
    }
    const selectPurchase = (item, index) => {
      console.log('selectPurchase', item, index);
      state.aeproductForm.labels[index] = item.label;
      let skuInfo = _.find(state.aeproductForm.skuInfos, n => n.labels.join('_') === state.aeproductForm.labels.join('_'));
      state.aeproductForm.label = skuInfo.labels.join(' ');
      state.aeproductForm.value = skuInfo.value;
      state.aeproductForm.cover = skuInfo.cover;
      state.purchaseForm.product_specid = skuInfo.value;
      state.purchaseForm.product_specvalue = skuInfo.labels;
      if (skuInfo.cover) state.purchaseForm.cover = skuInfo.cover;
    }
    const savePurchase = () => {
      console.log('savePurchase', toRaw(state.purchaseForm));
      state.productForm.suppliers = _.map(state.productForm.suppliers, n => {
        let supplier = { ...n };
        if (n._id === state.purchaseForm.supplierid) {
          _.extend(supplier, _.pick(state.purchaseForm, ["product_id", "product_specid", "product_specvalue", "product_url"]))
        }
        return supplier;
      });
      if (state.purchaseForm.cover) {
        state.productForm.cover = state.purchaseForm.cover;
        state.productForm.images[0] = state.purchaseForm.cover;
      }
      state.purchaseModal = false;
    }
    const handleBulkClick = ({ key }) => {
      if (state.selectedRowKeys.length === 0) return message.warn("未选择商品");
      console.log('handleBulkClick', key);
      switch (key) {

      }
    }
    const handleExportClick = ({ key }) => {
      console.log('handleExportClick', key);
      switch (key) {
        case "bulkimport":
          uploadTask.value.showUpload({ title: "模板导入库存SKU", file: "库存SKU模板.xlsx", type: "import_createsku" });
          break;
        case "exportselected": break;
        case "exportall": break;
      }
    }
    return {
      ...toRefs(state),
      locale,
      MAX_IMAGE_COUNT,
      formRef,
      uploadTask,
      columns,
      supplierColumns,
      suppliercolumns,
      width,
      supplierWidth,
      supplier_width,
      leftSize,
      leftPercent,
      leftColor,
      getData,
      addProduct,
      saveProduct,
      editProduct,
      copyProduct,
      removeProduct,
      cancelSave,
      addVSku,
      removeVSku,
      onLocalUploadSuccess,
      onNetworkInputSuccess,
      onSpacePickSuccess,
      setCover,
      removeImage,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
      manageSupplier,
      showSuppliers,
      getSuppliers,
      selectSupplier,
      removeSupplier,
      addPurchase,
      editPurchase,
      verifyPurchase,
      selectPurchase,
      savePurchase,
      handleBulkClick,
      handleExportClick,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.h3_border {
  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  min-width: 60px;
  border: 1px solid #cbcbcb;
  height: 30px;
  margin-left: 10px;
  margin-top: 5px;
  cursor: pointer;
  padding: 0 4px;
}

.h3_First {
  border: 2px solid #ff6a00;
}

p {
  margin: 0;
  padding: 0;
}
</style>
