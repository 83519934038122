<template>
  <a-tabs v-model:activeKey="activeKey" type="card" @change="handclick">
    <a-tab-pane :key="1" tab="按商品">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="create_time">下单时间</a-select-option>
              <a-select-option value="sendtime">入库时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }" size="small">
          <template #title>
            <div class="theader">
              <span class="title">按商品统计</span>
              <a-button type="primary">导出统计报表</a-button>
            </div>
          </template>
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'pic'">
              <a-image :src="text || image_placeholder" :width="50" :height="50" style="object-fit: contain;height:100%;"
                :preview="false" />
            </template>
            <template v-if="column.dataIndex === 'info'">
              <p style="color:dodgerblue;">{{ text?.sku || "-" }}</p>
              <p>{{ text?.name_cn || "" }}</p>
            </template>
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看明细</a>
              </a-space>
            </template>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane :key="2" tab="按供应商">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="create_time">下单时间</a-select-option>
              <a-select-option value="sendtime">入库时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns1" :data-source="data" bordered :scroll="{ x: width1 }" size="small">
          <template #title>
            <div class="theader">
              <span class="title">按供应商统计</span>
              <a-button type="primary">导出统计报表</a-button>
            </div>
          </template>
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看明细</a>
              </a-space>
            </template>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane :key="3" tab="按采购员">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="create_time">下单时间</a-select-option>
              <a-select-option value="sendtime">入库时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns2" :data-source="data" bordered :scroll="{ x: width2 }" size="small">
          <template #title>
            <div class="theader">
              <span class="title">按采购员统计</span>
              <a-button type="primary">导出统计报表</a-button>
            </div>
          </template>
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看明细</a>
              </a-space>
            </template>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane :key="4" tab="按下单员">
      <div class="header">
        <a-form :model="formState" name="form_query" layout="inline" autocomplete="off">
          <a-form-item>
            <a-select v-model:value="datetype">
              <a-select-option value="create_time">下单时间</a-select-option>
              <a-select-option value="sendtime">入库时间</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table :columns="columns3" :data-source="data" bordered :scroll="{ x: width3 }" size="small">
          <template #title>
            <div class="theader">
              <span class="title">按下单员统计</span>
              <a-button type="primary">导出统计报表</a-button>
            </div>
          </template>
          <template #bodyCell="{ column, text }">
            <template v-if="column.dataIndex === 'ordercount'">
              <a>{{ text }}</a>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <a href="javascript:;">查看明细</a>
              </a-space>
            </template>
          </template>
          <template #footer>合计</template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'pic', width: 80, align: 'center' },
  { title: '商品信息', dataIndex: 'info', width: 120, align: 'center' },
  { title: '采购数量', dataIndex: 'ordercount', width: 120, align: 'center' },
  { title: '入库数量', dataIndex: 'incount', width: 120, align: 'center' },
  { title: '损耗量', dataIndex: 'losscount', width: 120, align: 'center' },
  { title: '差额总计', dataIndex: 'margincount', width: 120, align: 'center' },
  { title: '金额总计', dataIndex: 'total', width: 120, align: 'center' },
  { title: '最后入库日期', dataIndex: 'instoretime', width: 150, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center' },
];
const columns1 = [
  { title: '供应商', dataIndex: 'supplier', width: 150, align: 'center' },
  { title: 'SKU数量', dataIndex: 'skucount', width: 120, align: 'center' },
  { title: '采购数量', dataIndex: 'ordercount', width: 120, align: 'center' },
  { title: '入库数量', dataIndex: 'incount', width: 120, align: 'center' },
  { title: '损耗量', dataIndex: 'losscount', width: 120, align: 'center' },
  { title: '差额总计', dataIndex: 'margincount', width: 120, align: 'center' },
  { title: '金额总计', dataIndex: 'total', width: 120, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center' },
];
const columns2 = [
  { title: '采购员', dataIndex: 'buyer', width: 100, align: 'center' },
  { title: 'SKU数量', dataIndex: 'skucount', width: 120, align: 'center' },
  { title: '采购数量', dataIndex: 'ordercount', width: 120, align: 'center' },
  { title: '入库数量', dataIndex: 'incount', width: 120, align: 'center' },
  { title: '损耗量', dataIndex: 'losscount', width: 120, align: 'center' },
  { title: '差额总计', dataIndex: 'margincount', width: 120, align: 'center' },
  { title: '金额总计', dataIndex: 'total', width: 120, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center' },
];
const columns3 = [
  { title: '下单员', dataIndex: 'buyer', width: 100, align: 'center' },
  { title: 'SKU数量', dataIndex: 'skucount', width: 120, align: 'center' },
  { title: '采购数量', dataIndex: 'ordercount', width: 120, align: 'center' },
  { title: '入库数量', dataIndex: 'incount', width: 120, align: 'center' },
  { title: '损耗量', dataIndex: 'losscount', width: 120, align: 'center' },
  { title: '差额总计', dataIndex: 'margincount', width: 120, align: 'center' },
  { title: '金额总计', dataIndex: 'total', width: 120, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center' },
];
export default defineComponent({
  name: 'PurchaseManageStatics',
  components: {

  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      datetype: "create_time",
      activeKey: 1,
      daterange: [],
      data: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    const width3 = computed(() => proxy.$utils.twidth(columns3));
    onActivated(() => {
      getData();
    });
    const handclick = () => {
      state.datetype = "create_time",
      state.daterange = [];
      state.data = [];
      getData();
    };
    const getData = () => {
      proxy.$api.doAPI("/purchaseorder/statistics", { type: state.activeKey, datetype: state.datetype, daterange: state.daterange }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns,
      width,
      columns1,
      width1,
      columns2,
      width2,
      columns3,
      width3,
      handclick,
      getData,
      image_placeholder: proxy.$constant.image_placeholder,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

:deep(.ant-table-measure-row) {
  visibility: collapse;
}

p {
  text-align: left;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>