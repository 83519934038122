<template>
  <div class="platform">
    <a-card>
      <a-card-grid v-for="item in platforms" :key="item.value"
        :class="`click ${platform === item.value ? 'pactive' : ''}`" style="width:20%;text-align:center;" hoverable
        @click="() => platform = item.value">
        {{ item.label }}
      </a-card-grid>
    </a-card>
  </div>
  <div class="authorizelist">
    <a-card title="授权店铺">
      <div class="d-flex justify-space-between align-center mb-2">
        <a-space>
          <a-button type="primary" @click="getData" :loading="dataLoading">
            刷新数据
          </a-button>
        </a-space>
        <p v-if="UnauthorizedShops" class="text-error">
          店铺授权异常：{{ UnauthorizedShops }}
        </p>
        <a-button type="primary" @click="() => addAuth()" :loading="addAuthLoading">
          <template #icon>
            <PlusCircleOutlined />
          </template>添加授权
        </a-button>
      </div>
      <a-radio-group v-if="platform === 'shopee'" v-model:value="shop_type" @change="getData">
        <a-radio value="1">普通店铺</a-radio>
        <a-radio value="2">全球店铺</a-radio>
      </a-radio-group>
      <a-table bordered :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: height }"
        :pagination="false" size="small">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'selling_partner_id'">
            <span v-if="record.selling_partner_id">{{ record.selling_partner_id }}</span>
            <span v-else class="text-error">未授权</span>
          </template>
          <template v-if="column.dataIndex === 'account'">
            <span v-if="record.account">{{ record.account }}</span>
            <span v-else class="text-error">未授权</span>
          </template>
          <template v-if="column.dataIndex === 'region'">
            <span>{{ getregionname(record) }}</span>
          </template>
          <template v-if="column.dataIndex === 'mailbox'">
            <template v-if="record.mailbox">
              <div class="d-flex align-center">
                <span>{{ record.mailbox.address }}</span>
                <CopyOutlined title="复制邮箱地址" class="ml-1 text-info" @click="doCopy(record.mailbox.address)" />
              </div>
              <!-- <a-button type="primary" size="small" @click="readMailBox(record._id)" :loading="mailbox_reading">
                读取邮件
              </a-button> -->
            </template>
            <a-button v-else type="primary" @click="configMailBox(record._id)" :loading="mailbox_configing">
              配置邮件收发
            </a-button>
          </template>
          <template v-if="column.dataIndex === 'authtime'">
            <p class="text-success">{{ record.authtime }}</p>
            <p class="text-error" v-if="record.authexpiretime"> {{ record.authexpiretime }} </p>
            <template v-if="record.enabled">
              <template v-if="record.platform === 'amz' || record.platform === 'smt' || record.platform === 'tiktok'">
                <a v-if="record.authorized" href="javascript:;" class="text-error"
                  @click="bindAuth(record._id)">重新授权</a>
                <a v-else href="javascript:;" @click="bindAuth(record._id)">立即授权</a>
              </template>
            </template>
          </template>
          <template v-if="column.dataIndex === 'enabled'">
            <a-switch v-model:checked="record.enabled" checked-children="启用" un-checked-children="停用"
              @change="(checked) => changeState(checked, record)" :loading="switching[record._id]" />
            <p v-if="record.auth_status === 'Unauthorized'" class="text-error">授权异常</p>
          </template>
          <template v-if="column.dataIndex === 'ad_authtime'">
            <span v-if="record.ad_authtime">
              {{ record.ad_authtime }}
              <a href="javascript:;" class="text-error" @click="bindAuthAD(record._id)">重新授权</a>
            </span>
            <span v-else>
              <p>未授权，无法使用广告功能</p>
              <a href="javascript:;" @click="bindAuthAD(record._id)">立即授权</a>
            </span>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <template v-if="record.platform === 'amz' || record.platform === 'ozon' || record.platform === 'walmart'">
              <a href="javascript:;" @click="editAuth(record)">编辑</a>
            </template>
          </template>
          <template v-if="column.dataIndex === 'accreditoperator'">
            <p v-if="record.operators !== ''">{{ record.operators }}</p>
            <a-space v-if="record.enabled">
              <a href="javascript:;" @click="bindOperator(record._id)">绑定</a>
            </a-space>
          </template>
          <template v-if="column.dataIndex === 'accreditwarehouse'">
            <p v-if="record.warehouse">{{ record.warehouse }}</p>
            <a-space v-if="record.enabled">
              <a-dropdown v-if="queryForm.state === 0 || queryForm.state === 4">
                <a class="ant-dropdown-link" @click.prevent>
                  绑定
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu @click="({ key }) => handleMenuClick(key, record._id)">
                    <a-menu-item v-for="witem in warehouselist" :key="witem._id">
                      <a href="javascript:;" class="underline text-info">{{ witem.name }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-card>
  </div>
  <a-modal v-model:visible="preAuthModal" :title="preAuthForm._id ? '编辑授权' : '添加授权'" @ok="preAuth" @cancel="cancelSave"
    ok-text="确认" cancel-text="取消" :confirm-loading="preAuthLoading" :cancel-button-props="{ disabled: preAuthLoading }"
    :maskClosable="false" :closable="!preAuthLoading">
    <a-form ref="formRef" name="preAuthForm" :model="preAuthForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="授权平台" name="platform">
        {{ getplatformname(preAuthForm.platform) }}
      </a-form-item>
      <a-form-item label="平台账号" name="account_username" :rules="[{ required: true, message: '请输入平台账号' }]">
        <a-input v-model:value="preAuthForm.account_username" placeholder="卖家在平台上的账号" :disabled="preAuthLoading" />
      </a-form-item>
      <a-form-item label="店铺名称" name="name" :rules="[{ required: true, message: '请输入店铺名称' }]">
        <a-input v-model:value="preAuthForm.name" placeholder="卖家自己定义，以做区分" :disabled="preAuthLoading" />
      </a-form-item>
      <template v-if="preAuthForm.platform === 'amz' && !preAuthForm._id">
        <a-form-item label="区域" name="region" :rules="[{ required: true, message: '请选择区域' }]">
          <a-select v-model:value="preAuthForm.region" :options="seller_regions" show-search
            :filter-option="$filters.filterOption" :disabled="preAuthLoading" @change="onChangeRegion" />
        </a-form-item>
        <a-form-item label="站点" name="sellercenterids" :rules="[{ required: true, message: '请选择站点' }]">
          <div class="d-flex flex-column">
            <a-checkbox v-model:checked="preAuthForm.checkAll" :disabled="preAuthLoading" @change="onCheckAllChange">
              全选
            </a-checkbox>
            <a-checkbox-group v-model:value="preAuthForm.sellercenterids" :options="region_seller_centers"
              :disabled="preAuthLoading" @change="onCheckChange" />
          </div>
        </a-form-item>
      </template>
      <template v-if="preAuthForm.platform === 'tiktok'">
        <a-form-item label="授权站点" name="region" :rules="[{ required: true, message: '请选择授权站点' }]">
          <a-select v-model:value="preAuthForm.region" :options="tt_auth_sites" placeholder="请选择授权站点" show-search
            :filter-option="$filters.filterOption" :disabled="preAuthLoading" @change="onChangeAuthSite" />
        </a-form-item>
        <a-form-item v-if="preAuthForm.region === 'US'" label="站点类型" name="site_type"
          :rules="[{ required: true, message: '请选择站点类型' }]">
          <a-select v-model:value="preAuthForm.site_type" :options="tt_site_types" placeholder="请选择站点类型" show-search
            :filter-option="$filters.filterOption" :disabled="preAuthLoading" @change="onChangeSiteType" />
        </a-form-item>
        <a-form-item v-if="preAuthForm.site_type === 'native_land'" label="受益人类型" name="benefit_type"
          :rules="[{ required: true, message: '请选择受益人类型' }]">
          <a-select v-model:value="preAuthForm.benefit_type" :options="tt_benefit_types" placeholder="请选择受益人类型"
            show-search :filter-option="$filters.filterOption" :disabled="preAuthLoading" />
        </a-form-item>
      </template>
      <template v-if="preAuthForm.platform === 'ozon'">
        <a-form-item label="Client-Id" name="client_id" :rules="[{ required: true, message: '请输入用户识别号' }]">
          <a-input v-model:value="preAuthForm.client_id" placeholder="用户识别号" :disabled="preAuthLoading" />
        </a-form-item>
        <a-form-item label="Api-Key" name="api_key" :rules="[{ required: true, message: '请输入API-密钥' }]">
          <a-input v-model:value="preAuthForm.api_key" placeholder="API-密钥" :disabled="preAuthLoading" />
        </a-form-item>
      </template>
      <template v-if="preAuthForm.platform === 'walmart'">
        <a-form-item label="站点" name="region" :rules="[{ required: true, message: '请选择站点' }]">
          <a-select v-model:value="preAuthForm.region" :options="walmart_regions" placeholder="请选择站点" show-search
            :filter-option="$filters.filterOption" :disabled="preAuthLoading || !!preAuthForm._id" />
        </a-form-item>
        <a-form-item label="Client ID" name="client_id" :rules="[{ required: true, message: '请输入Client ID' }]">
          <a-input v-model:value="preAuthForm.client_id" placeholder="Client ID" :disabled="preAuthLoading" />
        </a-form-item>
        <a-form-item label="Client Secret" name="client_secret"
          :rules="[{ required: true, message: '请输入Client Secret' }]">
          <a-input v-model:value="preAuthForm.client_secret" placeholder="Client Secret" :disabled="preAuthLoading" />
        </a-form-item>
        <a-form-item label="Channel Type" name="channel_type"
          :rules="[{ required: preAuthForm.region === 'CA', message: '请输入Channel Type' }]">
          <a-input v-model:value="preAuthForm.channel_type" placeholder="Channel Type" :disabled="preAuthLoading" />
        </a-form-item>
      </template>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="authModal" title="授权绑定店铺" @ok="confirmAuth" ok-text="确认" cancel-text="取消"
    :confirm-loading="authing" :cancel-button-props="{ disabled: authing }" :maskClosable="false" :closable="!authing">
    <a-form name="authForm" :model="authForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item label="授权平台" name="platform">
        {{ getplatformname(authForm.platform) }}
      </a-form-item>
      <a-form-item label="授权店铺" name="shopname">
        {{ authForm.shopname }}
      </a-form-item>
      <a-form-item label="平台账号" name="account_username">
        {{ authForm.account_username }}
      </a-form-item>
      <template v-if="authForm.platform === 'amz'">
        <a-form-item label="区域" name="region">
          {{ authForm.region }}
        </a-form-item>
        <a-form-item label="站点" name="sellercenter">
          {{ authForm.sellercenter }}
        </a-form-item>
      </template>
      <template v-if="authForm.platform === 'tiktok'">
        <a-form-item label="授权站点" name="region">
          {{ authForm.region }}
        </a-form-item>
        <a-form-item v-if="authForm.site_type" label="站点类型" name="site_type">
          {{ authForm.site_type }}
        </a-form-item>
        <a-form-item v-if="authForm.benefit_type" label="受益人类型" name="benefit_type">
          {{ authForm.benefit_type }}
        </a-form-item>
      </template>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="authADModal" title="授权店铺广告" @ok="confirmAuthAD" ok-text="确认" cancel-text="取消"
    :confirm-loading="authing" :cancel-button-props="{ disabled: authing }" :maskClosable="false" :closable="!authing">
    <a-form name="authADForm" :model="authADForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
      autocomplete="off">
      <a-form-item label="授权平台" name="platform">
        {{ getplatformname(authADForm.platform) }}
      </a-form-item>
      <a-form-item label="授权店铺" name="shopname">
        {{ authADForm.shopname }}
      </a-form-item>
      <a-form-item label="平台账号" name="account_username">
        {{ authADForm.account_username }}
      </a-form-item>
      <a-form-item label="区域" name="region">
        {{ authADForm.region }}
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="bindoperatorModel" title="绑定操作人" ok-text="确认" cancel-text="取消" @ok="confirmBindOperator"
    @cancel="cancelBindOperator" :confirm-loading="operatorBinding" :cancel-button-props="{ disabled: operatorBinding }"
    :maskClosable="false" :closable="!operatorBinding" :width="600">
    <a-checkbox-group v-model:value="bindOperatorForm.operators">
      <a-row justify="space-between">
        <a-col :key="index" v-for="(item, index) in stafflist" style="padding: 10px 14px;">
          <a-checkbox :value="item.value" @change="changecheckbox(item.value)">{{ item.name }}</a-checkbox>
        </a-col>
      </a-row>
    </a-checkbox-group>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, CopyOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
export default defineComponent({
  name: "ShopsManageAuthorize",
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, CopyOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      platform: route.query._from || "amz",
      platforms: [],
      shop_type: "1",
      dataLoading: false,
      data: [],
      addAuthLoading: false,
      seller_regions: [],
      seller_centers: [],
      region_seller_centers: [],
      tt_auth_sites: [],
      tt_site_types: [],
      tt_benefit_types: [],
      walmart_regions: [],
      preAuthModal: false,
      preAuthForm: {},
      preAuthLoading: false,
      authModal: false,
      authForm: {},
      authing: false,
      authADModal: false,
      authADForm: {},
      switching: {},
      columns: [],
      height: 600,
      mailbox_configing: false,
      mailbox_reading: false,
      stafflist: [],//账号列表
      bindoperatorModel: false,
      operatorBinding: false,
      bindOperatorForm: {
        shopid: null,
        operators: [],
      },
      delOperators: [],
      warehouselist: [],
      bindwarehouseModel: false,
      warehouseBinding: false,
    });
    const width = computed(() => proxy.$utils.twidth(state.columns));
    const UnauthorizedShops = computed(() => {
      return _.map(_.filter(state.data, n => n.auth_status === 'Unauthorized'), n => {
        return `${n.gname}-${n.authregion}`;
      }).join('、');
    })
    watch(() => state.platform, (platform) => {
      getData()
      if (platform === 'tiktok' || platform === 'walmart') {
        addAuth(true);
      }
    });
    onActivated(() => {
      // console.log("onActivated");
      if (route.query.state) {
        let _state = route.query.state.split('_');
        state.platform = _state[1];
        if (state.platform === "shopee") {
          //普通店铺
          if (route.query.shop_id) state.shop_type = "1";
          //全球店铺
          if (route.query.main_account_id) state.shop_type = "2";
        }
      } else if (state.platform === 'amz' && route.query.code) {
        let zdterp_adauth_shopid = localStorage.getItem("zdterp_adauth_shopid");
        if (zdterp_adauth_shopid) {
          state.platform = 'amz';
        }
      }
      getData();
      // console.log("route.query", route);
      if (route.query.state || state.platform === 'tiktok') {//TODO TikTok 测试
        showAuth(route.query);
      } else if (state.platform === 'amz' && route.query.code) {
        let zdterp_adauth_shopid = localStorage.getItem("zdterp_adauth_shopid");
        if (zdterp_adauth_shopid) {
          showAuthAD(zdterp_adauth_shopid)
        }
      }
    });
    const makeTableData = (data) => {
      let columns = [];
      let list = [];
      if (state.platform === 'amz') {
        for (let i = 0; i < data.length; i++) {
          let { shops } = data[i];
          for (let j = 0; j < shops.length; j++) {
            list.push({
              ...data[i],
              ...shops[j],
              _ids: _.pluck(shops, "_id"),
              rowSpan: j == 0 ? shops.length : 0,
            });
          }
        }
        console.log(list);
        columns.push(
          {
            title: "店铺名称", dataIndex: "name", width: 120,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          {
            title: "平台账号", dataIndex: "account_username", width: 180,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          {
            title: "店铺ID", dataIndex: "selling_partner_id", width: 120,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          { title: "授权地区", dataIndex: "authregion", width: 100, },
          { title: "授权时间(基础API)", dataIndex: "authtime", width: 120, },
          { title: "状态(基础API)", dataIndex: "enabled", width: 120, },
          { title: "绑定操作人", dataIndex: "accreditoperator", width: 120, },
          // { title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120, },
          {
            title: "授权时间(广告API)", dataIndex: "ad_authtime", width: 200,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
          {
            title: "操作", dataIndex: "operation", width: 100,
            customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
          },
        )
      } else if (state.platform === 'smt') {
        list = data;
        columns.push(
          { title: "店铺名称", dataIndex: "name", width: 100, },
          { title: "平台账号", dataIndex: "account_username", width: 150, },
          { title: "授权账号", dataIndex: "account", width: 150, },
          { title: "授权时间", dataIndex: "authtime", width: 120, },
          { title: "状态", dataIndex: "enabled", width: 120, },
          { title: "绑定操作人", dataIndex: "accreditoperator", width: 120, },
          // { title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120, },
          { title: "操作", dataIndex: "operation", width: 100, fixed: "right", }
        )
      } else if (state.platform === 'tiktok') {
        list = data;
        columns.push(
          { title: "店铺名称", dataIndex: "name", width: 120, },
          { title: "平台账号", dataIndex: "account_username", width: 150, },
          { title: "授权站点", dataIndex: "region", width: 150, },
          { title: "授权时间", dataIndex: "authtime", width: 120, },
          { title: "状态", dataIndex: "enabled", width: 120, },
          { title: "绑定操作人", dataIndex: "accreditoperator", width: 120, },
          // { title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120, },
          { title: "操作", dataIndex: "operation", width: 100, fixed: "right", }
        )
      } else if (state.platform === 'ozon') {
        list = data;
        columns.push(
          { title: "店铺名称", dataIndex: "name", width: 120, },
          { title: "平台账号", dataIndex: "account_username", width: 150, },
          { title: "Client-Id", dataIndex: "client_id", width: 150, },
          { title: "Api-Key", dataIndex: "api_key", width: 150, },
          { title: "授权时间", dataIndex: "authtime", width: 120, },
          { title: "状态", dataIndex: "enabled", width: 120, },
          { title: "绑定操作人", dataIndex: "accreditoperator", width: 120, },
          // { title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120, },
          { title: "操作", dataIndex: "operation", width: 100, fixed: "right", }
        )
      } else if (state.platform === 'walmart') {
        list = data;
        columns.push(
          { title: "店铺名称", dataIndex: "name", width: 120, },
          { title: "平台账号", dataIndex: "account_username", width: 150, },
          { title: "站点", dataIndex: "region", width: 150, },
          { title: "授权时间", dataIndex: "authtime", width: 120, },
          { title: "收发邮件邮箱地址", dataIndex: "mailbox", width: 180, },
          // { title: "Client ID", dataIndex: "client_id", width: 150, },
          // { title: "Client Secret", dataIndex: "client_secret", width: 150, },
          // { title: "Channel Type", dataIndex: "channel_type", width: 150, },
          { title: "状态", dataIndex: "enabled", width: 120, },
          { title: "绑定操作人", dataIndex: "accreditoperator", width: 120, },
          // { title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120, },
          { title: "操作", dataIndex: "operation", width: 100, fixed: "right", }
        )
      } else if (state.platform === 'shopee') {
        if (state.shop_type === "1") {
          for (let i = 0; i < data.length; i++) {
            let { is_cb, sip_affi_shops } = data[i];
            if (sip_affi_shops?.length > 0) {
              for (let j = 0; j < sip_affi_shops.length; j++) {
                list.push({
                  ...data[i],
                  is_cb: is_cb ? '是' : '否',
                  ...sip_affi_shops[j],
                  rowSpan: j == 0 ? sip_affi_shops.length : 0,
                });
              }
            } else {
              list.push({
                ...data[i],
                is_cb: is_cb ? '是' : '否',
                rowSpan: 1,
              });
            }
          }
          columns.push(
            {
              title: "店铺名称", dataIndex: "gname", width: 120,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "平台账号", dataIndex: "account_username", width: 180,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "站点", dataIndex: "_region", width: 100,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "是否跨境", dataIndex: "is_cb", width: 100,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "SHOP ID", dataIndex: "selling_partner_id", width: 120,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "SIP 子店铺", dataIndex: "sip_affi_shops",
              children: [
                { title: "站点", dataIndex: "region", width: 100, },
                { title: "SHOP ID", dataIndex: "affi_shop_id", width: 120, },
              ]
            },
            {
              title: "状态", dataIndex: "enabled", width: 120,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "授权时间", dataIndex: "authtime", width: 150,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "绑定操作人", dataIndex: "accreditoperator", width: 120,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            // {
            //   title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120,
            //   customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            // },
            {
              title: "操作", dataIndex: "operation", width: 100,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
          )
        }
        if (state.shop_type === "2") {
          for (let i = 0; i < data.length; i++) {
            let { shops } = data[i];
            for (let j = 0; j < shops.length; j++) {
              list.push({
                ...data[i],
                ...shops[j],
                _ids: _.pluck(shops, "_id"),
                rowSpan: j == 0 ? shops.length : 0,
              });
            }
          }
          columns.push(
            {
              title: "店铺名称", dataIndex: "gname", width: 120,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "平台账号", dataIndex: "account_username", width: 180,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "MERCHANT ID", dataIndex: "merchant_id", width: 120,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
            {
              title: "关联普通店铺", dataIndex: "shops",
              children: [
                { title: "店铺名称", dataIndex: "name", width: 100, },
                { title: "站点", dataIndex: "_region", width: 120, },
                { title: "SHOP ID", dataIndex: "selling_partner_id", width: 120, },
                { title: "绑定操作人", dataIndex: "accreditoperator", width: 120, },
                // { title: "绑定仓库", dataIndex: "accreditwarehouse", width: 120, },
                { title: "状态", dataIndex: "enabled", width: 120, },
                { title: "授权时间", dataIndex: "authtime", width: 150, },
              ]
            },
            {
              title: "操作", dataIndex: "operation", width: 100,
              customCell: (record, rowIndex, column) => ({ rowSpan: record.rowSpan || 0 }),
            },
          )
        }
      }
      state.columns = columns;
      state.data = _.map(list, n => {
        let { _id } = n;
        let operators = "";
        _.map(state.stafflist, s => {
          if (_.filter(s.shopids, j => j === _id).length > 0) {
            operators += s.name + "，"
          }
        });
        if (operators !== "") operators = operators.slice(0, -1);
        return {
          ...n,
          operators
        }
      });
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/shop/list", { platform: state.platform, shop_type: state.shop_type }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.platforms = res.data.platforms;
          state.stafflist = res.data.seller_list;
          state.warehouselist = res.data.warehouselist;
          makeTableData(res.data.list);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addAuth = (hide = false) => {
      state.addAuthLoading = true;
      proxy.$api.doAPI("/shop/addauth", { platform: state.platform }).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          let preAuthForm = {
            platform: state.platform,
            account_username: '',
            name: '',
          };
          if (state.platform === 'amz') {
            preAuthForm.checkAll = true;
            if (res.data.seller_regions?.length > 0) {
              state.seller_regions = res.data.seller_regions;
              preAuthForm.region = res.data.seller_regions[0].value;
            } else {
              state.seller_regions = [];
            }
            if (res.data.seller_centers?.length > 0) {
              state.seller_centers = res.data.seller_centers;
              state.region_seller_centers = _.where(state.seller_centers, { region: res.data.seller_regions[0]?.value });
              preAuthForm.sellercenterids = _.pluck(state.region_seller_centers, "value");
            } else {
              state.seller_centers = [];
              state.region_seller_centers = [];
            }
          }
          if (state.platform === 'tiktok') {
            state.tt_auth_sites = res.data.tt_auth_sites || [];
            preAuthForm.region = undefined;
            preAuthForm.site_type = undefined;
            preAuthForm.benefit_type = undefined;
          }
          if (state.platform === 'ozon') {
            preAuthForm.client_id = '';
            preAuthForm.api_key = '';
          }
          if (state.platform === 'walmart') {
            state.walmart_regions = res.data.walmart_regions || [];
            preAuthForm.region = undefined;
            preAuthForm.client_id = '';
            preAuthForm.client_secret = '';
            preAuthForm.channel_type = '';
          }
          state.preAuthForm = preAuthForm;
          if (!hide) state.preAuthModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    };
    const onChangeRegion = (region) => {
      state.region_seller_centers = _.where(state.seller_centers, { region });
      state.preAuthForm.sellercenterids = _.pluck(state.region_seller_centers, "value");
      state.preAuthForm.checkAll = true;
    }
    const onCheckAllChange = () => {
      if (state.preAuthForm.checkAll) {
        state.preAuthForm.sellercenterids = _.pluck(state.region_seller_centers, "value");
      } else {
        state.preAuthForm.sellercenterids = [];
      }
    }
    const onCheckChange = () => {
      state.preAuthForm.checkAll = state.preAuthForm.sellercenterids.length === _.pluck(state.region_seller_centers, "value").length;
    }
    const onChangeAuthSite = (region) => {
      if (region === 'US') {
        state.tt_site_types = _.findWhere(state.tt_auth_sites, { value: region }).site_types;
      } else {
        state.tt_site_types = [];
      }
    }
    const onChangeSiteType = (site_type) => {
      state.tt_benefit_types = _.findWhere(state.tt_site_types, { value: site_type }).benefit_types;
    }
    const preAuth = async () => {
      try {
        await formRef.value.validateFields();
        state.preAuthLoading = true;
        proxy.$api.doAPI("/shop/preauth", { preAuthForm: state.preAuthForm, host: window.location.host }).then((res) => {
          state.preAuthLoading = false;
          if (res.code === 0) {
            if (state.preAuthForm.platform === 'shopee') {
              proxy.$utils.downloadBrowser({ href: res.data.auth_url });
            } else {
              state.preAuthModal = false;
              getData();
            }
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.preAuthLoading = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
    }
    const editAuth = (item) => {
      state.addAuthLoading = true;
      let queryParams = { _id: item._id, platform: item.platform };
      if (queryParams.platform === 'amz') queryParams._id = item._ids;
      proxy.$api.doAPI("/shop/authinfo", queryParams).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          if (state.platform === 'walmart') {
            state.walmart_regions = res.data.walmart_regions || [];
          }
          state.preAuthForm = res.data.preAuthForm;
          state.preAuthModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    }
    const bindAuth = (_id) => {
      state.addAuthLoading = true;
      proxy.$api.doAPI("/shop/bindauth", { _id, host: window.location.host }).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          if (res.data.auth_url) {
            proxy.$utils.downloadBrowser({ href: res.data.auth_url });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    };
    const bindAuthAD = (_id) => {
      state.addAuthLoading = true;
      proxy.$api.doAPI("/shop/bindauthad", { _id, host: window.location.host }).then((res) => {
        state.addAuthLoading = false;
        if (res.code === 0) {
          if (res.data.auth_url) {
            localStorage.setItem("zdterp_adauth_shopid", _id);
            proxy.$utils.downloadBrowser({ href: res.data.auth_url });
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.addAuthLoading = false;
        console.error(err);
      });
    }
    const showAuth = (data) => {
      proxy.$api.doAPI("/shop/showauth", data).then((res) => {
        if (res.code === 0) {
          state.platform = res.data.platform;
          if (state.platform === "amz") {
            state.authForm = {
              platform: res.data.platform,
              shopid: res.data.shopid,
              shopname: res.data.shopname,
              account_username: res.data.account_username,
              region: res.data.region,
              sellercenter: res.data.sellercenter,
              ...route.query,
            };
          } else if (state.platform === "smt") {
            state.authForm = {
              platform: res.data.platform,
              shopid: res.data.shopid,
              shopname: res.data.shopname,
              account_username: res.data.account_username,
              ...route.query,
            };
          } else if (state.platform === "tiktok") {
            state.authForm = {
              platform: res.data.platform,
              shopid: res.data.shopid,
              shopname: res.data.shopname,
              account_username: res.data.account_username,
              region: res.data.region,
              site_type: res.data.site_type,
              benefit_type: res.data.benefit_type,
              ...route.query,
            }
          } else if (state.platform === "shopee") {
            state.authForm = {
              platform: res.data.platform,
              authid: res.data.authid,
              shopname: res.data.shopname,
              account_username: res.data.account_username,
              ...route.query,
            };
          }
          // if (state.platform === "tiktok") return confirmAuth();
          state.authModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const showAuthAD = (shopid) => {
      proxy.$api.doAPI("/shop/showauthad", { shopid }).then((res) => {
        if (res.code === 0) {
          if (res.data.shop) {
            state.platform = res.data.shop.platform;
            if (state.platform === "amz") {
              state.authADForm = {
                platform: res.data.shop.platform,
                shopid: res.data.shop._id,
                shopname: res.data.shop.name,
                account_username: res.data.shop.account_username,
                region: res.data.seller_center.region,
                ...route.query,
              };
            }
            state.authADModal = true;
          } else {
            message.error("授权失败");
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const confirmAuth = () => {
      state.authing = true;
      proxy.$api.doAPI("/shop/confirmauth", { authForm: state.authForm }).then((res) => {
        state.authing = false;
        if (res.code === 0) {
          Modal.success({
            title: "提示", content: "授权成功",
            onOk() {
              state.authModal = false;
              router.replace({ path: route.path }).catch(console.error);
              getData();
            },
          });
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.authing = false;
        console.error(err);
      });
    };
    const confirmAuthAD = () => {
      state.authing = true;
      proxy.$api.doAPI("/shop/confirmauthad", { authADForm: state.authADForm, host: window.location.host }).then((res) => {
        state.authing = false;
        if (res.code === 0) {
          Modal.success({
            title: "提示", content: "授权成功",
            onOk() {
              state.authADModal = false;
              router.replace({ path: route.path }).catch(console.error);
              getData();
            },
          });
          localStorage.removeItem("zdterp_adauth_shopid");
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.authing = false;
        console.error(err);
      });
    };
    const changeState = (enabled, item) => {
      state.switching[item._id] = true;
      proxy.$api.doAPI("/shop/changestate", { enabled, _id: item._id }).then((res) => {
        state.switching[item._id] = false;
        if (res.code === 0) {
        } else {
          message.error(res.msg);
        }
        getData();
      }).catch((err) => {
        state.switching[item._id] = false;
        console.error(err);
        getData();
      });
    }
    const configMailBox = (_id) => {
      state.mailbox_configing = true;
      proxy.$api.doAPI("/shop/configmailbox", { action: "create", _id }).then((res) => {
        state.mailbox_configing = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.mailbox_configing = false;
        console.error(err);
      });
    }
    const readMailBox = (_id) => {
      state.mailbox_reading = true;
      proxy.$api.doAPI("/shop/readmailbox", { _id }).then((res) => {
        state.mailbox_reading = false;
        if (res.code === 0) {

        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.mailbox_reading = false;
        console.error(err);
      });
    }
    const getplatformname = (platform) => {
      return _.findWhere(state.platforms, { value: platform })?.label;
    }
    const getregionname = (item) => {
      if (state.platform === 'tiktok') {
        let name = [];
        let auth_site = _.findWhere(state.tt_auth_sites, { value: item.region });
        if (auth_site) name.push(auth_site.label);
        if (item.region === 'US') {
          let site_type = _.findWhere(auth_site.site_types, { value: item.site_type });
          if (site_type) name.push(site_type.label);
          if (item.site_type === 'native_land') {
            let benefit_type = _.findWhere(site_type.benefit_types, { value: item.benefit_type });
            if (benefit_type) name.push(benefit_type.label);
          }
        }
        console.log(name);
        return name.join('-');
      } else if (state.platform === 'walmart') {
        return _.findWhere(state.walmart_regions, { value: item.region })?.label;
      }
    }
    const bindOperator = (_id) => {
      //根据店铺信息查找操作人
      let operators = [];
      _.map(state.stafflist, n => {
        if (_.filter(n.shopids, n => n === _id).length > 0) {
          operators.push(n._id)
        }
      });
      state.bindOperatorForm = { shopid: _id, operators };
      state.bindoperatorModel = true;
    };
    const changecheckbox = (_id) => {
      if (state.bindOperatorForm.operators.indexOf(_id) !== -1) {
        state.delOperators.push(_id);
      }
    };
    //确认操作人
    const confirmBindOperator = () => {
      state.operatorBinding = true;
      proxy.$api.doAPI("/shop/bindoperator", { shopid: state.bindOperatorForm.shopid, operators: state.bindOperatorForm.operators, delOperators: _.uniq(state.delOperators) }).then((res) => {
        state.operatorBinding = false;
        if (res.code === 0) {
          state.bindOperatorForm = { shopid: null, operators: [], };
          state.delOperators = [];
          state.bindoperatorModel = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.operatorBinding = false;
        console.error(err);
      });
    };
    const cancelBindOperator = () => {
      state.bindoperatorModel = false;
      state.authOperatorForm = [];
    };
    const bindWarehouse = (_id) => {

    };
    return {
      ...toRefs(state),
      formRef,
      width,
      UnauthorizedShops,
      getData,
      addAuth,
      onChangeRegion,
      onCheckAllChange,
      onCheckChange,
      onChangeAuthSite,
      onChangeSiteType,
      preAuth,
      cancelSave,
      editAuth,
      bindAuth,
      bindAuthAD,
      confirmAuth,
      confirmAuthAD,
      changeState,
      configMailBox,
      readMailBox,
      getplatformname,
      getregionname,
      bindOperator,
      changecheckbox,
      confirmBindOperator,
      cancelBindOperator,
      doCopy: proxy.$utils.doCopy,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

// :deep(.ant-table-thead > tr > th) {
//   padding: 8px 8px !important;
// }

.pactive {
  background: #e6f7ff;
  color: #1890ff;
}

p {
  margin: 0;
  padding: 0;
}
</style>
