<template>
  <div class="main">
    <div class="nav-head">
      <a class="index-nav-a" href="#">
        <img src="~@/assets/logo.png" style="height: 35px;" />
      </a>
      <div class="nav-word" id="navWord">
        <ul>
          <li class="nav-li"><a class="active" href="#">首　页</a></li>
          <li class="left nav-li"><a href="#" target="_blank">帮助手册</a></li>
          <li class="left nav-li"><a href="#">关于我们</a></li>
          <li class="left nav-li"><a href="#">隐私政策</a></li>
          <li class="left nav-li"><a href="#">用户协议</a></li>
        </ul>
      </div>
    </div>
    <div class="login">
      <div class="left"></div>
      <div class="right">
        <div class="loginContainer">
          <a-card title="欢迎登录" :bordered="false" style="width: 400px">
            <a-form ref="formRef" name="loginForm" :model="loginForm" class="login-form" @finish="doLogin">
              <a-form-item name="username" :rules="[{ required: true, message: '请输入账号' }]">
                <a-input v-model:value="loginForm.username" placeholder="请输入账号" :disabled="logining">
                  <template #prefix>
                    <UserOutlined class="site-form-item-icon" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item name="password" :rules="[{ required: true, message: '请输入密码' }]">
                <a-input-password v-model:value="loginForm.password" placeholder="请输入密码" :disabled="logining">
                  <template #prefix>
                    <LockOutlined class="site-form-item-icon" />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-form-item name="verifycode" :rules="[{ required: true, message: '验证码不能为空' }]">
                <a-input-group compact>
                  <a-input v-model:value="loginForm.verifycode" placeholder="验证码" :disabled="logining" style="width: 74%">
                    <template #prefix>
                      <KeyOutlined class="site-form-item-icon" />
                    </template>
                  </a-input>
                  <a-spin :spinning="verifying" size="small">
                    <img :src="verifyImage" style="width: 100%; cursor: pointer" @click="getVerifyCode" />
                  </a-spin>
                </a-input-group>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" style="width: 100%" html-type="submit" class="login-form-button"
                  :loading="logining">
                  登录
                </a-button>
              </a-form-item>
              <a-form-item>
                <p>登录即表明您同意<a href="javascript:;" @click="showModal">正店通ERP条款与条件</a></p>
                <p>
                  <a-button type="link" @click="doRegister">免费注册</a-button>
                </p>
              </a-form-item>
            </a-form>
          </a-card>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="htitle">
        特色功能
      </div>
      <div class="features">
        <div class="ftitle">商品Listing</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/product.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>商品SKU管理</h4>
              <p>√ 支持多种管理粒度，如：单品、组合产品、套装产品，对商品进行精细化管理。</p>
              <p>√ 商品标签丰富，可自动下单带打印的商品信息表格。</p>
              <p>√ 新品开发线上化，开发进度全程跟踪。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/kandeng.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>Listing在线刊登</h4>
              <p>√ 支持批量快速上架产品，并自动检查违禁关键词。</p>
              <p>√ 支持亚马逊无缝刊登及listing批量修改。</p>
              <p>√ 将店铺SKU与本地产品的自动匹配成映射关系。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/jiankong.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>Listing在线分析监控</h4>
              <p>√ 监控ASIN关键词自然排名与PPC广告排名，通过大数据分析为listing优化推荐有效建议。</p>
              <p>√ 跟卖监控提醒，自动快照留凭，杜绝跟卖。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/order.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>亚马逊订单自动获取</h4>
              <p>支持多店铺亚马逊订单自动获取，实时同步，0漏单；多种自动化策略，高效处理订单。</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="features">
        <div class="ftitle">订单采购一体化</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/dingzhi.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>订单定制商品采购加工</h4>
              <p>支持定制商品的采购加工，包括：采购计划、商品型号、样品、供应商等进行精细化管理。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/zhuizong.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>订单商品全程追踪</h4>
              <p>通过对商品供应商的管理，自动同步订单商品采购、加工、运输全流程的各个节点。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/FBA.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>FBA发货</h4>
              <p>通过发货计划，将FBA货件与发货单之间的数据串联，形成完整的数据链路，可以替代运营和仓库、物流之间互相传递的补货表单。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/FBM.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>FBM发货</h4>
              <p>根据亚马逊订单自动发货，支持亚马逊多渠道配送服务。</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="features">
        <div class="ftitle">智能发货</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/haiwaicang.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>海外仓发货</h4>
              <p>支持自建或第三方海外仓发货，订单直接同步到海外仓系统完成发货。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/zhinengcangchu.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>仓储智能服务</h4>
              <p>商品精细化出入库管理、PDA管理、仓库绩效统计、自定义出入库环节。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/cangkujiankong.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>仓储安全监控</h4>
              <p>通过仓储数据智能分析，对库存数据自动预警，缺货数据发起紧急采购。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/ads.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>智能广告投放</h4>
              <p>通过灵活操作广告活动，自定义广告数据指标，CPC全面广告数据分析，智能投放广告。</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="features">
        <div class="ftitle">AI智能广告云服务</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/shouyi.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>广告收益最大化</h4>
              <p>结合ASIN、关键词广告表现和排名监控，为精细化运营提供数据参考，实现广告效益最大化。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/fenxi.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>广告数据多维度分析</h4>
              <p>通过广告创意，文本，渠道和其他多维分析掌握市场动态。 追踪最流行的广告策略，并且可通过广告成本分析发现电商平台的广告成本指数。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/baobiao.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>财务报表</h4>
              <p>
                用于记录、跟踪和管理电商企业的财务活动，以便进行准确的财务报告和决策。
              </p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/kanban.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>经营看板</h4>
              <p>通过店铺经营成果、盈利情况、回款情况等数据，分析诊断店铺经营现况，发现运营问题，保证店铺良性运转。</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="features">
        <div class="ftitle">业财一体化</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/caiwuruanjian.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>与财务软件打通</h4>
              <p>通过与财务软件对接，打通业务信息与财务信息间的通道，使电商业务数据及时地、准确地、自动地、规范地传递给财务。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/huikuan.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>亚马逊回款跟踪</h4>
              <p>每天同步多个店铺的亚马逊后台-付款报告数据，形成财务的销售回款分析面板，通过面板可以查看所有店铺未结算和已结算的预留账户金额、回款明细和汇总金额数据。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/shishishuju.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>实时销售数据展示</h4>
              <p>实时整理展示亚马逊各个店铺销售订单、销售金额、利润数据，分析销售额异常情况。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/gongyingshang.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>供应商管理</h4>
              <p>供应商信息管理、供应商分类、采购信息自动同步，供应商生产订单管理、采购账单同步等。</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="features">
        <div class="ftitle">供应链全流程服务</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/zhinengcaigou.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>智能采购管理</h4>
              <p>根据每件产品特性、销售数据、采购时效、物流时效，自动计算FBA库存可售天数以及推荐备货计划。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/tongjifenxi.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>采购统计分析</h4>
              <p>通过对大量采购数据的智能分析，优化供应链和采购决策，包括：趋势分析、多维度分解、转化漏斗等。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/pingtai.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>电商平台对接</h4>
              <p>目前已对接市面上各大流行电商平台，新崛起的电商平台也在积极对接中，以满足商家不断的拓展新的销售渠道。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/shejiaomeiti.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>社交媒体对接</h4>
              <p>随着社交媒体平台电商化的案例越来越多，这意味着电商业务提升到了更高的战略级别，与社交媒体平台的对接，为商家带来更多的流量。</p>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="features">
        <div class="ftitle">人工智能服务</div>
        <a-row>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/kefu.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>智能客服</h4>
              <p>通过智能机器人，提供自动回复、客服灵活分配、AI智能回复、客服绩效管理等多种功能，提高客服效率。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/dashuju.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>大数据分析</h4>
              <p>通过智能大数据分析，可预测销售数据、完善供应链、完善产品、分析用户画像、改善客户体验。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/chatgpt.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>ChatGPT</h4>
              <p>引入了ChatGPT技术，基于提高客服效率进行了"智能升级"，实现了跨境电商客服场景与ChatGPT的结合。</p>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="6">
            <img src="~@/assets/features/yidong.png" style="height: 44px;line-height: 44px;margin-right: 15px;" />
            <div>
              <h4>移动端支持</h4>
              <p>利用微信公众号、小程序、H5等工具，将重要信息，如：退单、订单超时等信息自动推送给管理员，手机随时随地查看。</p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="section partner">
      <div class="htitle">
        合作伙伴
      </div>
      <div class="partnerc">
        <img src="~@/assets/partner2.png" style="width:100%;" />
      </div>
    </div>
    <div class="footer">
      Copyright © 2023 河南智叟网络科技有限公司 豫ICP备2023016597号-1
    </div>
    <a-modal v-model:visible="open" title="正店通ERP条款与条件" width="680px" @ok="handleOk">
      <div class="content">
        <p><strong>前言</strong></p>

        <p>欢迎使用河南智叟网络科技有限公司 (以下称“我们”)开发和运营的正店通ERP软件 及服务！</p>

        <p>正店通ERP 软件供亚马逊商城的卖家或者其从业人员使用，其结合了各种不同的工具，这将有助于您或者您代表的某个实体公司及其附属机构(以下合称为“您”)在亚马逊商城做出商业决策。</p>

        <p>
          为使用我们的<strong><em>ERP软件及服务，您应当阅读并遵守《</em></strong>ERP使用条款与条件》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通其他服务的条款。免除或者限制责任的条款可能以加粗的形式提示您注意。
        </p>

        <p>本协议通过点击“阅读并同意”，即视为您已阅读并同意本协议项下的所有条款。除非您已阅读并同意本协议所有条款，否则您不得注册正店通ERP软件的账户。 <strong>本协议自您点击“阅读并同意”时起生效。</strong>
        </p>

        <p><strong>1.</strong><strong>范围及适用性</strong></p>

        <p>1.1协议适用主体范围</p>

        <p>本协议适用于您下载（如有）、安装（如有）、注册、使用、登陆正店通ERP软件（以下简称“本软件”），以及适用本软件所订立本协议的人员，或您与我们直接签订个人合同的主体。</p>

        <p>1.2协议关系及冲突条款</p>

        <p>如果本协议与实际签订的合同存在矛盾或不一致的内容，以实际签订的合同为准。</p>

        <p>未经事先书面许可，我们的直接竞争对手不可使用本软件及服务。此外，直接竞争对手也不能以监视服务的可用性、性能或功能为目的或出于任何其他基准建立或竞争目的使用本软件及服务。</p>

        <p>本协议最后更新时间为2022年5月18日。我们可能不断发布关于本软件的相关协议、规则、规范等内容，前述内容一经发布，即为本协议不可分割的组成部分，与其构成统一整体，您同样应当遵守。</p>

        <p><strong>2.</strong><strong>关于本服务</strong></p>

        <p><strong>2.1</strong><strong>本服务内容</strong></p>

        <p>本服务内容是指我们通过本软件向用户提供相关服务（以下简称“本服务”）。</p>

        <p><strong>2.2</strong><strong>本服务形式</strong></p>

        <p>
          您可能通过电脑、手机等终端以客户端、网页等形式使用本软件及服务，具体以我们提供的为准，我们会不断丰富您适用本软件及服务的终端、形式等。当您使用本软件及服务时，您应选择与您终端、系统等相匹配的本软件版本，否则无法正常使用本软件及服务。
        </p>

        <p>其他服务，例如本软件的支持服务，仅在事先达成协议后作为附加服务提供。本软件可能但没有义务向您提供任何的支持服务，也没有义务签订此类附加服务的合同。</p>

        <p>您可以创建自己的用户帐户以使用本软件。我们将通过您的帐户授予您购买的服务范围的使用权限。</p>

        <p>2.3软件更新</p>

        <p>
          我们有权随时更新本软件版本，扩展本软件功能的范围，使其适应技术的进步进行改进。我们还有权随时引入额外的安全措施或类似的变更措施。本软件新版本发布后，我们将可能视情况设定合理的过渡期，以便部分用户使用新版本。过渡期结束后，旧版本的软件可能无法继续使用，我们不保证旧版本软件继续可用及向您提供相应的服务。
        </p>

        <p>我们如果自愿提供附加服务或软件功能（例如新工具、新功能）的，不收取任何额外费用。 <strong>这类功能未经本协议约定的，不得视为您将来能够免费使用此类功能。</strong>
          此类新功能通常用于测试目的，而且我们有权停止、更改或提供此类附加服务。</p>

        <p>
          <strong>本软件的部分功能取决于亚马逊通过API提供的数据以及通过其他方式收集的数据。我们只能处理和分析此类数据。因此，在某些情况下，我们可能会被迫减少本软件或某些工具的可用功能；并且，只要这种功能的减少是亚马逊对可用数据进行更改的结果我们有权按照上述方式进行处理。如果在上述情况下本软件功能的减少损害了您对使用本软件的兴趣，则您有权终止本软件的订购。</strong>
        </p>

        <p><strong>3.</strong><strong>期限与终止</strong></p>

        <p><strong>3.1</strong> <strong>关于已购买服务的规定</strong></p>

        <p>我们通过合同或订单向您提供服务，合同期限内授予您本软件访问权限范围的详细信息：</p>

        <p>(1) 服务以用户套餐方式出售;</p>

        <p>a) 在双方签订的单独合同中达成一致;</p>

        <p>b) 通过网页购买套餐对应的说明达成一致:您可以在我们的网站（hnzhisou.com）上找到通用服务说明、功能访问以及适用的价格表。其他承诺，履约承诺或抵押协议仅在我们书面确认后才有效;</p>

        <p>c) 如果两者之间存在矛盾，以a)的约定为准。</p>

        <p>(2) 套餐期间可增加其它套餐，其价格与合同或我们的网站价格表为准;</p>

        <p>(3) 我们的网站价格表总是会有变化，由我们确认公布但对您与我们不具约束力。只有在我们与您以书面形式确认或我们您在我们网站下单确认后已经开始执行的情况下，订单才被视为已被接受。</p>

        <p>（4）套餐期即将到期的前 30 天（自然日），我们会以邮件和电话形式通知您续订服务，续订的期限可以自行选择。</p>

        <p>（5）价目表中列出的所有价格均为净价，不包括其适用的法定增值税以及可能产生的任何其他税费。</p>

        <p><strong>3.2</strong> <strong>开票与付款</strong></p>

        <p>您只需向我们提供的专用充值帐号支付充值金额即可购买套餐服务，我们会为您提前开票，或者按照相关订单约定执行。您有义务在服务中准确保留完整的开票信息和联系信息。</p>

        <p><strong>3.3</strong> <strong>服务暂停与提前收费</strong></p>

        <p>
          如果您根据本协议或任何其它协议的服务欠费已逾期30天（自然日）或以上，根据本协议约定，我们有权提前收取您的未付费用，因此所有此类应付款项都将立即到期并变成应付款项，在您付清全部金额之前，我们会暂停服务，同时我们保留对您采取补救措施的权利和其它权利。
        </p>

        <p>
          为避免因您的欠费而导致系统数据异常问题，在您欠费30天（自然日）内，本软件继续根据第5条的约定获取数据；如欠费逾期30天（自然日）届满之日起，本软件停止获取您的所有数据；如欠费逾期60天（自然日），我们将删除您在本软件的所有数据。
        </p>

        <p><strong>3.4</strong> <strong>付款争议</strong></p>

        <p>如果相关费用发生有理有据的争议，且您与我们努力协调解决该争议，则我们可能不会实施第 3.3款 (服务暂停和提前收费)中约定的权利。</p>

        <p><strong>4.</strong><strong>您的义务</strong></p>

        <p>您需要保证，在创建帐户及其使用过程中，您提供的信息是正确和完整的。如果您提供的联系数据发生任何变化，以及为执行本协议所需的任何数据发生变化，您应立即通知我们。</p>

        <p>本软件的使用及服务记录的所有操作行为都和注册账号关联，这代表该账户在本软件的所有操作都默认为是您本人操作，您本人应对操作结果负责。</p>

        <p>如果您离开您代表的某个实体公司及其附属机构并停止使用本软件，我们可以从服务器上删除您的注册账号。</p>

        <p>您有责任确保满足技术要求，包括所需硬件和软件的可用性以及合适的电信连接。根据要求，我们可以就所需的硬件和软件环境向您提供建议。</p>

        <p>您有责任确保您员工具备使用本软件的适当资格和接受过相应的培训。</p>

        <p>本软件无法访问或我们提供的服务存在故障、缺陷，您在发现后应通过邮件、Q群、电话等方式立即向我们反馈。造成影响的，您应尽可能以文本形式描述，解释如何再现故障、缺陷或功能障碍。</p>

        <p>本软件不用于永久备份和存储数据。因此，您应保留其传输给我们的所有数据的备份副本，这些副本不得存储在我们的服务器上。此外，您应定期备份与使用服务相关的数据，以防止永久性数据丢失，从而最大限度地降低损坏风险。</p>

        <p>
          您应保留访问本软件的登录名和密码详细信息，并确保第三方网络安全且无法访问。您授予访问权限的人员应由您进行相应的指示，并且在相同的范围内有义务。密码必须定期更改，至少每季度更换一次。如果您发现未经授权的第三方已获得对本软件的访问数据，则应立即通知我们。我们有权(但没有义务)采取措施减少损害，例如，阻止您的帐户或更改访问数据。在这种情况下，您有权要求我们提供新的访问数据。如果第三方在获得所需访问数据后使用您的帐户访问本软件，因为您没有充分保护，则您应对第三方操作您的帐户负责。如果您事先已通知我们未经授权的可能性，并且有足够的准备时间阻止由第三方访问，则不适用此规则。
        </p>

        <p>您应避免采取任何可能损害或危害本软件或我们其他系统或服务的稳定性和安全性的措施，除非此类损害或危害是按照本协议说明使用本软件的技术必然后果。</p>

        <p>
          <strong>您全权负责遵守亚马逊的使用条款和使用亚马逊商城的指南。虽然本软件及其提供的功能在某些情况下可能会使您违反亚马逊的条款和指南，但这显然不是本软件的预期用途，而且我们既不支持也不接受本软件的这种使用。</strong>
        </p>

        <p><strong>如果我们发现或收到别人举报您有违反本协议约定，我们有权采取包括但不限于暂停、终止您使用本软件，追究法律责任等措施。</strong></p>

        <p><strong>5.</strong><strong>数据访问及数据管理</strong></p>

        <p><strong>5.1</strong><strong>数据访问</strong></p>

        <p>您有义务按照本协议约定向我们提供履行服务所需的所有数据。本软件通过您的亚马逊帐户(“亚马逊帐户”)的授权以获取相关数据及报表，将明确并诚实地告知您需要访问的数据及其用途，
          <strong>您必须确保我们被授予此目的所需的所有访问权限。根据亚马逊对个人身份信息保留政策要求，我们针对在您的买家订单发货之日起28天后自动删除您的买家个人身份信息，并且不做离线备份。</strong></p>

        <p>
          您提供的用于分析的数据不得违反法律规定或侵犯第三方的权利。我们提供的服务不得用于非法或第三方侵权目的。如果发生违规行为，我们可能会暂停提供即时生效的服务或阻止您访问本软件。您赔偿我们因您违反上述约定而造成的任何损害，包括因实现上述债权而产生的费用，包括但不限于公证费、鉴定费、差旅费、办理费、律师费等。
        </p>

        <p>您授予我们使用数据所需的权利，以便根据本协议分析交付数据。</p>

        <p><strong>由于您所需的数据分析的性质，我们可能使用授权的亚马逊帐户来收集和分析与您没有直接关系的数据，例如与您未出售的产品有关的数据。您允许我们分析此类数据。</strong></p>

        <p>5.2数据管理</p>

        <p>
          根据亚马逊的数据保护政策规定，我们必须为应用程序或服务创建、记录并遵守隐私以及数据处理策略，这些政策规定管理和保护信息资产时的适当行为和技术控制。我们必须保存能够访问PII的软件和实物资产(如计算机、移动设备)的库存，并定期进行更新。应维护数据处理活动的记录，如特定数据字段及其所有PII信息的收集、处理、存储、使用、共享和处置方式，以建立责任和遵守条例。我们必须建立并遵守客户同意的隐私政策和数据访问权，纠正、删除或停止共享/处理其信息(如适用)或数据隐私条例所要求的信息。
        </p>

        <p>5.3要求删除</p>

        <p>在收到亚马逊通知并根据其要求删除亚马逊信息，必须由我们自行 立即（但必须在亚马逊的要求后不超过72小时之内）永久且安全地删除（根据行业标准的 处理流程，例如NIST
          800-88）。我们还必须在亚马逊发出通知后的90天内永久安全地删除所有实时的（在线或网络可访问的）亚马逊信息实例。</p>

        <p><strong>6.</strong><strong>可用性</strong></p>

        <p>为了确保本软件和我们所提供服务的正常运行，我们定期对系统进行维护和更新工作(“日常维护工作”)。这种工作通常在预期的低使用率时进行。</p>

        <p>如果由于此类维护工作在相当长的一段时间内无法保证服务的可用性，我们将在适当的时间提前通知您即将到来的维护日期。</p>

        <p><strong>7.</strong> <strong>所有权</strong></p>

        <p><strong>7.1</strong> <strong>保留权利</strong></p>

        <p>除此处明确授予的使用权外，我们保留服务中的所有权利、所有权和利益，包括所有相关知识产权。除此处明确规定的权利外，下文并未授予您任何其它权利。</p>

        <p><strong>7.2</strong> <strong>限制</strong></p>

        <p>您不得从事下列行为：</p>

        <p>(1)允许第三方访问本软件，除非本协议或订单中许可;</p>

        <p>(2) 基于服务创建衍生产品;</p>

        <p>(3) 复制、设计或镜像服务的任意部分或任意内容，但您可以在自己的内部网内复制、设计或其它行为以实现您自己的内部商业用途;</p>

        <p>(4) 对本软件进行反向工程、反向汇编、反向编译，后者以其他方式尝试发现、获得本软件的源代码;</p>

        <p>(5) 出于以下目的访问服务：</p>

        <p>a) 生成竞争性产品或服务;</p>

        <p>b) 复制服务的任何特性、功能或图形。</p>

        <p><strong>7.3</strong> <strong>您的数据的所有权在我们与您之间，您独立拥有您数据的全部权利、所有权和利益。</strong></p>

        <p><strong>8.</strong> <strong>保密约定</strong></p>

        <p>您与我们双方对本协议内容以及对本协议过程执行情况予以保密，不得向第三方披露。
          您与我们双方互为保密资料的提供方和接受方，负有保密义务，承担保密责任。甲乙双方中任何一方未经对方书面同意不得向第三方公开和披露任何保密资料或以其他方式使用保密资料。
          您与我们也须促使各自代表不向第三方公开或披露任何保密资料或以其它方式使用保密资料。除非披露、公开或利用保密资料是双方从事或开展合作项目工作在通常情况下应承担的义务（包括双方今后依法律或合同应承担的义务）适当所需的。</p>

        <p><strong>9.</strong> <strong>保证与免责声明</strong></p>

        <p><strong>9.1</strong> <strong>相互保证</strong></p>

        <p>各方认可和保证：</p>

        <p>(1) 签署本协议具有法律效力;</p>

        <p>(2) 不会向对方转移任何恶意代码(由其它方向保证方传输的恶意代码除外)。</p>

        <p><strong>9.2</strong> <strong>免责声明</strong></p>

        <p><strong>(1)</strong> <strong>由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露。</strong></p>

        <p><strong>(2)</strong> <strong>任何黑客政击、计算机病毒侵入或发作、政府部门管制和其他不可抗力等造成的个人资料泄露、丢失、被盗用或被窜改等。</strong></p>

        <p><strong>(3)</strong> <strong>因与本软件或我们的网站链接的其它网站造成个人资料泄露及由此而导致的任何法律争议和后果。</strong></p>

        <p><strong>10.</strong> <strong>责任范围</strong></p>

        <p><strong>10.1</strong> <strong>相互保证</strong></p>

        <p>
          责任范围在任何情况下，任何一方因本协议而产生的或与本协议相关的全部责任，无论其是因为本协议约定还是侵权行为或是任何其它责任范畴，都不可超过您在此处支付的总金额，或就任何单个事件而言，您的责任不应超过30万元人民币或您在该事件之后12个月内在此处支付的金额(两者取其小)。上述责任并不限制第3条(期限与终止)中约定的您的付款义务。
        </p>

        <p><strong>10.2</strong> <strong>后续损失和相关损失的除外责任</strong></p>

        <p>
          <strong>在任何情况下，对于任何一方的任何利润/收入损失或因任何其它原因导致的任何间接、特殊、偶然、必然、补偿或惩罚性的损失，无论其是由于本协议约定还是侵权行为或是任何其它责任范畴，也无论是否已被告知可能的损失，对方都没有义务给予赔偿。在适用法律限制范围内，上述免责声明不适用。</strong>
        </p>

        <p><strong>11.</strong><strong>关于我们使用cookie条款</strong></p>

        <p><strong>Cookies</strong> <strong>是细小的资料档（通常是数字与字母），网站存放于您的电脑或移动设备上的小型资料文件（通常是数字和字母）。 Cookies
            将帮助该网站或另一个网站在您下次浏览时，对您的设备进行识别。网络信标或其他类似文件也具有同样功能。在本协议中，我们使用「cookies」一词来指称以此方式收集信息的所有文件。 Cookies
            可实施很多不同的功能，比如让您在网页之间高效导航，保存您的偏好，以及全面改进您对网站的访问体验。本软件页面可能使用cookies，以便当您返回我们的网站时，本软件可以为您提供信息访问权限。</strong></p>

        <p>
          <strong>尽管对于Cookie的隐私含义一直存在争议，但是对于本软件的客户而言，重要的是要知道cookie不会读取您的硬盘驱动器，也不能使用cookie来泄露您未选择透露的关于您的新信息。本软件可能会使用cookie来记录您在我们网站上的活动。请注意，您的网络浏览器设置可能会进行调整，以限制或禁止该网站和其他网站放置Cookie。</strong>
        </p>

        <p><strong>12.</strong><strong>通讯偏好设置和退出</strong></p>

        <p>
          <strong>您向我们提供您的联系信息后，本软件或我们可能会向您推销，包括但不限于发送促销和相关优惠的信息。如果您要选择不接收来自本软件营销有关的信息，请单击通信中的“选择退出”链接。请注意，如果您选择不接收来自我们的与营销相关的电子邮件，我们仍然可以继续向您的电子邮箱发送重要的管理消息。如果您希望我们从数据库中完全删除您的个人记录，我们将根据您的要求删除您的联系信息。</strong>
        </p>

        <p><strong>13.</strong><strong>国际数据传输</strong></p>

        <p><strong>根据我们适用的法律和法规，在您所在国家/地区之外的任何地方，我们或我们的第三方运营机构将保留传输、处理和储存您的信息的权利。</strong></p>

        <p><strong>14.</strong><strong>总则</strong></p>

        <p><strong>14.1</strong> <strong>各方关系</strong></p>

        <p>各方均为独立的合约商。本协议并未在各方之间形成伙伴关系、特许经营关系、合资、代理、信托或雇佣关系。</p>
        <p><strong>14.2</strong> <strong>无第三方受益人</strong></p>
        <p>本协议无任何第三方受益人。</p>
        <p><strong>14.3</strong> <strong>独立权利和义务</strong></p>
        <p>如果具有司法管辖权的法院认定本协议规定不符合法律，则该法院可修改或解释相关规定，以便在法律许可的最大范围内最完美地实现原规定的目的，本协议其余规定仍然有效。</p>
        <p><strong>14.4</strong><strong>法律适用及争议解决</strong></p>
        <p>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国的法律。本协议签订地为深圳市南山区，若您和我们之间发生纠纷或争议，首先应友好解决；协商不成的，您同意将纠纷或争议提交本协议签订地的人民法院管辖。</p>
        <p><strong>14.5</strong> <strong>完整协议</strong></p>
        <p>
          本协议，包括此处规定的所有内容及附录以及所有订单，构成各方之间的完整协议，本协议取代之前约定和同时约定的关于本软件的口头协议、提议或陈述。如果与具体签订的合同有冲突，以合同为准。对于本协议中规定的任何变更、修改或放弃，除非以书面形式提出并经另外一方签字或以电子方式认可，否则全部无效。
        </p>
        <p>同意并继续</p>
      </div>
      <template #footer>
        <div style="text-align:center;">
          <a-button key="submit" type="primary"  @click="handleOk">阅读并同意</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
import Cookies from "js-cookie";
export default defineComponent({
  name: "Login",
  components: { UserOutlined, LockOutlined, KeyOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const initForm = {
      username: "",
      password: "",
      verifycode: "",
      verifyuuid: "",
    };
    const state = reactive({
      loginForm: {},
      verifying: false,
      verifyImage: "",
      logining: false,
      rememberpwd: true,
      open: false
    });
    onMounted(() => {
      state.loginForm = _.extend({}, initForm);
    });
    onActivated(() => {
      // console.log("onActivated");
      getVerifyCode();
    });
    const showModal = () => {
      state.open = true;
    };
    const handleOk = () => {
      state.open = false;
    };
    const getVerifyCode = () => {
      state.verifying = true;
      proxy.$api.doAPI2("/authcode").then((res) => {
        state.verifying = false;
        if (res.code === 0) {
          state.loginForm.verifyuuid = res.data.uuid;
          state.verifyImage = res.data.img;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.verifying = false;
        console.error(err);
      });
    };
    const doRegister = () => {
      console.log("doRegister");
      router.push({ path: "/register" }).catch(console.error);
    };
    const doLogin = () => {
      state.logining = true;
      proxy.$api.doAPI("/login", { loginForm: state.loginForm, ...route.query }).then((res) => {
        state.logining = false;
        if (res.code === 0) {
          localStorage.setItem("zberp_client.token", res.data.token);
          Cookies.set('zdterp_token', res.data.token, { expires: 365, secure: true, sameSite: "None" });
          proxy.$utils.setStore(res.data);
          let { ae1688authcode, ae1688reauth } = Cookies.get();
          console.log("cookies", Cookies.get());
          if (ae1688authcode) {
            Cookies.remove('ae1688authcode');
            Cookies.remove('ae1688reauth');
            let query = { code: ae1688authcode };
            if (ae1688reauth) query.reauth = ae1688reauth;
            router.replace({ path: "/purchase/supplier/1688", query });
          } else {
            router.replace({ path: "/workbench" }).catch(console.error);
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.logining = false;
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      getVerifyCode,
      doRegister,
      doLogin,
      showModal,
      handleOk
    };
  },
});
</script>
<style lang="less" scoped>
.nav-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 100px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;

    li {
      position: relative;

      a,
      a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 10px 30px;
        font-family: "Nunito", sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #013289;
        white-space: nowrap;
        transition: 0.3s;
      }
    }

    a.active {
      color: #4154f1;
    }
  }
}
.login {
  background: url(~@/assets/login_bg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    flex: 1;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  :deep(.ant-card-head-title) {
    text-align: center;
    font-size: 30px;
  }
  :deep(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :deep(.ant-spin-nested-loading) {
    width: 26%;
  }
}

p {
  margin: 0;
  padding: 0;
}

.section {
  padding: 30px 100px;
  margin: 0 auto;

  .htitle {
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    padding: 20px 0 40px 0;
  }
}

.partner {
  background: #F5F5F5;
}

.header {
  padding: 30px 0 0 75px;
  position: absolute;
}

.features {
  margin-bottom: 20px;

  h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #012970;
  }

  .ftitle {
    font-size: 30px;
    color: #012970;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .gutter-row {
    display: flex;
    padding: 0 10px;
  }
}

.footer {
  background: #021361;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  margin: auto;
  align-content: center;
  justify-content: center;
  color: #fff;
}

:deep(.ant-modal-wrap .ant-modal-footer){
    text-align: center;
}
</style>
