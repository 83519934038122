<template>
  <a-tabs v-model:activeKey="activeTab" type="card" @change="changeTab">
    <a-tab-pane key="1" tab="按平台">
      <div class="header">
        <a-form layout="inline" autocomplete="off">
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table bordered :columns="columns1" :data-source="data1" :loading="dataLoading" :scroll="{ x: width1 }"
          size="small">
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <!-- <a-button type="primary">导出订单收支明细</a-button> -->
            </div>
          </template>
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'order_count'">
              {{ record.order_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_count'">
              {{ record.product_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_sku_count'">
              {{ record.platform_sku_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'order_amount'">
              {{ record.order_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_amount'">
              {{ record.other_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'shouru_sum'">
              {{ record.shouru_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_cost'">
              {{ record.product_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'freight'">
              {{ record.freight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_fee'">
              {{ record.platform_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'fba_fee'">
              {{ record.fba_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_fee'">
              {{ record.other_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'zhichu_sum'">
              {{ record.zhichu_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'refund_amount'">
              {{ record.refund_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'maoli'">
              {{ record.maoli.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="按店铺">
      <div class="header">
        <a-form layout="inline" autocomplete="off">
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table bordered :columns="columns2" :data-source="data2" :loading="dataLoading" :scroll="{ x: width2 }"
          size="small">
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <!-- <a-button type="primary">导出订单收支明细</a-button> -->
            </div>
          </template>
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'shop'">
              {{ getShop(record.shopid) }}
            </template>
            <template v-if="column.dataIndex === 'order_count'">
              {{ record.order_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_count'">
              {{ record.product_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_sku_count'">
              {{ record.platform_sku_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'order_amount'">
              {{ record.order_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_amount'">
              {{ record.other_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'shouru_sum'">
              {{ record.shouru_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_cost'">
              {{ record.product_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'freight'">
              {{ record.freight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_fee'">
              {{ record.platform_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'fba_fee'">
              {{ record.fba_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_fee'">
              {{ record.other_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'zhichu_sum'">
              {{ record.zhichu_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'refund_amount'">
              {{ record.refund_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'maoli'">
              {{ record.maoli.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="按国家/地区">
      <div class="header">
        <a-form layout="inline" autocomplete="off">
          <a-form-item>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="getData">查询</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="content">
        <a-table bordered :columns="columns3" :data-source="data3" :loading="dataLoading" :scroll="{ x: width3 }"
          size="small">
          <template #title>
            <div class="theader">
              <span class="title">订单收支报告</span>
              <!-- <a-button type="primary">导出订单收支明细</a-button> -->
            </div>
          </template>
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'country'">
              <span style="color: red;"
                v-if="record.country === undefined || record.country === null || record.country === ''">未匹配</span>
              <span v-else>{{ record.country }}</span>
            </template>
            <template v-if="column.dataIndex === 'order_count'">
              {{ record.order_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_count'">
              {{ record.product_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_sku_count'">
              {{ record.platform_sku_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'order_amount'">
              {{ record.order_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_amount'">
              {{ record.other_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'shouru_sum'">
              {{ record.shouru_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_cost'">
              {{ record.product_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'freight'">
              {{ record.freight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_fee'">
              {{ record.platform_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'fba_fee'">
              {{ record.fba_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_fee'">
              {{ record.other_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'zhichu_sum'">
              {{ record.zhichu_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'refund_amount'">
              {{ record.refund_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'maoli'">
              {{ record.maoli.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns1 = [
  { title: '序号', dataIndex: 'index', width: 70, fixed: 'left', align: 'center' },
  { title: '平台', dataIndex: 'platform', width: 100, fixed: 'left', align: 'center' },
  { title: '订单数', dataIndex: 'order_count', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'product_count', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'platform_sku_count', width: 120, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '应收货款', dataIndex: 'order_amount', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'other_amount', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'shouru_sum', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'fba_fee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'other_fee', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'zhichu_sum', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refund_amount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refund_rate', width: 100, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_rate', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
const columns2 = [
  { title: '序号', dataIndex: 'index', width: 70, fixed: 'left', align: 'center' },
  { title: '店铺', dataIndex: 'shop', width: 200, fixed: 'left', align: 'center' },
  { title: '订单数', dataIndex: 'order_count', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'product_count', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'platform_sku_count', width: 120, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '应收货款', dataIndex: 'order_amount', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'other_amount', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'shouru_sum', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'fba_fee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'other_fee', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'zhichu_sum', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refund_amount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refund_rate', width: 100, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_rate', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
const columns3 = [
  { title: '序号', dataIndex: 'index', width: 70, fixed: 'left', align: 'center' },
  { title: '国家', dataIndex: 'country', width: 150, fixed: 'left', align: 'center' },
  { title: '订单数', dataIndex: 'order_count', width: 100, align: 'center' },
  { title: '商品数', dataIndex: 'product_count', width: 100, align: 'center' },
  { title: '平台SKU数', dataIndex: 'platform_sku_count', width: 120, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '应收货款', dataIndex: 'order_amount', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'other_amount', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'shouru_sum', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', width: 100, align: 'center' },
      { title: 'FBA费用', dataIndex: 'fba_fee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'other_fee', width: 100, align: 'center' },
      { title: '合计', dataIndex: 'zhichu_sum', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refund_amount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refund_rate', width: 100, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_rate', width: 100, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 180, fixed: 'right', align: 'center' },
];
export default defineComponent({
  name: 'FinanceIncomeReport',
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeTab: "1",
      daterange: [],
      dataLoading: false,
      data1: [],
      data2: [],
      data3: [],
      shops: [],
    });
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    const width3 = computed(() => proxy.$utils.twidth(columns3));
    onActivated(() => {
      changeTab();
    });
    const changeTab = () => {
      let beforeDay = proxy.$utils.moment().subtract(0, "days").format('YYYY-MM-DD');
      let before7Day = proxy.$utils.moment().subtract(6, "days").format('YYYY-MM-DD');
      state.daterange = [];
      state.daterange.push(before7Day);
      state.daterange.push(beforeDay);
      getData();
    };
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/finance/salesreport/recedisreport", { activeTab: state.activeTab, daterange: state.daterange }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state[`data${state.activeTab}`] = res.data.list;
          state.shops = res.data.shops;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const getShop = (value) => {
      return _.findWhere(state.shops, { value })?.label;
    };
    return {
      ...toRefs(state),
      columns1, width1,
      columns2, width2,
      columns3, width3,
      changeTab,
      getData,
      getShop,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>