<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="skus" placeholder="输入库存SKU/线上SKU查询，多个SKU空格隔开" enter-button="搜索" allow-clear
      @search="getData()" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="getData()">刷新数据</a-button>
    </a-space>
    <a-space>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导入">
              <a-menu-item key="bulkimport">模板导入组合SKU</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导入导出
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-button type="danger" @click="addProduct" :loading="initing">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增组合SKU
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'info'">
        <p>组合SKU</p>
        <p>中文名称</p>
        <p>英文名称</p>
        <p>线上SKU</p>
      </template>
      <template v-if="column.dataIndex === 'cinfo'">
        <p style="text-align:center;border-bottom:1px solid #ddd;">商品信息</p>
        <div class="d-flex justify-space-between align-center">
          <p style="width:80px;">缩略图</p>
          <div class="d-flex flex-column" style="flex:1;">
            <p>库存SKU/捆绑数量</p>
            <p>中文名称</p>
          </div>
        </div>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <div v-if="column.dataIndex === 'cover'" class="text-center" style="width:50px;">
        <a-tooltip v-if="record.cover" placement="rightBottom" color="#fff"
          :overlayStyle="{ width: '500px', height: '500px' }">
          <template #title>
            <img :src="record.cover" style="width:500px;height:500px;object-fit: contain;" />
          </template>
          <div class="image-header">
            <img :src="record.cover" style="object-fit: contain;" />
          </div>
        </a-tooltip>
        <div v-else class="image-header">
          <img :src="image_placeholder" style="object-fit: contain;" />
        </div>
      </div>
      <template v-if="column.dataIndex === 'info'">
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="doCopy(record.sku)">复制</a>
          </template>
          <a href="javascript:;" @click="editProduct(record)">{{ record.sku }}</a>
        </a-tooltip>
        <p>{{ record.name_cn }}</p>
        <p>{{ record.name_en }}</p>
        <p title="线上SKU">【{{ record.vskus.join(';') || '--' }}】</p>
      </template>
      <template v-if="column.dataIndex === 'cinfo'">
        <div v-for="item in record.combine_list" :key="item.sku" class="d-flex justify-space-between"
          style="border-bottom:1px solid #ececec;">
          <div class="text-center" style="width:80px;">
            <a-tooltip v-if="item.cover" placement="rightBottom" color="#fff"
              :overlayStyle="{ width: '500px', height: '500px' }">
              <template #title>
                <img :src="item.cover" style="width:500px;height:500px;object-fit: contain;" />
              </template>
              <div class="image-header">
                <img :src="item.cover" style="object-fit: contain;" />
              </div>
            </a-tooltip>
            <div v-else class="image-header">
              <img :src="image_placeholder" style="object-fit: contain;" />
            </div>
          </div>
          <div style="flex:1;">
            <div>
              <a href="javascript:;">{{ item.sku }}</a>
              <span class="text-error text-h6">×{{ item.quantity }}</span>
            </div>
            <p>{{ item.name_cn }}</p>
          </div>
        </div>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editProduct(record)">编辑</a>
          <a-popconfirm title="确定要删除此SKU?" @confirm="removeProduct(record._id)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-drawer :title="`${productForm._id !== '' ? '编辑' : '新增'}组合SKU`" placement="right" v-model:visible="productDrawer"
    @close="cancelSave" width="50%" :closable="!saving" :maskClosable="!saving">
    <template #extra>
      <a-button class="mr-2" @click="cancelSave">取消</a-button>
      <a-button type="primary" @click="saveProduct">保存</a-button>
    </template>
    <a-form ref="formRef" name="productForm" :model="productForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-row>
        <a-col :span="24">
          <a-card title="基本信息" size="small">
            <a-form-item label="组合SKU编号" name="sku" :rules="[{ required: true, message: '请输入组合SKU编号' }]">
              <a-input v-model:value="productForm.sku" placeholder="输入组合SKU编号" show-count :maxlength="100"
                :disabled="productForm._id !== '' || saving" />
            </a-form-item>
            <a-form-item label="中文名称" name="name_cn" :rules="[{ required: true, message: '请输入组合SKU中文名称' },]">
              <a-input v-model:value="productForm.name_cn" show-count :maxlength="100" :disabled="saving" />
            </a-form-item>
            <a-form-item label="英文名称" name="name_en">
              <a-input v-model:value="productForm.name_en" show-count :maxlength="100" :disabled="saving" />
            </a-form-item>
            <a-form-item label="线上SKU" name="vsku">
              <div class="d-flex flex-column">
                <a-input-group compact>
                  <a-input v-model:value="vsku" placeholder="输入线上SKU编号" show-count :maxlength="100" :disabled="saving"
                    style="width: calc(100% - 32px)" @keyup.enter="addVSku" />
                  <a-button type="primary" :disabled="saving" @click="addVSku">
                    <template #icon>
                      <PlusCircleOutlined />
                    </template>
                  </a-button>
                </a-input-group>
                <div class="d-flex flex-wrap" style="height:50px;border:1px solid #ddd;overflow-y:auto;">
                  <a-tag v-for="sku in productForm.vskus" :key="sku" color="purple" :closable="!saving"
                    @close="removeVSku(sku)" :disabled="saving" style="height:20px;">
                    {{ sku }}
                  </a-tag>
                </div>
              </div>
            </a-form-item>
          </a-card>
        </a-col>
        <a-col :span="24">
          <a-card title="产品图片" size="small">
            <a-row>
              <a-col :span="14">
                选择图片(<span class="text-error">{{ productForm.images.length }}</span>/{{ MAX_IMAGE_COUNT }})
                <a-dropdown>
                  <template #overlay>
                    <ImagePicker :max_count="MAX_IMAGE_COUNT" :images="productForm.images"
                      @local-upload-success="onLocalUploadSuccess" @network-input-success="onNetworkInputSuccess"
                      @space-pick-success="onSpacePickSuccess" />
                  </template>
                  <a-button>
                    选择图片
                    <DownOutlined />
                  </a-button>
                </a-dropdown>
              </a-col>
              <a-col :span="10">
                <div class="d-flex flex-column justify-center align-center">
                  <span>剩余<span>{{ leftSize }}</span>MB</span>
                  <a-progress :percent="leftPercent" :showInfo="false" :strokeColor="leftColor" />
                </div>
              </a-col>
            </a-row>
            <a-row style="height:115px;overflow-y:auto;">
              <a-col :span="4" v-for="image in productForm.images" :key="image">
                <div style="width:105px;height:105px;position:relative;border:1px solid #e8e8e8;padding:2px;">
                  <a-image width="101px" height="101px" :src="image" style="object-fit: contain;height:100%;">
                    <template #placeholder>
                      <a-image :src="image_placeholder" width="101px" height="101px" :preview="false" />
                    </template>
                  </a-image>
                  <svg-icon v-if="image === productForm.cover" iconClass="zhutu"
                    style="position:absolute;top:0px;left:0px;height:17px;width:40px;" />
                  <div style="position:absolute;bottom:0;left:0;width:101px;height:20px;"
                    class="d-flex justify-space-between">
                    <template v-if="image !== productForm.cover">
                      <a-tooltip>
                        <template #title>设为主图</template>
                        <HomeOutlined @click="setCover(image)" style="font-size:20px;color:#1890ff;" />
                      </a-tooltip>
                      <a-tooltip>
                        <template #title>删除图片</template>
                        <DeleteOutlined @click="removeImage(image)" style="font-size:20px;color:#ff4d4f;" />
                      </a-tooltip>
                    </template>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="24">
          <a-card size="small">
            <template #title>
              <div class="d-flex justify-space-between align-center">
                <span>组合商品清单</span>
                <div>
                  <a href="javascript:;" @click="findsku">
                    <PlusCircleOutlined />
                    <span class="underline ml-1">添加商品</span>
                  </a>
                </div>
              </div>
            </template>
            <a-row :gutter="16">
              <a-col :span="8">
                <a-form-item label="库存SKU">
                  <a-input v-model:value="sku_code" placeholder="库存SKU" :disabled="saving" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="数量">
                  <a-input v-model:value="sku_quantity" type="number" :disabled="saving" placeholder="数量" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-button type="primary" :loading="saving" :disabled="saving" @click="addSku">
                  添加
                </a-button>
              </a-col>
            </a-row>
            <a-table :columns="skucolumns" bordered :data-source="productForm.combine_list" :scroll="{ y: 200 }"
              :pagination="false" size="small">
              <template #bodyCell="{ record, column, index }">
                <template v-if="column.dataIndex === 'cover'">
                  <a-tooltip v-if="record.cover" placement="rightBottom" color="#fff"
                    :overlayStyle="{ width: '500px', height: '500px' }">
                    <template #title>
                      <img :src="record.cover" style="width:500px;height:500px;object-fit: contain;" />
                    </template>
                    <div class="image-header">
                      <img :src="record.cover" style="object-fit: contain;" />
                    </div>
                  </a-tooltip>
                  <div v-else class="image-header">
                    <img :src="image_placeholder" style="object-fit: contain;" />
                  </div>
                </template>
                <template v-if="column.dataIndex === 'sku'">
                  <a href="javascript:;">{{ record.sku }}</a>
                </template>
                <template v-if="column.dataIndex === 'quantity'">
                  <a-input-number v-model:value="record.quantity" :min="1" :disabled="saving" placeholder="数量">
                    <template #addonBefore>
                      <MinusCircleOutlined :disabled="saving || record.quantity < 2"
                        @click="() => record.quantity > 1 ? record.quantity-- : ''"
                        :class="`i-amount ${record.quantity > 1 ? 'text-error' : 'disabled'}`" />
                    </template>
                    <template #addonAfter>
                      <PlusCircleOutlined :disabled="saving" @click="() => record.quantity++"
                        class="i-amount text-success" />
                    </template>
                  </a-input-number>
                </template>
                <template v-if="column.dataIndex === 'operation'">
                  <a href="javascript:;" class="text-error" @click="removeSku(index)">删除</a>
                </template>
              </template>
            </a-table>
          </a-card>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
  <a-modal v-model:visible="skuModal" title="查找SKU" :maskClosable="false" :closable="!skuloading" width="800px"
    @cancel="() => skuModal = false">
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索类型：</div>
      <a-radio-group v-model:value="skuForm.type" size="small">
        <a-radio-button value="sku">库存SKU</a-radio-button>
        <a-radio-button value="name_cn">库存SKU名称</a-radio-button>
        <a-radio-button value="name_en">库存SKU英文名称</a-radio-button>
        <a-radio-button value="vsku">库存线上SKU</a-radio-button>
      </a-radio-group>
    </div>
    <div class="d-flex align-center mb-2">
      <div style="width:85px;">搜索内容：</div>
      <a-input-search v-if="skuForm.type === 'sku'" v-model:value="skuForm.text" placeholder="请输入库存SKU编码"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_cn'" v-model:value="skuForm.text" placeholder="请输入库存SKU名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'name_en'" v-model:value="skuForm.text" placeholder="请输入库存SKU英文名称"
        enter-button="搜索" @search="searchSku" />
      <a-input-search v-if="skuForm.type === 'vsku'" v-model:value="skuForm.text" placeholder="请输入库存线上SKU编码"
        enter-button="搜索" @search="searchSku" />
    </div>
    <a-table :columns="skuColumns" bordered :data-source="skudata" :loading="skuloading" :scroll="{ y: 400 }"
      :pagination="{ position: ['bottomLeft'], current: skupage, pageSize: skulimit, total: skucount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { skupage = pagination.current; skulimit = pagination.pageSize; }" row-key="sku"
      :row-selection="{ selectedRowKeys: selectedSkus, onChange: (keys) => { selectedSkus = keys; } }" size="small">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'cover'" class="text-center" style="width:50px;">
          <a-tooltip v-if="record.cover" placement="rightBottom" color="#fff"
            :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="record.cover" style="width:500px;height:500px;object-fit: contain;" />
            </template>
            <div class="image-header">
              <img :src="record.cover" style="object-fit: contain;" />
            </div>
          </a-tooltip>
          <div v-else class="image-header">
            <img :src="image_placeholder" style="object-fit: contain;" />
          </div>
        </div>
        <template v-if="column.dataIndex === 'info'">
          <p>{{ record.sku }}</p>
          <p>{{ record.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'quantity'">
          <a-input-number v-model:value="record.quantity" :min="1" :disabled="skuloading" placeholder="数量">
            <template #addonBefore>
              <MinusCircleOutlined :disabled="skuloading || record.quantity < 2"
                @click="() => record.quantity > 1 ? record.quantity-- : ''"
                :class="`i-amount ${record.quantity > 1 ? 'text-error' : 'disabled'}`" />
            </template>
            <template #addonAfter>
              <PlusCircleOutlined :disabled="skuloading" @click="() => record.quantity++"
                class="i-amount text-success" />
            </template>
          </a-input-number>
        </template>
      </template>
    </a-table>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <div>
          <a-checkbox v-model:checked="skuClose">
            添加后直接关闭窗口
          </a-checkbox>
          <a-button @click="addSkus" type="primary" :loading="skuloading">添加</a-button>
          <a-button @click="() => skuModal = false" :disabled="skuloading">关闭</a-button>
        </div>
      </div>
    </template>
  </a-modal>
  <UploadTask ref="uploadTask" />
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, PlusCircleOutlined, MinusCircleOutlined, HomeOutlined, UploadOutlined, DeleteOutlined, } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import ImagePicker from '@/components/ImagePicker.vue';
import UploadTask from '@/components/UploadTask.vue';
const columns = [
  { title: '缩略图', dataIndex: 'cover', width: 50 },
  { title: '编号/中文名称/英文名称/线上SKU', dataIndex: 'info', width: 200 },
  { title: '缩略图/库存SKU/捆绑数量/中文名称', dataIndex: 'cinfo', width: 250, },
  { title: '组合成本', dataIndex: 'combine_cost', width: 100 },
  { title: '组合重量', dataIndex: 'combine_weight', width: 100 },
  { title: '创建时间', dataIndex: 'create_time', width: 150 },
  { title: '操作', dataIndex: 'operation', width: 180, align: 'center', fixed: 'right' },
];
const skuColumns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '商品信息', dataIndex: 'info' },
  { title: "数量", dataIndex: "quantity", width: 200 },
];
const skucolumns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: '库存SKU编号', dataIndex: 'sku', width: 150 },
  { title: '数量', dataIndex: 'quantity', wdith: 100 },
  { title: '中文名称', dataIndex: 'name_cn', },
  { title: "操作", dataIndex: "operation", width: 80 },
];
const MAX_IMAGE_COUNT = 20;
export default defineComponent({
  name: 'ProductSkus',
  components: { DownOutlined, PlusCircleOutlined, MinusCircleOutlined, HomeOutlined, UploadOutlined, DeleteOutlined, ImagePicker, UploadTask, },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref();
    const uploadTask = ref();
    const state = reactive({
      skus: "",
      productDrawer: false,
      initing: false,
      productForm: {},
      selectedRowKeys: [],
      dataLoading: false,
      sku_code: "",
      sku_quantity: 1,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      saving: false,
      choosing: false,
      vsku: "",
      skuModal: false,
      skuForm: { type: "sku", text: "", },
      skupage: 1,
      skulimit: 100,
      skudata: [],
      skulength: 0,
      skucount: 0,
      skuloading: false,
      selectedSkus: [],
      skuClose: true,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const totalSize = computed(() => proxy.$utils.d((store.state.imagepicker.total_size) / 1024 / 1024).toFixed(2));
    const usedSize = computed(() => proxy.$utils.d((store.state.imagepicker.used_size) / 1024 / 1024).toFixed(2));
    const leftSize = computed(() => proxy.$utils.d(totalSize.value).minus(usedSize.value).toFixed(2));
    const leftPercent = computed(() => Number(proxy.$utils.d(leftSize.value).dividedBy(totalSize.value).times(100).toFixed(2)));
    const leftColor = computed(() => {
      if (Number(leftPercent.value < 10)) return '#ff4d4f';
      else if (Number(leftPercent.value < 30)) return '#faad14';
      else if (Number(leftPercent.value < 50)) return '#1890ff';
      else return '#52c41a';
    });
    watch(() => state.skus, () => {
      if (!state.skus) getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.skupage, () => searchSku());
    watch(() => state.skulimit, () => {
      if (state.skulimit !== 1) state.skulimit = 1;
      else searchSku();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData(route.query.sku);
    });
    const getData = (sku) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/cskus", { page: state.page, limit: state.limit, skus: sku || state.skus, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          if (sku && res.data.list.length === 1) {
            getProduct(res.data.list[0]._id);
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addProduct = () => {
      getProduct();
      getSpaceUsage();
    };
    const getProduct = (productid) => {
      state.initing = true;
      message.loading("", 0);
      proxy.$api.doAPI("/product/cinit", { productid }).then((res) => {
        state.initing = false;
        message.destroy();
        if (res.code === 0) {
          state.productForm = res.data.productForm;
          state.productDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.initing = false;
        message.destroy();
        console.error(err);
      });
    }
    const saveProduct = async () => {
      try {
        await formRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/product/csave", { productForm: state.productForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            state.productDrawer = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    const editProduct = (product) => {
      getProduct(product._id);
      getSpaceUsage();
    };
    const removeProduct = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/remove", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          message.success("删除成功");
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
      state.productDrawer = false;
    }
    const addVSku = () => {
      if (state.vsku) {
        if (state.productForm.vskus.indexOf(state.vsku) === -1) {
          state.productForm.vskus = [...state.productForm.vskus, state.vsku];
          state.vsku = "";
        } else {
          message.error("线上SKU重复");
        }
      }
    }
    const removeVSku = (sku) => {
      state.productForm.vskus = _.filter(state.productForm.vskus, n => n !== sku);
    }
    const onLocalUploadSuccess = (data) => {
      console.log('onLocalUploadSuccess', data);
      let images = toRaw(state.productForm.images)
      images.push(data.url);
      state.productForm.images = _.uniq(images);
      if (!state.productForm.cover) state.productForm.cover = images[0];
    }
    const onNetworkInputSuccess = (data) => {
      console.log('onNetworkInputSuccess', data);
      let images = toRaw(state.productForm.images)
      images.push(...data.images);
      state.productForm.images = _.uniq(images);
      if (!state.productForm.cover) state.productForm.cover = images[0];
    }
    const onSpacePickSuccess = (data) => {
      console.log('onSpacePickSuccess', data);
      let images = toRaw(state.productForm.images)
      images.push(...data.images);
      state.productForm.images = _.uniq(images);
      if (!state.productForm.cover) state.productForm.cover = images[0];
    }
    const setCover = (image) => {
      let new_images = [], old_images = toRaw(state.productForm.images);
      for (let i = 0; i < old_images.length; i++) {
        if (old_images[i] !== image) {
          new_images.push(old_images[i]);
        }
      }
      state.productForm.images = _.uniq([image, ...new_images]);
      state.productForm.cover = image;
    }
    const removeImage = (image) => {
      state.productForm.images = _.filter(state.productForm.images, n => n !== image);
    }
    const getSpaceUsage = () => {
      store.dispatch("imagepicker/getSpaceUsage");
    }
    const addSku = () => {
      if (!state.sku_code) return message.error("库存SKU不能为空")
      if (!state.sku_quantity) return message.error("数量不能为空")
      if (state.sku_quantity < 1) return message.error("数量不能小于1")
      if (_.findWhere(state.productForm.combine_list, { sku: state.sku_code })) return message.error("SKU已存在，请勿重复添加");
      findonesku();
    }
    const findonesku = () => {
      state.skuloading = true;
      proxy.$api.doAPI("/product/findonesku", { sku: state.sku_code }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          combineSkus([_.extend({ quantity: state.sku_quantity }, { ...res.data.product, productid: res.data.product._id })]);
          state.sku_code = "";
          state.sku_quantity = "";
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const findsku = () => {
      console.log('findsku');
      state.skuForm = { type: "sku", text: "", };
      state.skupage = 1;
      state.skucount = 0;
      state.skudata = [];
      state.skuModal = true;
    }
    const searchSku = () => {
      // if (!state.skuForm.text) return message.error("请输入搜索内容");
      state.selectedSkus = [];
      state.skuloading = true;
      proxy.$api.doAPI("/product/searchsku", { page: state.skupage, limit: state.skulimit, skuForm: state.skuForm, }).then((res) => {
        state.skuloading = false;
        if (res.code === 0) {
          state.skudata = _.map(res.data.list, n => ({ ...n, quantity: 1 }));
          state.skulength = res.data.length;
          state.skucount = res.data.count;
          if (state.skulength > 0 && state.skupage > state.skulength) state.skupage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.skuloading = false;
        console.error(err);
      });
    }
    const addSkus = () => {
      if (state.selectedSkus.length === 0) return message.error("请选择要添加的数据");
      let skus = _.filter(state.skudata, n => state.selectedSkus.indexOf(n.sku) > -1);
      console.log(skus);
      for (let i = 0; i < skus.length; i++) {
        let { sku, quantity } = skus[i];
        if (!quantity) return message.error(`${sku}数量不能为空`);
        if (quantity < 1) return message.error(`${sku}数量不能小于1`)
      }
      combineSkus(skus);
      state.selectedSkus = [];
      if (state.skuClose) state.skuModal = false;
    }
    const combineSkus = (skus) => {
      let combine_list = [], combine_skus = {};
      for (let i = 0; i < state.productForm.combine_list.length; i++) {
        let { sku } = state.productForm.combine_list[i];
        if (!combine_skus[sku]) {
          combine_list.push(_.extend({}, state.productForm.combine_list[i]));
          combine_skus[sku] = true;
        }
      }
      for (let i = 0; i < skus.length; i++) {
        let { sku } = skus[i];
        if (!combine_skus[sku]) {
          combine_list.push(_.extend({}, skus[i]));
          combine_skus[sku] = true;
        }
      }
      state.productForm.combine_list = combine_list;
    }
    const removeSku = (index) => {
      state.productForm.combine_list.splice(index, 1);
    }
    const handleBulkClick = ({ key }) => {
      if (state.selectedRowKeys.length === 0) return message.warn("未选择商品");
      console.log('handleBulkClick', key);
      switch (key) {

      }
    }
    const handleExportClick = ({ key }) => {
      console.log('handleExportClick', key);
      switch (key) {
        case "bulkimport":
          uploadTask.value.showUpload({ title: "模板导入组合SKU", file: "组合SKU模板.xlsx", type: "import_createcombinesku" });
          break;
        case "exportselected": break;
        case "exportall": break;
      }
    }
    return {
      ...toRefs(state),
      locale,
      MAX_IMAGE_COUNT,
      formRef,
      uploadTask,
      columns,
      skuColumns,
      skucolumns,
      width,
      leftSize,
      leftPercent,
      leftColor,
      getData,
      addProduct,
      saveProduct,
      editProduct,
      removeProduct,
      cancelSave,
      addVSku,
      removeVSku,
      onLocalUploadSuccess,
      onNetworkInputSuccess,
      onSpacePickSuccess,
      setCover,
      removeImage,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
      addSku,
      findsku,
      searchSku,
      addSkus,
      removeSku,
      handleBulkClick,
      handleExportClick,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.i-amount > svg) {
  width: 20px;
  height: 20px;
}

p {
  margin: 0;
  padding: 0;
}
</style>