<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">查询条件：</span>
      <a-radio-group v-model:value="day" @change="getData">
        <a-radio-button :value="1">昨天</a-radio-button>
        <a-radio-button :value="7">近7天</a-radio-button>
        <a-radio-button :value="15">近15天</a-radio-button>
        <a-radio-button :value="30">近30天</a-radio-button>
      </a-radio-group>
    </div>
    <div class="search">
      <span style="width:80px;">选择日期：</span>
      <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
      <a-button class="ma-2" type="primary" @click="getData">查询</a-button>
    </div>
    <div class="review-header">
      <a-row>
        <a-col :span="5" class="hitem">
          <a-card style="width: 100%;" :bordered="false">
            <div class="chart-card-header">
              <div class="meta">
                <div class="chart-card-title">总订单数</div>
                <div class="chart-card-action"></div>
              </div>
              <div class="total">
                <svg-icon iconClass="dingdan" :style="{ 'width': '32px', 'height': '32px' }" /> <span> {{ order_count }}
                </span>
              </div>
            </div>
            <div class="chart-card-footer">
              <div class="filed">
                今日新增订单 <span> {{ today_count }} </span>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="5" class="hitem">
          <a-card style="width: 100%;" :bordered="false">
            <div class="chart-card-header">
              <div class="meta">
                <div class="chart-card-title">总销售额（元）</div>
                <div class="chart-card-action"></div>
              </div>
              <div class="total">
                <span> {{ order_sales }} </span>
              </div>
            </div>
            <div class="chart-card-footer">
              <div class="filed">
                今销售额 <span> {{ today_sales }} </span>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="5" class="hitem">
          <a-card style="width: 100%;" :bordered="false">
            <div class="chart-card-header">
              <div class="meta">
                <div class="chart-card-title">总支出（元）</div>
                <div class="chart-card-action"></div>
              </div>
              <div class="total">
                <span> {{ order_disburse }} </span>
              </div>
            </div>
            <div class="chart-card-footer">
              <div class="filed">
                今日支出 <span> {{ today_disburse }} </span>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="5" class="hitem">
          <a-card style="width: 100%;" :bordered="false">
            <div class="chart-card-header">
              <div class="meta">
                <div class="chart-card-title">总退款（元）</div>
                <div class="chart-card-action"></div>
              </div>
              <div class="total">
                <span> {{ order_refund }} </span>
              </div>
            </div>
            <div class="chart-card-footer">
              <div class="filed">
                今日退款 <span> {{ today_refund }} </span>
              </div>
            </div>
          </a-card>
        </a-col>
        <a-col :span="4" class="hitem">
          <a-card style="width: 100%;" :bordered="false">
            <div class="chart-card-header">
              <div class="meta">
                <div class="chart-card-title">总毛利（元）</div>
                <div class="chart-card-action"></div>
              </div>
              <div class="total">
                <span class="text-error"> {{ order_profit }} </span>
              </div>
            </div>
            <div class="chart-card-footer">
              <div class="filed">
                今日毛利 <span> {{ today_profit }} </span>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'orderprofit'">
        <p>{{ (Number(record.ordersales) - Number(record.orderdisburse)).toFixed(2) }}</p>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '日期', dataIndex: 'time', width: 150, align: 'center', },
  { title: '总订单数', dataIndex: 'ordercount', width: 150, align: 'center', },
  { title: '总销售额', dataIndex: 'ordersales', width: 150, align: 'center', },
  { title: '总支出', dataIndex: 'orderdisburse', width: 150, align: 'center', },
  { title: '总退款', dataIndex: 'orderrefund', width: 150, align: 'center', },
  { title: '总毛利', dataIndex: 'orderprofit', width: 150, align: 'center', },
];
export default defineComponent({
  name: 'OperationAnalysisOpdata',
  components: {
    DownOutlined,
    DownloadOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      day: 7,
      days: [],
      data: [],
      daterange: [],
      firdaterange: [],
      order_count: 0,
      today_count: 0,
      order_sales: 0,
      today_sales: 0,
      order_disburse: 0,
      today_disburse: 0,
      order_refund: 0,
      today_refund: 0,
      order_profit: 0,
      today_profit: 0,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onActivated(() => {
      getData();
    });
    const getDay = (day) => {
      state.days = [];
      for (let i = 0; i < day; i++) {
        const time = new Date(new Date().setDate(new Date().getDate() + i - day));
        const year = time.getFullYear();
        const month = `0${time.getMonth() + 1}`.slice(-2);
        const strDate = `0${time.getDate()}`.slice(-2);
        if (i === 0) {
          state.days.push(`${year}-${month}-${strDate}`);
        }
        if (i === day - 1) {
          state.days.push(`${year}-${month}-${strDate}`);
        }
      }
    };
    const getData = () => {
      getDay(state.day);
      proxy.$api.doAPI("/finance/dataanalysis/managementboard", {
        firdaterange: state.days,
        daterange: state.daterange,
      }).then((res) => {
        if (res.code === 0) {
          state.order_sales = proxy.$utils.formatMoney(res.data.order_sales, 2, '');
          state.today_sales = proxy.$utils.formatMoney(res.data.today_sales, 2, '');
          state.order_count = proxy.$utils.formatMoney(res.data.order_count, 0, '');
          state.today_count = proxy.$utils.formatMoney(res.data.today_count, 0, '');
          state.order_disburse = proxy.$utils.formatMoney(res.data.order_disburse, 2, '');
          state.today_disburse = proxy.$utils.formatMoney(res.data.today_disburse, 2, '');
          state.order_refund = proxy.$utils.formatMoney(res.data.order_refund, 2, '');
          state.today_refund = proxy.$utils.formatMoney(res.data.today_refund, 2, '');
          let order_profit = Number(res.data.order_sales) - Number(res.data.order_disburse);
          state.order_profit = proxy.$utils.formatMoney(order_profit, 2, '');
          let today_profit = Number(res.data.today_sales) - Number(res.data.today_disburse);
          state.today_profit = proxy.$utils.formatMoney(today_profit, 2, '');
          state.data = res.data.list;
          state.firdaterange = [];
          state.daterange = [];
        } else {
          message.error(res.msg);
          state.firdaterange = [];
          state.daterange = [];
        }
      }).catch((err) => {
        console.error(err);
        state.firdaterange = [];
        state.daterange = [];
      });
    };
    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      handleChange,
    };
  }
});
</script>
<style lang="less" scoped>
.review-header {
  .hitem {
    margin-bottom: 15px;
  }

  .hitem:first-child {
    padding-left: 0;
  }

  .hitem:last-child {
    padding-right: 0;
  }

  .meta {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
  }

  .total {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    color: #000;
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    line-height: 38px;
    height: 38px;
  }

  .chart-card-footer {
    border-top: 1px solid #e8e8e8;
    padding-top: 9px;
    margin-top: 8px;
  }

  .chart-card-footer .filed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}

:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
