<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData" :loading="dataLoading">刷新数据</a-button>
    <a-button type="primary" @click="addPermission" :loading="loading">
      <template #icon>
        <PlusCircleOutlined />
      </template>新增权限
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ y: 600 }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'list'">
        <div class="d-flex flex-column justify-center align-left">
          <span v-for="(item, index) in record.list" :key="index">
            {{ item.title }} { {{ item.url }} }
          </span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'enabled'">
        <a-switch v-model:checked="record.enabled" checked-children="启用" un-checked-children="禁用"
          @change="(checked) => changePermission(checked, record)" :loading="switching[record._id]" />
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editPermission(record)">编辑</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="permissionModal" :title="`${permissionForm._id !== '' ? '编辑' : '新增'}角色`" @ok="savePermission"
    ok-text="确认" cancel-text="取消" :confirm-loading="saving" :cancel-button-props="{ disabled: saving }"
    :maskClosable="false" :closable="!saving" width="750px">
    <a-form ref="permissionRef" name="permissionForm" :model="permissionForm" autocomplete="off" layout="vertical">
      <a-form-item label="权限组" name="title" :rules="[{ required: true, message: '请输入权限组' }]">
        <a-input v-model:value="permissionForm.title" :disabled="saving" />
      </a-form-item>
      <a-form-item label="权限值" name="list">
        <a-table :columns="columns2" :data-source="permissionForm.list" :scroll="{ y: 300 }" :pagination="false" size="small">
          <template #title>
            <div class="d-flex align-center mb-2">
              <a-input v-model:value="addvalue.title" placeholder="权限名" :disabled="saving" />
              <a-input v-model:value="addvalue.url" placeholder="权限值" :disabled="saving" class="ml-2" />
              <a href="javascript:;" @click="add" style="width:70px;" class="text-success ml-2">添加</a>
            </div>
          </template>
          <template #bodyCell="{ index, record, column }">
            <template v-if="column.dataIndex === 'title'">
              <a-input v-if="editing[record.index]" v-model:value="editvalue.title" :disabled="saving" />
              <span v-else> {{ record.title }} </span>
            </template>
            <template v-if="column.dataIndex === 'index'">
              <div class="d-flex flex-column align-center">
                <a-button style="width:20px;height:20px;padding:0;" :disabled="index === 0" @click="moveUp({ index })">
                  <template #icon>
                    <UpOutlined />
                  </template>
                </a-button>
                {{ index + 1 }}
                <a-button style="width:20px;height:20px;padding:0;" :disabled="index === permissionForm.list.length - 1"
                  @click="moveDown({ index })">
                  <template #icon>
                    <DownOutlined />
                  </template>
                </a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'url'">
              <a-input v-if="editing[record.index]" v-model:value="editvalue.url" :disabled="saving" />
              <span v-else> {{ record.url }} </span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <div v-if="editing[record.index]">
                  <a href="javascript:;" @click="save(record)" class="text-success">保存</a>
                  <a href="javascript:;" @click="cancelsave(record)" class="text-error ml-1">取消</a>
                </div>
                <div v-else>
                  <a href="javascript:;" @click="edit(record)">编辑</a>
                  <a href="javascript:;" @click="remove(record)" class="text-error ml-1">删除</a>
                </div>
              </a-space>
            </template>
          </template>
        </a-table>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { UpOutlined, DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, },
  { title: '权限组', dataIndex: 'title', width: 100, },
  { title: '权限列表', dataIndex: 'list', },
  { title: '状态', dataIndex: 'enabled', width: 120, },
  { title: '创建时间', dataIndex: 'create_time', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right' },
];
const columns2 = [
  { title: '排序', dataIndex: 'index', width: 100, },
  { title: '权限名', dataIndex: 'title', width: 150, },
  { title: '权限值', dataIndex: 'url', width: 300, },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right' },
]
export default defineComponent({
  name: 'AccountSettingPermissions',
  components: { UpOutlined, DownOutlined, SearchOutlined, PlusCircleOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const permissionRef = ref();
    const state = reactive({
      data: [],
      dataLoading: false,
      saving: false,
      permissionForm: { list: [] },
      permissionModal: false,
      loading: false,
      permissions: [],
      editing: {},
      editvalue: {},
      addvalue: { title: "", url: "", },
      adding: false,
      switching: {},
    });
    const addding = computed(() => state.editing[state.editvalue.index] || state.adding);
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/account/permissionlist").then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            };
          });
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addPermission = () => {
      state.permissionForm = { _id: "", title: "", list: [], };
      state.permissionModal = true;
    }
    const editPermission = (item) => {
      state.permissionForm = {
        _id: item._id,
        title: item.title,
        list: _.map(item.list, (n, i) => _.extend({ index: i }, n)),
      };
      state.addvalue = { title: "", url: "" };
      state.permissionModal = true;
    }
    const savePermission = async () => {
      try {
        await permissionRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/account/savepermission", { permissionForm: state.permissionForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("保存成功");
            state.permissionModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const changePermission = (enabled, item) => {
      state.switching[item._id] = true;
      proxy.$api.doAPI("/account/changepermission", { enabled, _id: item._id }).then((res) => {
        state.switching[item._id] = false;
        if (res.code === 0) {
        } else {
          message.error(res.msg);
        }
        getData();
      }).catch((err) => {
        state.switching[item._id] = false;
        console.error(err);
        getData();
      });
    }
    const add = () => {
      if (!state.addvalue.title) return message.error("权限名不能为空");
      if (!state.addvalue.url) return message.error("权限值不能为空");
      let list = [];
      for (let i = 0; i < state.permissionForm.list.length; i++) {
        let { index, title, url } = state.permissionForm.list[i];
        list.push({ index, title, url });
      }
      list.push({
        index: state.permissionForm.list.length,
        title: state.addvalue.title,
        url: state.addvalue.url,
      });
      state.permissionForm.list = list;
      message.success("添加成功");
      state.addvalue = { title: "", url: "" };
    }
    const edit = (item) => {
      state.editing = {};
      state.editvalue = {
        index: item.index,
        title: item.title,
        url: item.url,
      };
      state.editing[item.index] = true;
    }
    const save = (item) => {
      let list = [];
      for (let i = 0; i < state.permissionForm.list.length; i++) {
        let { index, title, url } = state.permissionForm.list[i];
        if (index === state.editvalue.index) {
          if (!state.editvalue.title) return message.error("权限名不能为空");
          if (!state.editvalue.url) return message.error("权限值不能为空");
          list.push({ index, title: state.editvalue.title, url: state.editvalue.url });
        } else {
          list.push({ index, title, url });
        }
      }
      state.permissionForm.list = list;
      state.editing[item.index] = false;
    }
    const cancelsave = (item) => {
      state.editing = {};
    }
    const remove = (item) => {
      let list = [];
      for (let i = 0; i < state.permissionForm.list.length; i++) {
        let { index, title, url } = state.permissionForm.list[i];
        if (index === item.index) continue;
        list.push({ index: i, title, url });
      }
      state.permissionForm.list = list;
    }
    const moveUp = ({ index }) => {
      let permission_item1 = toRaw(state.permissionForm.list)[index];
      let permission_item2 = toRaw(state.permissionForm.list)[index - 1];
      state.permissionForm.list[index - 1] = permission_item1;
      state.permissionForm.list[index] = permission_item2;
    }
    const moveDown = ({ index }) => {
      let permission_item1 = toRaw(state.permissionForm.list)[index];
      let permission_item2 = toRaw(state.permissionForm.list)[index + 1];
      state.permissionForm.list[index + 1] = permission_item1;
      state.permissionForm.list[index] = permission_item2;
    }
    return {
      ...toRefs(state),
      permissionRef,
      columns,
      columns2,
      addding,
      getData,
      addPermission,
      editPermission,
      savePermission,
      changePermission,
      add,
      edit,
      save,
      cancelsave,
      remove,
      moveUp,
      moveDown,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-title) {
  padding: 0;
}

:deep(.ant-switch) {
  background-color: #ff4d4f;
}

:deep(.ant-switch-checked) {
  background-color: #52c41a;
}

p {
  margin: 0;
  padding: 0;
}
</style>

