<template>
  <a-alert message="目前支持刊登的站点：美国、加拿大、墨西哥、巴西、西班牙、英国、法国、比利时、荷兰、德国、意大利、瑞典、波兰、澳大利亚、日本；其他站点陆续增加中" type="info" show-icon
    class="mb-2" />
  <a-tabs v-model:activeKey="activeTab">
    <a-tab-pane key="0" tab="待上架/刊登商品">
      <div class="d-flex align-center mb-2">
        <span style="width:70px;">状态：</span>
        <a-radio-group v-model:value="queryForm.state" @change="getData">
          <a-radio-button :value="0">
            草稿 ({{ getstatisticscount('draft') }})
          </a-radio-button>
          <a-radio-button :value="2">
            刊登中 ({{ getstatisticscount('publish') + getstatisticscount('schedule') }})
          </a-radio-button>
          <a-radio-button :value="3">
            刊登成功 ({{ getstatisticscount('success') }})
          </a-radio-button>
          <a-radio-button :value="4">
            刊登失败 ({{ getstatisticscount('fail') }})
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="d-flex align-center mb-2">
        <span style="width:80px;">搜索：</span>
        <a-select v-model:value="queryForm.account_username" :options="shopaccounts" placeholder="选择账号" show-search
          :filter-option="$filters.filterOption" allow-clear style="width:300px;">
        </a-select>
        <a-input-search v-model:value="queryForm.searchtext" placeholder="输入名称查询" enter-button="搜索" allow-clear
          @search="getData" />
      </div>
      <div class="d-flex justify-space-between align-center mb-2">
        <a-space>
          <a-button type="primary" @click="getData">刷新数据</a-button>
        </a-space>
        <a-space>
          <a-button type="danger" @click="addListing">
            <template #icon>
              <PlusCircleOutlined />
            </template>新增刊登
          </a-button>
        </a-space>
      </div>
      <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
        :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
        @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
        size="small">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'item_name'">
            <p>账号</p>
            <p>站点</p>
            <p>标题</p>
          </template>
        </template>
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'main_image_url'">
            <a-tooltip v-if="record.images[0]" color="white" placement="rightBottom">
              <template #title>
                <a-image :src="record.images[0]" width="480px" height="480px" style="object-fit: contain;height:100%;">
                  <template #placeholder>
                    <a-image :src="image_placeholder" width="480px" height="480px" :preview="false" />
                  </template>
                </a-image>
              </template>
              <a href="javascript:;">
                <a-image :src="record.images[0]" width="80px" height="80px" style="object-fit: contain;height:100%;">
                  <template #placeholder>
                    <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
                  </template>
                </a-image>
              </a>
            </a-tooltip>
            <a-image v-else :src="image_placeholder" width="80px" height="80px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </template>
          <template v-if="column.dataIndex === 'item_name'">
            <p class="text-primary">{{ record.account_username }}</p>
            <p v-if="record.state === 'draft'">{{ record.region_names.join(',') }}</p>
            <template v-else>
              <template v-for="(region, index) in record.regions" :key="region">
                <div class="d-flex flex-column">
                  <span v-if="record.region_state?.[region] === 'success'" class="text-success">
                    {{ record.region_names[index] }}(刊登成功)
                    <a v-if="record.asin?.[region]" :href="`${record.amazon_product_urls[index]}${record.asin[region]}`"
                      target="_blank">
                      <amazon-outlined />
                    </a>
                  </span>
                  <span v-else-if="record.region_state?.[region] === 'fail'" class="text-error">
                    {{ record.region_names[index] }}(刊登失败)
                  </span>
                  <span v-else class="text-primary">
                    {{ record.region_names[index] }}(刊登中)
                  </span>
                </div>
              </template>
            </template>
            <p>{{ record[record.regions[0]].item_name }}</p>
          </template>
          <template v-if="column.dataIndex === 'parent_sku'">
            {{ record.item_sku }}
          </template>
          <template v-if="column.dataIndex === 'item_sku'">
            <template v-if="record.sale_type === '2'">
              <p v-for="(variation, vindex) in record.variations" :key="variation._id">
                <template v-if="record.variations.length > variation_size">
                  <span v-if="vindex < variation_size">{{ variation.item_sku }}</span>
                  <a v-else-if="vindex === variation_size" class="ant-dropdown-link"
                    @click="() => { variation_size = 100 }">
                    更多
                    <DownOutlined />
                  </a>
                </template>
                <span v-else>{{ variation.item_sku }}</span>
              </p>
              <a v-if="variation_size === 100" class="ant-dropdown-link" @click="() => { variation_size = 2 }">
                收起
                <UpOutlined />
              </a>
            </template>
          </template>
          <template v-if="column.dataIndex === 'standard_price'">
            <template v-if="record.sale_type === '2'">
              <p v-for="(variation, vindex) in record.variations" :key="variation._id">
                <template v-if="record.variations.length > variation_size">
                  <span v-if="vindex < variation_size">{{ variation[record.regions?.[0]]?.standard_price }}</span>
                  <a v-else-if="vindex === variation_size" class="ant-dropdown-link"
                    @click="() => { variation_size = 100 }">
                    更多
                    <DownOutlined />
                  </a>
                </template>
                <span v-else>{{ variation[record.regions?.[0]]?.standard_price }}</span>
              </p>
              <a v-if="variation_size === 100" class="ant-dropdown-link" @click="() => { variation_size = 2 }">
                收起
                <UpOutlined />
              </a>
            </template>
            <template v-else> {{ record.standard_price }} </template>
          </template>
          <template v-if="column.dataIndex === 'quantity'">
            <template v-if="record.sale_type === '2'">
              <p v-for="(variation, vindex) in record.variations" :key="variation._id">
                <template v-if="record.variations.length > variation_size">
                  <span v-if="vindex < variation_size">{{ variation.quantity }}</span>
                  <a v-else-if="vindex === variation_size" class="ant-dropdown-link"
                    @click="() => { variation_size = 100 }">
                    更多
                    <DownOutlined />
                  </a>
                </template>
                <span v-else>{{ variation.quantity }}</span>
              </p>
              <a v-if="variation_size === 100" class="ant-dropdown-link" @click="() => { variation_size = 2 }">
                收起
                <UpOutlined />
              </a>
            </template>
            <template v-else> {{ record.quantity }} </template>
          </template>
          <template v-if="column.dataIndex === 'state'">
            <a-tag v-if="record.state === 'draft'" color="gray">草稿</a-tag>
            <a-tag v-if="record.state === 'schedule' || record.state === 'publish'" color="processing">刊登中</a-tag>
            <a-tag v-if="record.state === 'success'" color="success">刊登成功</a-tag>
            <a-tag v-if="record.state === 'fail' || record.states?.indexOf('fail') > -1" color="error"
              class="underline click" @click="showError(record._id)">刊登失败</a-tag>
          </template>
          <template v-if="column.dataIndex === 'moreinfo'">
            <div class="d-flex flex-column">
              <p>
                <span class="text-gray">创建：</span>{{ record.create_by }}
              </p>
              <p>{{ record.create_time }}</p>
              <p>
                <span class="text-gray">更新：</span>{{ record.update_by }}
              </p>
              <p>{{ record.update_time }}</p>
            </div>
          </template>
          <template v-if="column.dataIndex === 'operation'">
            <div class="d-flex flex-column">
              <a-dropdown v-if="queryForm.state === 0 || queryForm.state === 4">
                <a class="ant-dropdown-link" @click.prevent>
                  刊登
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu @click="({ key }) => handleMenuClick(key, record._id)">
                    <a-menu-item key="publish">
                      <a href="javascript:;" class="underline text-info">立即刊登</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a v-if="queryForm.state === 0 || queryForm.state === 4" href="javascript:;"
                @click="editListing(record._id)"> 编辑 </a>
              <a-popconfirm v-if="queryForm.state === 0 || queryForm.state === 4" title="删除？"
                @confirm="removeListing(record._id)">
                <a href="javascript:;" class="text-error">删除</a>
              </a-popconfirm>
              <a v-if="queryForm.state === 0 || queryForm.state === 3" href="javascript:;" @click="copyListing(record)">
                复制 </a>
              <a v-if="queryForm.state === 3" href="javascript:;" @click="editListing(record._id)"> 查看 </a>
              <template v-if="envOS === 'darwin'">
                <!-- <a v-for="(region, index) in record.regions" :key="region" href="javascript:;"
                  @click="publishListing(record._id, region)"> 刊登[{{ record.region_names[index] }}] </a> -->
                <a v-for="(region, index) in record.regions" :key="region" href="javascript:;"
                  @click="pullListing(record._id, region)"> 拉取[{{ record.region_names[index] }}] </a>
              </template>
            </div>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
  </a-tabs>
  <a-drawer title="刊登失败原因" placement="right" v-model:visible="errorDrawer" width="30%">
    <a-tabs>
      <a-tab-pane v-for="(region, index) in regions" :key="region" :tab="region_names[index]">
        <div class="text-error" v-html="error_messages[region]"></div>
        <a-list size="small" bordered :data-source="errors[region]"
          :pagination="{ pageSize: 20, hideOnSinglePage: true, showSizeChanger: false, }">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-alert :message="item.title" :type="item.type" style="width:100%;">
                <template #description>
                  <p v-for="mitem in item.messages" :key="mitem.key">
                    code:{{ mitem.code }},message:{{ mitem.message }}
                  </p>
                </template>
              </a-alert>
            </a-list-item>
          </template>
        </a-list>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
  <a-modal v-model:visible="shopaccountModal" title="请选择店铺" :maskClosable="false" :closable="!copying" width="500px"
    @cancel="() => shopaccountModal = false">
    <a-table :columns="shopaccountcolumns" :data-source="shopaccounts" :loading="copying"
      :scroll="{ x: swidth, y: 600 }" :pagination="false" row-key="value"
      :row-selection="{ selectedRowKeys: selectedShops, onChange: (keys) => selectedShops = keys }" size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'label'">
          {{ record.label }}
          <p class="text-gray">
            <template v-for="(item, index) in record.region_names" :key="index">
              <span :class="{ 'text-error font-weight-bold': copyListingForm.region_names.indexOf(item) > -1 }">
                {{ item }}
              </span>
              <span v-if="record.region_names.length > 1 && index < record.region_names.length - 1">、</span>
            </template>
          </p>
        </template>
      </template>
    </a-table>
    <template #footer>
      <div class="d-flex justify-space-between">
        <div class="btn-group">
          <button class="click btn btn-sm btn-teal" @click="selectall">全选</button>
          <button class="click btn btn-sm btn-default" @click="selectnone">全不选</button>
        </div>
        <span class="text-error">仅复制到粗体标红站点</span>
        <a-space>
          <a-button type="default" @click="() => shopaccountModal = false" :disabled="copying">取消</a-button>
          <a-button type="primary" @click="confirmCopyListing" :disabled="selectedShops.length === 0"
            :loading="copying">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>
<script>
import { DownOutlined, UpOutlined, SearchOutlined, PlusCircleOutlined, AmazonOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
const columns = [
  { title: '缩略图', dataIndex: 'main_image_url', width: 100, },
  { title: '商品标题', dataIndex: 'item_name', width: 500, },
  { title: '主SKU', dataIndex: 'parent_sku', width: 150, },
  { title: '子SKU', dataIndex: 'item_sku', width: 300, },
  { title: '价格', dataIndex: 'standard_price', width: 100, },
  { title: '数量', dataIndex: 'quantity', width: 100, },
  { title: '刊登状态', dataIndex: 'state', width: 100, },
  { title: '更多信息', dataIndex: 'moreinfo', width: 160, },
  { title: '操作', dataIndex: 'operation', width: 120, align: 'center', fixed: 'right' },
];
const shopaccountcolumns = [
  { title: '账号', dataIndex: 'label', width: 400 },
];
export default defineComponent({
  name: 'AmazonListingPublishRegion',
  components: { DownOutlined, UpOutlined, SearchOutlined, PlusCircleOutlined, AmazonOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const envOS = ref(process.env.OS);
    const state = reactive({
      activeTab: "0",
      queryForm: {
        state: 0,
        account_username: null,
        searchtext: '',
      },
      shopaccounts: [],
      shopaccountModal: false,
      selectedShops: [],
      copying: false,
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      statistics: [],
      regions: [],
      region_names: [],
      error_messages: [],
      errors: [],
      warnings: [],
      errorDrawer: false,
      copyListingForm: {
        listingform_id: "",
        regions: [],
        region_names: [],
      },
      variation_size: 2,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const swidth = computed(() => proxy.$utils.twidth(shopaccountcolumns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1; else getData();
    });
    onActivated(() => {
      getData();
    })
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/list", { ...state.queryForm, page: state.page, limit: state.limit, platform: 'amz' }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.statistics = res.data.statistics;
          state.shopaccounts = res.data.shopaccounts;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addListing = () => {
      proxy.$utils.downloadBrowser({ href: `/shops/published/amazonpublishformregion?id=${proxy.$utils.uuid()}` });
    }
    const editListing = (id) => {
      proxy.$utils.downloadBrowser({ href: `/shops/published/amazonpublishformregion?id=${id}` });
    }
    const removeListing = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/remove", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const copyListing = (item) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/shopaccounts").then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.copyListingForm = {
            listingform_id: item._id,
            regions: item.regions,
            region_names: item.region_names,
          };
          state.shopaccounts = res.data.shopaccounts;
          state.shopaccountModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const confirmCopyListing = () => {
      if (state.selectedShops.length === 0) return message.error("请选择账号");
      state.copying = true;
      proxy.$api.doAPI("/listingform/amazon/copy", { copyListingForm: state.copyListingForm, selectedShops: state.selectedShops }).then((res) => {
        state.copying = false;
        if (res.code === 0) {
          state.shopaccountModal = false;
          // TODO 切换到草稿
          if (state.queryForm.state !== 0) state.queryForm.state = 0;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.copying = false;
        console.error(err);
      });
    }
    const selectall = () => {
      state.selectedShops = _.pluck(state.shopaccounts, "value");
    }
    const selectnone = () => {
      state.selectedShops = [];
    }
    const handleMenuClick = (key, id) => {
      console.log('handleMenuClick', key, id);
      switch (key) {
        case "publish": //立即刊登
          // message.success("立即刊登");
          publish(id);
          break;
      }
    }
    const publish = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/publish", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const publishListing = (_id, region) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/publishlisting", { _id, region }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const pullListing = (_id, region) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/pulllisting", { _id, region }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const showError = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingform/amazon/errors", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.regions = res.data.regions;
          state.region_names = res.data.region_names;
          state.error_messages = res.data.error_messages;
          state.errors = res.data.errors;
          state.warnings = res.data.warnings;
          state.errorDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const handleExportClick = ({ key }) => {
      console.log('handleExportClick', key);
      switch (key) {
        case "uploadtemplate": //上传商品
          break;
        case "uploadrecord": //上传记录
          break;
        case "bulkedit": //批量编辑
          break;
        case "bulkeditprice": //批量改价格
          break;
        case "bulkeditquantity": //批量改数量
          break;
        case "bulkeditremark": //批量备注
          break;
        case "bulkdelete": //批量删除
          break;
      }
    }
    const getstatisticscount = (type) => {
      return _.findWhere(state.statistics, { _id: type })?.count || 0;
    }
    return {
      ...toRefs(state),
      envOS,
      columns,
      shopaccountcolumns,
      width,
      swidth,
      getData,
      handleMenuClick,
      addListing,
      editListing,
      removeListing,
      copyListing,
      confirmCopyListing,
      selectall,
      selectnone,
      publishListing,
      pullListing,
      showError,
      handleExportClick,
      getstatisticscount,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-teal {
  color: #fff;
  background-color: #6bccb4;
  border-color: #60c8ae;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .1);
}

.btn-default {
  color: #6a6a6a;
  background-color: #f5f5f5;
  border-color: #cfd9db;
  text-shadow: 0 1px 0 rgba(255, 255, 255, .8);
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group .btn+.btn {
  margin-left: -1px;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>