<template>
  <a-spin :spinning="loading">
    <div>
      <div class="mainform">
        <a-form ref="listingformRef" name="listingForm" :model="listingForm" autocomplete="off">
          <a-card size="small" title="基本信息">
            <div class="form-item">
              <div class="form-label">
                <span class="cn">对标产品</span>
              </div>
              <a-form-item style="width:100%;">
                <a-input v-model:value="listingForm.url" placeholder="用于记录供货渠道URL，方便采购。" readonly
                  style="width:900px;" />
                <a-button>查看在线商品</a-button>
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="form-label ">
                <span class="cn">主SKU编号</span>
              </div>
              <a-form-item style="width:80%;max-width:400px;">
                <a-input v-model:value="listingForm.item_sku" />
              </a-form-item>
            </div>
            <div class="form-item">
              <div class="form-label ">
                <span class="cn">产品标题</span>
              </div>
              <div class="d-flex flex-column">
                <a-tabs v-model:activeKey="curr_lang">
                  <a-tab-pane v-for="item in languages" :key="item.value" :tab="item.label">
                  </a-tab-pane>
                </a-tabs>
                <a-form-item style="width:900px;">
                  <a-input v-if="curr_lang" v-model:value="listingForm[curr_lang].item_name"
                    :placeholder="`请输入${curr_lang_label}标题`" show-count :maxlength="200" />
                </a-form-item>
              </div>
            </div>
            <div class="form-item">
              <div class="form-label ">
                <span class="cn">品牌</span>
              </div>
              <a-form-item style="width:80%;max-width:400px;">
                <a-input v-model:value="listingForm.brand_name" />
              </a-form-item>
            </div>
          </a-card>
          <a-card size="small" title="产品图片">
            <a-tabs v-model:activeKey="image_type" @change="changeImageType">
              <a-tab-pane key="images">
                <template #tab>
                  橱窗图
                  <a-badge :count="images_count"
                    :number-style="{ backgroundColor: image_type === 'images' ? '#ff4d4f' : '#999' }" />
                </template>
              </a-tab-pane>
              <a-tab-pane key="variation_images" v-if="listingForm.variation_list?.length > 0">
                <template #tab>
                  多属性图
                  <a-badge :count="variation_images_count"
                    :number-style="{ backgroundColor: image_type === 'variation_images' ? '#ff4d4f' : '#999' }" />
                </template>
              </a-tab-pane>
            </a-tabs>
            <div class="d-flex justify-space-between align-center mb-4">
              <a-space>
                <span class="text-h8 font-weight-bold">添加图片：</span>
                <ImagePicker0 v-if="image_type === 'images'" :direction="'horizontal'" :images="listingForm.images"
                  @local-upload-success="(data) => onLocalUploadSuccess({ data })"
                  @network-input-success="(data) => onNetworkInputSuccess({ data })"
                  @space-pick-success="(data) => onSpacePickSuccess({ data })" />
                <ImagePicker1 v-if="image_type === 'variation_images'" :direction="'horizontal'"
                  :images="listingForm.variation_images"
                  @local-upload-success="(data) => onLocalUploadSuccess({ data })"
                  @network-input-success="(data) => onNetworkInputSuccess({ data })"
                  @space-pick-success="(data) => onSpacePickSuccess({ data })" />
              </a-space>
              <a-space>
                <a-checkbox v-model:checked="check_all_images" :indeterminate="indeterminate_all_images"
                  @change="toggleCheckAllImages">全选</a-checkbox>
                <a-button @click="downloadImages">下载</a-button>
                <a-button @click="copyImagesUrl">复制链接</a-button>
                <a-button @click="removeImagesUrl">批量删除</a-button>
              </a-space>
            </div>
            <a-row :gutter="8">
              <template v-if="image_type === 'images'">
                <a-col :span="2" v-for="(image, index) in listingForm.images" :key="index">
                  <div style="width:100%;border:1px solid #e8e8e8;">
                    <div class="image-header" style="border-bottom:1px solid #e8e8e8;">
                      <img :src="image" style="object-fit: contain;" @load="(e) => onImageLoad(e, index)" />
                    </div>
                    <div style="height:30px;padding:5px;" class="d-flex justify-space-between align-center">
                      <a-checkbox v-model:checked="checked_images[`${index}`]"></a-checkbox>
                      <a-divider type="vertical" />
                      <a-tooltip>
                        <template #title>删除图片</template>
                        <a-popconfirm title="确定要删除?" @confirm="removeImage(index)">
                          <DeleteOutlined style="font-size:20px;color:#ff4d4f;" />
                        </a-popconfirm>
                      </a-tooltip>
                    </div>
                  </div>
                </a-col>
              </template>
              <template v-if="image_type === 'variation_images'">
                <a-col :span="2" v-for="(image, index) in listingForm.variation_images" :key="index">
                  <div style="width:100%;border:1px solid #e8e8e8;margin-bottom:5px;">
                    <div class="image-header">
                      <img :src="image" style="object-fit: contain;" @load="(e) => onVariationImageLoad(e, index)" />
                    </div>
                    <div style="height:30px;padding:5px;" class="d-flex justify-space-between align-center">
                      <a-checkbox v-model:checked="checked_variation_images[index]"></a-checkbox>
                      <a-divider type="vertical" />
                      <a-tooltip>
                        <template #title>删除图片</template>
                        <a-popconfirm title="确定要删除?" @confirm="removeVariationImage(index)">
                          <DeleteOutlined style="font-size:20px;color:#ff4d4f;" />
                        </a-popconfirm>
                      </a-tooltip>
                    </div>
                  </div>
                </a-col>
              </template>
            </a-row>
          </a-card>
          <a-card size="small" title="多属性" v-if="listingForm.variation_list?.length > 0">
            <a-tabs>
              <a-tab-pane>
                <template #tab>
                  产品多属性信息
                  <a-badge :count="listingForm.variation_list.length" />
                </template>
              </a-tab-pane>
            </a-tabs>
            <a-checkbox v-model:checked="listingForm.enable_variation">启用多属性</a-checkbox>
            <a-table v-if="listingForm.enable_variation" bordered :columns="variation_headers"
              :data-source="listingForm.variation_list" :scroll="{ x: width }"
              :pagination="{ current: page, pageSize: limit, total: listingForm.variation_list.length, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
              @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
              :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
              <template #headerCell="{ column }">
                <template v-if="column.dataIndex === 'item_sku'">
                  <div class="d-flex flex-column justify-center align-center">
                    <span>sku编号</span>
                    <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                      @click="oneClickGenerate('item_sku')">一键生成</a>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'item_name_zh'">
                  <div class="d-flex flex-column justify-center align-center">
                    <span>中文标题</span>
                    <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                      @click="oneClickGenerate('item_name_zh')">一键生成</a>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'item_name_en'">
                  <div class="d-flex flex-column justify-center align-center">
                    <span>英文标题</span>
                    <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                      @click="oneClickGenerate('item_name_en')">一键生成</a>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'external_product_id'">
                  <div class="d-flex justify-center align-center">
                    <a-select v-model:value="listingForm.variation_list[0].external_product_id_type"
                      @change="changeVariationListProductIDType">
                      <a-select-option v-for="item in external_product_id_types" :key="item" :value="item">
                        {{ item }}
                      </a-select-option>
                    </a-select>
                    <!-- <div class="d-flex flex-column ml-2">
                      <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                        @click="getExternalProductID">批量修改</a>
                    </div> -->
                  </div>
                </template>
                <template v-if="column.dataIndex === 'standard_price'">
                  <div class="d-flex flex-column justify-center align-center">
                    <span>价格</span>
                    <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                      @click="editVariationList('standard_price')">批量修改</a>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'quantity'">
                  <div class="d-flex flex-column justify-center align-center">
                    <span>数量</span>
                    <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                      @click="editVariationList('quantity')">批量修改</a>
                  </div>
                </template>
              </template>
              <template #bodyCell="{ record, index, column }">
                <template v-if="column.dataIndex === 'index'">
                  <div class="d-flex flex-column align-center">
                    <a-button style="width:20px;height:20px;padding:0;" :disabled="index === 0"
                      @click="moveUp({ index })">
                      <template #icon>
                        <UpOutlined />
                      </template>
                    </a-button>
                    {{ index + 1 }}
                    <a-button style="width:20px;height:20px;padding:0;"
                      :disabled="index === listingForm.variation_list.length - 1" @click="moveDown({ index })">
                      <template #icon>
                        <DownOutlined />
                      </template>
                    </a-button>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'main_image'">
                  <div v-if="record.images[0]" class="d-flex align-center">
                    <div style="width:50px;height:50px;border:1px solid #eee;">
                      <div class="image-header">
                        <img :src="record.images[0]" style="object-fit: contain;" />
                      </div>
                    </div>
                    <div class="d-flex flex-column ml-1 align-center">
                      <a href="javascript:;" class="text-info underline" @click="editMainImage(index)"> 更换首图 </a>
                      <a href="javascript:;" class="text-error underline" @click="clearVariationImages(record)"> 清除图片
                      </a>
                    </div>
                  </div>
                  <img v-else :src="image_placeholder" width="50" />
                </template>
                <template v-if="column.dataIndex === 'color_name'">
                  <a-input v-model:value="record.color_name" style="width:100%" />
                </template>
                <template v-if="column.dataIndex === 'size_name'">
                  <a-input v-model:value="record.size_name" style="width:100%" />
                </template>
                <template v-if="column.dataIndex === 'item_sku'">
                  <a-input v-model:value="record.item_sku" style="width:100%" />
                </template>
                <template v-if="column.dataIndex === 'item_name_zh'">
                  <div class="d-flex align-center">
                    <a-input v-model:value="record.item_name_zh" :maxlength="256" style="width:100%" />
                    <p class="text-gray text-right ml-1" style="min-width:40px;">
                      {{ record.item_name_zh?.length || 0 }}/256
                    </p>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'item_name_en'">
                  <div class="d-flex align-center">
                    <a-input v-model:value="record.item_name_en" :maxlength="256" style="width:100%" />
                    <p class="text-gray text-right ml-1" style="min-width:40px;">
                      {{ record.item_name_en?.length || 0 }}/256
                    </p>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'external_product_id'">
                  <a-input v-model:value="record.external_product_id" style="width:100%" />
                </template>
                <template v-if="column.dataIndex === 'standard_price'">
                  <a-input v-model:value="record.standard_price" type="number" style="width:100%" />
                </template>
                <template v-if="column.dataIndex === 'quantity'">
                  <a-input v-model:value="record.quantity" type="number" style="width:100%" />
                </template>
                <template v-if="column.dataIndex === 'operation'">
                  <a-space direction="vertical">
                    <a href="javascript:;" class="mb-2" @click="editVariationImages(index, record)">
                      编辑图片({{ record.images.length }})
                    </a>
                    <a-popconfirm title="确定要删除?" @confirm="removeVariationItem(index)">
                      <a href="javascript:;" class="text-error">
                        <DeleteOutlined /> 删除
                      </a>
                    </a-popconfirm>
                  </a-space>
                </template>
              </template>
            </a-table>
          </a-card>
          <a-card size="small" title="产品描述">
            <div class="form-item">
              <div class="form-label ">
                <span class="cn">searchTerms</span>
              </div>
              <div class="d-flex flex-column" style="width:80%;min-width:600px;">
                <p class="text-info">
                  amazon专有，请填写一行，不要按回车键手动换行，并且不能超过250个字符
                </p>
                <a-tabs v-model:activeKey="curr_lang">
                  <a-tab-pane v-for="item in languages" :key="item.value" :tab="item.label">
                  </a-tab-pane>
                </a-tabs>
                <a-form-item>
                  <a-textarea v-if="curr_lang" v-model:value="listingForm[curr_lang].generic_keywords"
                    :auto-size="{ minRows: 3, maxRows: 3 }" />
                </a-form-item>
              </div>
            </div>
            <div class="form-item">
              <div class="form-label ">
                <span class="cn">Bullet Point</span>
              </div>
              <div class="d-flex flex-column" style="width:80%;min-width:600px;">
                <p class="text-info">
                  amazon专有，每行一条Bullet Point，共5行，每行最多500个字符
                </p>
                <a-tabs v-model:activeKey="curr_lang">
                  <a-tab-pane v-for="item in languages" :key="item.value" :tab="item.label">
                  </a-tab-pane>
                </a-tabs>
                <a-form-item>
                  <a-textarea v-if="curr_lang" v-model:value="listingForm[curr_lang].bullet_points"
                    :auto-size="{ minRows: 10, maxRows: 10 }" />
                </a-form-item>
              </div>
            </div>
            <div class="form-item">
              <div class="form-label ">
                <span class="cn">物品状况描述</span>
              </div>
              <div class="d-flex flex-column" style="width:80%;min-width:600px;">
                <p class="text-info">
                  amazon专有，最多2000个字符
                </p>
                <a-tabs v-model:activeKey="curr_lang">
                  <a-tab-pane v-for="item in languages" :key="item.value" :tab="item.label">
                  </a-tab-pane>
                </a-tabs>
                <a-form-item>
                  <a-textarea v-if="curr_lang" v-model:value="listingForm[curr_lang].condition_note"
                    :auto-size="{ minRows: 5, maxRows: 5 }" />
                </a-form-item>
              </div>
            </div>
            <div class="form-item">
              <div class="form-label ">
                <span class="cn ">详细描述</span>
              </div>
              <div class="d-flex flex-column" style="width:80%;min-width:600px;">
                <p class="text-info">
                  详细描述用于描述用于wish, amazon, shopee, joom, tophatter
                </p>
                <a-tabs v-model:activeKey="curr_lang">
                  <a-tab-pane v-for="item in languages" :key="item.value" :tab="item.label">
                  </a-tab-pane>
                </a-tabs>
                <div v-if="product_description_edit_type === 'editor'">
                  <div style="border: 1px solid #ccc">
                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
                      :mode="editorMode" />
                    <Editor style="height: 500px; overflow-y: hidden;" v-model="product_description"
                      :defaultConfig="editorConfig" :mode="editorMode" @onCreated="handleCreated(editor)" />
                  </div>
                </div>
                <div v-if="product_description_edit_type === 'textarea'" style="border: 1px solid #ccc">
                  <a-textarea v-model:value="product_description2" :auto-size="{ minRows: 15, maxRows: 15 }"
                    style="width:100%;" />
                </div>
                <a href="javascript:;" class="underline text-h10" v-if="product_description_edit_type === 'editor'"
                  @click="() => {
                    product_description_edit_type = 'textarea';
                    product_description2 = product_description;
                  }">
                  使用纯文本编辑器
                </a>
                <a href="javascript:;" class="underline text-h10" v-if="product_description_edit_type === 'textarea'"
                  @click="() => {
                    product_description_edit_type = 'editor';
                    product_description = product_description2;
                  }">
                  使用富文本编辑器
                </a>
              </div>
            </div>
          </a-card>
        </a-form>
        <div class="footer pr-4">
          <a-button class="btn-warning" @click="doSave" :loading="saving">
            <span>保 存</span>
          </a-button>
        </div>
      </div>
    </div>
  </a-spin>
  <a-modal v-model:visible="variationListQuantityModal" title="批量修改数量"
    @cancel="() => variationListQuantityModal = false" width="400px">
    <div class="d-flex justify-center align-center">
      <a-form-item label="数量" name="variationListQuantity">
        <a-input v-model:value="variationListQuantity" type="number" />
      </a-form-item>
    </div>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationListQuantityModal = false">关闭</a-button>
          <a-button class="btn-primary" @click="confirmVariationListQuantity">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="variationListPriceModal" title="批量修改价格" @cancel="() => variationListPriceModal = false"
    width="400px">
    <div class="d-flex justify-center align-center">
      <a-form-item label="价格" name="variationListPrice">
        <a-input v-model:value="variationListPrice" type="number" />
      </a-form-item>
    </div>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationListPriceModal = false">关闭</a-button>
          <a-button class="btn-primary" @click="confirmVariationListPrice">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="pickImageModal" :title="pickImageType === 'variation_images' ? '编辑多属性图片' : '更换多属性首图'"
    @cancel="() => pickImageModal = false" :width="pickImageType === 'variation_images' ? '1100px' : '750px'">
    <a-alert v-if="pickImageType === 'variation_images'" type="info" show-icon>
      <template #icon>
        <InfoCircleOutlined />
      </template>
      <template #message>
        <span>
          请将左侧的可选图片，添加复制到右侧的多属性图片，多属性图片最多 <span class="text-info">12</span> 张
        </span>
      </template>
    </a-alert>
    <a-row v-if="pickImageType === 'variation_images'" :gutter="8">
      <a-col :span="18">
        <a-card size="small" style="box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
          <template #title>
            <div class="text-center">
              <span class="text-info">可选图片</span>
              <span class="text-grey">(点击‘添加’，复制到右侧的多属性图片中)</span>
            </div>
          </template>
          <a-tabs v-if="listingForm.images.length > 0 || listingForm.variation_images.length > 0"
            v-model:activeKey="pickImageTab">
            <a-tab-pane v-if="listingForm.images.length > 0" key="images" :tab="`橱窗图(${listingForm.images.length})`" />
            <a-tab-pane v-if="listingForm.variation_images.length > 0" key="variation_images"
              :tab="`多属性图(${listingForm.variation_images.length})`" />
          </a-tabs>
          <div style="height:350px;overflow-y:auto;overflow-x:hidden;">
            <a-row :gutter="8">
              <a-col :span="4" v-for="(image, index) in pick_images" :key="index">
                <div class="d-flex flex-column align-center mb-2"
                  style="border:1px solid #ddd;box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
                  <div class="image-header" style="border-bottom:1px solid #ddd;">
                    <img :src="image" style="object-fit: contain;" />
                  </div>
                  <a-button class="btn-success" @click="addPickImage(image)">
                    添加
                    <ArrowRightOutlined />
                  </a-button>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card size="small" style="box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
          <template #title>
            <div class="text-center">
              <span class="text-info">多属性图片</span>
              <span class="text-grey">(可拖动排序,第一张为首图)</span>
            </div>
          </template>
          <div style="height:400px;overflow-y:auto;overflow-x:hidden;">
            <draggable v-if="pickImages.length > 0" v-model="pickImages" item-key="index" class="d-flex flex-wrap">
              <template #item="{ index, element: image }">
                <div class="image-block mr-1 mb-1">
                  <div :class="{ 'image-header': true, 'image-border': true, 'main-image': index === 0 }">
                    <img :src="image" style="object-fit: contain;" />
                    <span title="移除" class="btn-remove">
                      <CloseOutlined @click="removePickImage(index)" />
                    </span>
                  </div>
                </div>
              </template>
            </draggable>
            <a-empty v-else description="暂无图片，请先从左侧选择图片" :image="emptyImage">
            </a-empty>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <div v-if="pickImageType === 'main_image'">
      <a-tabs v-if="listingForm.images.length > 0 || listingForm.variation_images.length > 0"
        v-model:activeKey="pickImageTab">
        <a-tab-pane v-if="listingForm.images.length > 0" key="images" :tab="`橱窗图(${listingForm.images.length})`" />
        <a-tab-pane v-if="listingForm.variation_images.length > 0" key="variation_images"
          :tab="`多属性图(${listingForm.variation_images.length})`" />
      </a-tabs>
      <div style="height:350px;overflow-y:auto;overflow-x:hidden;">
        <a-row :gutter="8">
          <a-col :span="4" v-for="(image, index) in pick_images" :key="index">
            <div class="d-flex flex-column align-center mb-2"
              style="border:1px solid #ddd;box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
              <div class="image-header" style="border-bottom:1px solid #ddd;">
                <img :src="image" style="object-fit: contain;" />
              </div>
              <a-button class="btn-info" @click="doPickImage(image)">
                选择
              </a-button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => pickImageModal = false">关闭</a-button>
          <a-button v-if="pickImageType === 'variation_images'" class="btn-info"
            @click="confirmPickImages">保存</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="downloadModal" title="图片下载" :maskClosable="false" :closable="downloadState === 'process'"
    @cancel="() => downloadModal = false" :footer="null">
    <a-card size="small" style="width:100%">
      <template #title>
        <span :class="{ 'text-success': downloadState === 'success', 'text-error': downloadState === 'fail' }">
          {{ downloadState === 'process' ? '图片正在打包中，请稍后...' : '' }}
          {{ downloadState === 'success' ? '图片下载成功！' : '' }}
          {{ downloadState === 'fail' ? '图片下载失败！' : '' }}
        </span>
      </template>
      <div class="d-flex justify-center align-center">
        <div v-if="downloadState === 'process'">
          <div class="loader-bar"></div>
          <div class="mt-2 text-center">下载中请勿关闭提示窗口</div>
        </div>
        <div v-if="downloadState === 'success'">
          <a-button @click="() => downloadModal = false">关闭</a-button>
        </div>
        <div v-if="downloadState === 'fail'">图片下载失败，请稍后重试</div>
      </div>
    </a-card>
  </a-modal>
</template>
<script>
import { InfoCircleFilled, UpOutlined, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, SaveOutlined, RollbackOutlined, InfoCircleOutlined, ArrowRightOutlined, CloseOutlined, } from '@ant-design/icons-vue';
import { message, Modal, Empty } from "ant-design-vue";
import { defineComponent, ref, shallowRef, reactive, toRefs, watch, computed, onMounted, onBeforeUnmount, onActivated, getCurrentInstance, toRaw, } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import ImagePicker0 from '@/components/ImagePicker.vue';
import ImagePicker1 from '@/components/ImagePicker.vue';
import draggable from 'vuedraggable';
export default defineComponent({
  name: 'GatherForm',
  components: {
    InfoCircleFilled, UpOutlined, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, SaveOutlined, RollbackOutlined, InfoCircleOutlined, ArrowRightOutlined, CloseOutlined,
    Editor, Toolbar, ImagePicker0, ImagePicker1, draggable,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const listingformRef = ref();
    const editorRef = shallowRef();
    const toolbarConfig = {
      toolbarKeys: ["bold", "italic", "bulletedList", "numberedList", "undo", "redo", "fullScreen"]
    };
    const editorConfig = {

    };
    const state = reactive({
      curr_lang: '',
      languages: [
        { value: 'en', label: '英语' },
        { value: 'zh', label: '中文' },
      ],
      image_type: "images",
      check_all_images: false,
      indeterminate_all_images: false,
      checked_images: {},
      checked_variation_images: {},
      downloadModal: false,
      downloadState: "",
      product_description: "",
      product_description2: "",
      product_description_edit_type: "editor",
      external_product_id_types: ["UPC", "EAN", "ISBN", "GTIN", "GCID", "PZN"],
      variationListQuantity: "",
      variationListQuantityModal: false,
      variationListPrice: {},
      variationListPriceModal: false,
      pickImageTab: "variation_images",
      pickImageType: "",
      pickImageIndex: -1,
      pickImages: [],
      pickImageModal: false,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      page: 1,
      limit: 10,
      loading: false,
      saving: false,
      listingForm: {
        en: {},
        zh: {},
        url: "", item_sku: "", brand_name: "", images: [], variation_images: [], variation_list: [],
        enable_variation: false,
      },
    });
    const variation_headers = computed(() => {
      if (state.listingForm.variation_list?.length > 0) {
        let headers = [
          { title: "序号", dataIndex: "index", width: 80 },
          { title: "属性图", dataIndex: "main_image", width: 150 },
        ];
        if (_.has(state.listingForm.variation_list[0], 'color_name')) headers.push({ title: "color_name", dataIndex: "color_name", width: 150 });
        if (_.has(state.listingForm.variation_list[0], 'size_name')) headers.push({ title: "size_name", dataIndex: "size_name", width: 150 });
        headers.push(
          { title: "sku编号", dataIndex: "item_sku", width: 180 },
          { title: "中文标题", dataIndex: "item_name_zh", width: 300 },
          { title: "英文标题", dataIndex: "item_name_en", width: 300 },
          { title: "", dataIndex: "external_product_id", width: 180 },
          { title: "价格", dataIndex: "standard_price", width: 120 },
          { title: "数量", dataIndex: "quantity", width: 120 },
          { title: "操作", dataIndex: "operation", width: 150, fixed: "right" },
        );
        return headers;
      }
      return [];
    });
    const width = computed(() => proxy.$utils.twidth(variation_headers.value));
    const curr_lang_label = computed(() => {
      return _.findWhere(state.languages, { value: state.curr_lang })?.label || '';
    });
    const images_count = computed(() => {
      return state.listingForm.images?.length || 0;
    });
    const variation_images_count = computed(() => {
      return state.listingForm.variation_images?.length || 0;
    });
    const checked_images_list = computed(() => {
      let keys = _.keys(state.checked_images);
      let list = [];
      for (let i = 0; i < keys.length; i++) {
        let index = keys[i];
        if (state.checked_images[index]) {
          list.push(state.listingForm.images[index]);
        }
      }
      return list;
    });
    const checked_variation_images_list = computed(() => {
      let keys = _.keys(state.checked_variation_images);
      let list = [];
      for (let i = 0; i < keys.length; i++) {
        let index = keys[i];
        if (state.checked_variation_images[index]) {
          list.push(state.listingForm.variation_images[index]);
        }
      }
      return list;
    });
    const pick_images = computed(() => {
      if (state.pickImageTab === "images") return state.listingForm.images;
      if (state.pickImageTab === "variation_images") return state.listingForm.variation_images;
    });
    watch(() => state.curr_lang, (lang) => {
      if (state.product_description_edit_type === 'editor') {
        state.product_description = state.listingForm[lang]?.product_description || '';
      }
      if (state.product_description_edit_type === 'textarea') {
        state.product_description2 = state.listingForm[lang]?.product_description || '';
      }
    });
    watch(() => state.product_description, (product_description) => {
      if (state.listingForm[state.curr_lang]) {
        state.listingForm[state.curr_lang].product_description = product_description;
      }
    });
    watch(() => state.product_description2, (product_description) => {
      if (state.listingForm[state.curr_lang]) {
        state.listingForm[state.curr_lang].product_description = product_description;
      }
    });
    watch(() => state.checked_images, (images) => {
      let check_all_images = state.listingForm.images.length > 0, indeterminate_all_images = false;
      for (let i = 0; i < state.listingForm.images.length; i++) {
        if (images[i]) {
          indeterminate_all_images = true;
        } else {
          check_all_images = false;
        }
      }
      console.log([check_all_images, indeterminate_all_images]);
      state.check_all_images = check_all_images;
      state.indeterminate_all_images = indeterminate_all_images && !check_all_images;
    }, { deep: true });
    watch(() => state.checked_variation_images, (variation_images) => {
      let check_all_images = state.listingForm.variation_images.length > 0, indeterminate_all_images = false;
      for (let i = 0; i < state.listingForm.variation_images.length; i++) {
        if (variation_images[i]) {
          indeterminate_all_images = true;
        } else {
          check_all_images = false;
        }
      }
      console.log([check_all_images, indeterminate_all_images]);
      state.check_all_images = check_all_images;
      state.indeterminate_all_images = indeterminate_all_images && !check_all_images;
    }, { deep: true });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      console.log('getData');
      state.loading = true;
      state.loading = true;
      proxy.$api.doAPI("/listingcollect/info", { _id: route.query.id }).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.languages = res.data.languages;
          state.listingForm = res.data.listingForm;
          state.curr_lang = "en";
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const changeImageType = () => {
      state.check_all_images = false;
      state.indeterminate_all_images = false;
      uncheckAllImages();
    }
    const removeImage = (index) => {
      state.listingForm.images.splice(index, 1);
    }
    const removeVariationImage = (index) => {
      state.listingForm.variation_images.splice(index, 1);
    }
    const onImageLoad = (e, index) => {
      console.log('onImageLoad', e, index);
    }
    const onVariationImageLoad = (e, index) => {
      console.log('onVariationImageLoad', e, index);
    }
    const downloadImages = () => {
      console.log('downloadImages');
      let images = [], variation_list = [];
      if (state.image_type === 'images') {
        images = checked_images_list.value;
      } else if (state.image_type === 'variation_images') {
        variation_list = [{ images: checked_variation_images_list.value }];
      }
      if (images.length === 0 && variation_list.length === 0) return message.error("请先勾选您要下载的图片");
      state.downloadModal = true;
      state.downloadState = "process";
      proxy.$utils.downloadImages([
        { platform: state.listingForm.platform, images, variation_list }
      ]).then(() => {
        state.downloadState = "success";
      }).catch(e => {
        message.error(e.message);
        state.downloadState = "fail";
      });
    }
    const copyImagesUrl = () => {
      let urls = [];
      if (state.image_type === 'images') {
        urls = checked_images_list.value;
      } else if (state.image_type === 'variation_images') {
        urls = checked_variation_images_list.value;
      }
      if (urls.length === 0) return message.error("请先勾选您要操作的图片");
      proxy.$utils.doCopy(urls.join('\n'));
    }
    const removeImagesUrl = () => {
      let images = [];
      if (state.image_type === 'images') {
        images = checked_images_list.value;
      } else if (state.image_type === 'variation_images') {
        images = checked_variation_images_list.value;
      }
      if (images.length === 0) return message.error("请先勾选您要操作的图片");
      if (state.image_type === 'images') {
        state.listingForm.images = _.without(toRaw(state.listingForm.images), ...images);
      } else if (state.image_type === 'variation_images') {
        state.listingForm.variation_images = _.without(toRaw(state.listingForm.variation_images), ...images);
      }
    }
    const onLocalUploadSuccess = ({ data }) => {
      console.log('onLocalUploadSuccess', data);
      if (state.image_type === 'images') {
        let images = toRaw(state.listingForm.images);
        images.push(data.url);
        state.listingForm.images = _.uniq(images);
      }
      if (state.image_type === 'variation_images') {
        let variation_images = toRaw(state.listingForm.variation_images);
        variation_images.push(data.url);
        state.listingForm.variation_images = _.uniq(variation_images);
      }
    }
    const onNetworkInputSuccess = ({ data, }) => {
      console.log('onNetworkInputSuccess', data,);
      if (state.image_type === 'images') {
        let images = toRaw(state.listingForm.images);
        images.push(...data.images);
        state.listingForm.images = _.uniq(images);
      }
      if (state.image_type === 'variation_images') {
        let variation_images = toRaw(state.listingForm.variation_images);
        variation_images.push(...data.images);
        state.listingForm.variation_images = _.uniq(variation_images);
      }
    }
    const onSpacePickSuccess = ({ data, }) => {
      console.log('onSpacePickSuccess', data,);
      if (state.image_type === 'images') {
        let images = toRaw(state.listingForm.images);
        images.push(...data.images);
        state.listingForm.images = _.uniq(images);
      }
      if (state.image_type === 'variation_images') {
        let variation_images = toRaw(state.listingForm.variation_images);
        variation_images.push(...data.images);
        state.listingForm.variation_images = _.uniq(variation_images);
      }
    }
    const toggleCheckAllImages = (e) => {
      console.log('toggleCheckAllImages', e);
      let checked = e.target.checked;
      // state.check_all_images = checked;
      // state.indeterminate_all_images = false;
      if (checked) checkAllImages();
      else uncheckAllImages();
    }
    const checkAllImages = () => {
      if (state.image_type === "images") {
        let checked_images = {};
        for (let i = 0; i < state.listingForm.images.length; i++) {
          checked_images[i] = true;
        }
        state.checked_images = checked_images;
      } else if (state.image_type === "variation_images") {
        let checked_variation_images = {};
        for (let i = 0; i < state.listingForm.variation_images.length; i++) {
          checked_variation_images[i] = true;
        }
        state.checked_variation_images = checked_variation_images;
      }
    }
    const uncheckAllImages = () => {
      if (state.image_type === "images") {
        state.checked_images = {};
      } else if (state.image_type === "variation_images") {
        state.checked_variation_images = {};
      }
    }
    const moveUp = ({ index }) => {
      let variation_item1 = toRaw(state.listingForm.variation_list)[index];
      let variation_item2 = toRaw(state.listingForm.variation_list)[index - 1];
      state.listingForm.variation_list[index - 1] = variation_item1;
      state.listingForm.variation_list[index] = variation_item2;
    }
    const moveDown = ({ index }) => {
      let variation_item1 = toRaw(state.listingForm.variation_list)[index];
      let variation_item2 = toRaw(state.listingForm.variation_list)[index + 1];
      state.listingForm.variation_list[index + 1] = variation_item1;
      state.listingForm.variation_list[index] = variation_item2;
    }
    const oneClickGenerate = (key) => {
      if (key === 'item_sku') {
        let item_sku = state.listingForm.item_sku;
        state.listingForm.variation_list.map(n => {
          let values = [];
          if (n.color_name) values.push(n.color_name);
          if (n.size_name) values.push(n.size_name);
          n.item_sku = `${item_sku ? (item_sku + '-') : ''}${values.join('-')}`;
        });
      } else if (key === 'item_name_zh') {
        let item_name = state.listingForm.zh?.item_name || '';
        state.listingForm.variation_list.map(n => {
          let values = [];
          if (n.color_name) values.push(n.color_name);
          if (n.size_name) values.push(n.size_name);
          n.item_name_zh = `${item_name}-${values.join('-')}`;
        });
      } else if (key === 'item_name_en') {
        let item_name = state.listingForm.en?.item_name || '';
        state.listingForm.variation_list.map(n => {
          let values = [];
          if (n.color_name) values.push(n.color_name);
          if (n.size_name) values.push(n.size_name);
          n.item_name_en = `${item_name}-${values.join('-')}`;
        });
      }
    }
    const changeVariationListProductIDType = (type) => {
      state.listingForm.variation_list.map(n => n.external_product_id_type = type);
    }
    const getExternalProductID = () => {

    }
    const editVariationList = (key) => {
      if (key === 'quantity') {
        state.variationListQuantity = "";
        state.variationListQuantityModal = true;
      } else if (key === 'standard_price') {
        state.variationListPrice = "";
        state.variationListPriceModal = true;
      }
    }
    const confirmVariationListQuantity = () => {
      if (!state.variationListQuantity) return message.error("请输入修改数量");
      if (!proxy.$utils.isDecimal(state.variationListQuantity) || proxy.$utils.d(state.variationListQuantity).lt(0)) return message.error("请输入正确数量");
      state.listingForm.variation_list.map(n => {
        n.quantity = state.variationListQuantity;
      });
      state.variationListQuantityModal = false;
    }
    const confirmVariationListPrice = () => {
      if (!state.variationListPrice) return message.error("请输入修改价格");
      if (!proxy.$utils.isDecimal(state.variationListPrice) || proxy.$utils.d(state.variationListPrice).lte(0)) return message.error("请输入正确价格");
      state.listingForm.variation_list.map(n => {
        n.standard_price = state.variationListPrice;
      });
      state.variationListPriceModal = false;
    }
    const editMainImage = (index) => {
      state.pickImageTab = "variation_images";
      state.pickImageType = "main_image";
      state.pickImageIndex = index;
      state.pickImageModal = true;
    }
    const clearVariationImages = (record) => {
      record.images = [];
    }
    const editVariationImages = (index, item) => {
      state.pickImageTab = "variation_images";
      state.pickImageType = "variation_images";
      state.pickImageIndex = index;
      state.pickImages = [...toRaw(item.images)];
      state.pickImageModal = true;
    }
    const addPickImage = (image) => {
      if (state.pickImages.length >= 12) return message.error("多属性图片最多12张，请删除不必要的图片");
      if (state.pickImages.indexOf(image) > -1) return message.error("该图片已添加，请勿重复添加");
      state.pickImages.push(image);
    }
    const doPickImage = (image) => {
      state.listingForm.variation_list[state.pickImageIndex].images[0] = image;
      state.pickImageModal = false;
    }
    const removePickImage = (index) => {
      state.pickImages.splice(index, 1);
    }
    const confirmPickImages = () => {
      if (state.pickImageType === "variation_images") {
        state.listingForm.variation_list[state.pickImageIndex].images = [...state.pickImages];
        state.pickImageModal = false;
      }
    }
    const removeVariationItem = (index) => {
      state.listingForm.variation_list.splice(index, 1);
    }
    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }
    const doSave = async () => {
      try {
        state.saving = true;
        await listingformRef.value.validateFields();
        proxy.$api.doAPI("/listingcollect/save", { listingForm: state.listingForm }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            Modal.success({
              title: "提示",
              content: "保存成功",
              centered: true,
              okText: "关闭",
              okType: "default",
              onOk() {
                window.close();
              },
            });
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        state.saving = false;
        console.log("Failed:", errorInfo);
        message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
      }
    }
    return {
      ...toRefs(state),
      variation_headers,
      locale,
      listingformRef,
      editorRef,
      width,
      curr_lang_label,
      images_count,
      variation_images_count,
      pick_images,
      editorMode: 'default',
      toolbarConfig,
      editorConfig,
      changeImageType,
      removeImage,
      removeVariationImage,
      onImageLoad,
      onVariationImageLoad,
      downloadImages,
      copyImagesUrl,
      removeImagesUrl,
      onLocalUploadSuccess,
      onNetworkInputSuccess,
      onSpacePickSuccess,
      toggleCheckAllImages,
      moveUp,
      moveDown,
      oneClickGenerate,
      changeVariationListProductIDType,
      getExternalProductID,
      editVariationList,
      confirmVariationListQuantity,
      confirmVariationListPrice,
      editMainImage,
      clearVariationImages,
      editVariationImages,
      addPickImage,
      doPickImage,
      removePickImage,
      confirmPickImages,
      removeVariationItem,
      handleCreated,
      doSave,
      doCopy: proxy.$utils.doCopy,
      image_placeholder: proxy.$constant.image_placeholder,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

p {
  margin: 0;
  padding: 0;
}

.form-item {
  display: flex;
}

.form-label {
  min-width: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 3px;
  padding-right: 10px;
  text-align: right;
}

.form-label>span:first-child::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin-left: 2px;
}

.form-label>.en {
  word-break: break-word;
}

.mainform {
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
}

.footer {
  box-shadow: 0 0 6px 0 #d9d9d9;
  height: 64px !important;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: right;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
}

.image-block {
  width: 70px;
}

.image-border {
  border: 2px solid #eee;
}

.main-image {
  border-color: #e6a23c;
  box-shadow: 0 0 5px #e6a23c;
}

.btn-remove {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 16px;
  width: 16px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, .5);
  cursor: pointer;
}
</style>