<template>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">来自平台：</span> </a-col>
      <a-col>
        <a-tag v-for="item in platforms" :key="item.value"
          :color="queryForm.platform === item.value ? (item.color || 'processing') : 'default'" class="click"
          @click="changePlatform(item.value)"> {{ item.label }} </a-tag>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">订单类型：</span> </a-col>
      <a-col>
        <div>
          <a-tag :color="queryForm.ordertype === 0 ? 'processing' : 'default'" @click="changeOrdertype(0)"
            class="click">自发货</a-tag>
          <a-tag :color="queryForm.ordertype === 1 ? 'processing' : 'default'" @click="changeOrdertype(1)"
            class="click">FBA</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">商品检索：</span> </a-col>
      <a-col>
        <div>
          <a-tag :color="queryForm.skumatch_status === 0 ? 'processing' : 'default'" @click="change_skumatch_status(0)"
            class="click">全部</a-tag>
          <a-tag :color="queryForm.skumatch_status === 1 ? 'processing' : 'default'" @click="change_skumatch_status(1)"
            class="click">已匹配</a-tag>
          <a-tag :color="queryForm.skumatch_status === 2 ? 'processing' : 'default'" @click="change_skumatch_status(2)"
            class="click">未匹配</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">所在店铺：</span> </a-col>
      <a-col>
        <a-select v-model:value="queryForm.shopid" placeholder="请选择店铺" mode="tags" :options="shops"
          style="width: 1000px" @change="(v) => changeShop(v)"></a-select>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-row :gutter="8" style="width:100%;">
      <a-col :span="4">
        <a-textarea v-model:value="queryForm.orderids" placeholder="输入订单号搜索,支持多行输入"
          :auto-size="{ minRows: 1, maxRows: 3 }" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-textarea v-model:value="queryForm.logisticsnos" placeholder="输入物流单号搜索,支持多行输入"
          :auto-size="{ minRows: 1, maxRows: 3 }" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-input v-model:value="queryForm.skus" placeholder="输入库存SKU搜索" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-input v-model:value="queryForm.skuname" placeholder="输入库存SKU名称搜索" style="width:100%;" />
      </a-col>
      <a-col :span="4">
        <a-range-picker v-model:value="queryForm.daterange" :locale="locale" value-format="YYYY-MM-DD" />
      </a-col>
      <a-col :span="4">
        <a-button type="primary" @click="getData">搜索</a-button>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-end align-center mb-2">
    <a-space>
      <!-- <a-button type="primary" @click="getData">同步订单</a-button> -->
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导出订单">
              <a-menu-item key="1">导出勾选订单</a-menu-item>
              <a-menu-item key="2">导出全部订单</a-menu-item>
              <a-menu-item key="3">查看导出任务</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          导出订单
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'], total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: changeSelectedRows }" v-model:expandedRowKeys="expandedRowKeys"
    size="small">
    <template #title>
      <MinusOutlined v-if="expanded" class="click" style="border:1px solid #ddd;" @click="foldall" />
      <PlusOutlined v-else class="click" style="border:1px solid #ddd;" @click="expandall" />
    </template>
    <template #headerCell="{ column }">
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>店铺</p>
        <p>订单号</p>
        <p>SKU</p>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>买家</p>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>订单金额(原始货币)</p>
        <p>收入运费(原始货币)</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p>创建时间</p>
        <p>付款时间</p>
        <p>剩余发货</p>
        <p>交运时间</p>
        <p>打印时间</p>
      </template>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'platform'">
        <a-tag v-if="record.platform === 'amz'" color="#FF9F0F">Amazon</a-tag>
        <a-tag v-else-if="record.platform === 'smt'" color="#E62E04">AliExpress</a-tag>
        <a-tag v-else-if="record.platform === 'ozon'" color="#1985FF">Ozon</a-tag>
        <a-tag v-else-if="record.platform === 'tiktok'" color="#010101">TikTok</a-tag>
        <a-tag v-else-if="record.platform === 'walmart'" color="#FFC935">Walmart</a-tag>
        <a-tag v-else-if="record.platform === 'shopee'" color="#FE5622">Shopee</a-tag>
        <a-tag v-else>{{ record.platform }}</a-tag>
      </template>
      <template v-if="column.dataIndex === 'shop_orderid'">
        <p>{{ record.shop?.name }}</p>
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="doCopy(record.orderid)">复制</a>
          </template>
          <p>{{ record.orderid }}</p>
        </a-tooltip>
        <p class="text-info in1line"> {{ showorderitems(record) }} </p>
        <p>
          <svg-icon v-if="record.has_battery" iconClass="dianchi1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带电池" />
          <svg-icon v-else iconClass="dianchi" :style="{ 'width': '20px', 'height': '20px' }" title="不带电池" />
          <svg-icon v-if="record.has_magnet" iconClass="citie1" :style="{ 'width': '20px', 'height': '20px' }"
            title="带磁" />
          <svg-icon v-else iconClass="citie" :style="{ 'width': '20px', 'height': '20px' }" title="不带磁" />
          <svg-icon v-if="record.is_liquid" iconClass="yeti1" :style="{ 'width': '20px', 'height': '20px' }"
            title="液体" />
          <svg-icon v-else iconClass="yeti" :style="{ 'width': '20px', 'height': '20px' }" title="非液体" />
          <svg-icon v-if="record.is_powder" iconClass="fenchen1" :style="{ 'width': '20px', 'height': '20px' }"
            title="粉末" />
          <svg-icon v-else iconClass="fenchen" :style="{ 'width': '20px', 'height': '20px' }" title="非粉末" />
          <svg-icon v-if="record.items[0]?.product?.is_combine" iconClass="zuhesku1"
            :style="{ 'width': '20px', 'height': '20px' }" :title="record.items[0]?.seller_sku" />
        </p>
      </template>
      <template v-if="column.dataIndex === 'skumatch_status'">
        <span v-if="record.skumatch_status === 'fullmatch'" class="text-success">
          SKU已配对
        </span>
        <span v-else class="text-error">
          SKU未配对
        </span>
      </template>
      <template v-if="column.dataIndex === 'raw_order_status'">
        {{ record.raw_order_status || record.order_status }}
      </template>
      <template v-if="column.dataIndex === 'logistics_channel'">
        <template v-if="record.channelbind">
          <span class="text-info">
            【{{ record.supplierbind.name }}】{{ record.channelbind.name }}
            {{ record.channelbind.remark ? `（${record.channelbind.remark}）` : '' }}
          </span>
          <div>
            <a-tag v-if="record.logistics_status === 'pending'" color="warning"> 正在获取物流单号 </a-tag>
            <template v-else-if="record.logistics_status === 'success'">
              <div class="d-flex align-center">
                <a-tooltip color="white">
                  <template #title>
                    <a href="javascript:;" @click="doCopy(record.logistics_tracking_number)">复制</a>
                  </template>
                  <a-tag color="success"> {{ record.logistics_tracking_number }} </a-tag>
                </a-tooltip>
                <a-tooltip title="打印面单">
                  <a href="javascript:;" @click="printorder(record)">
                    <svg-icon iconClass="printer" :style="{ 'width': '20px', 'height': '20px' }" />
                  </a>
                </a-tooltip>
                <a-tooltip title="物流追踪">
                  <a :href="`https://t.17track.net/zh-cn#nums=${record.logistics_tracking_number}`" target="_blank">
                    <svg-icon iconClass="track" :style="{ 'width': '20px', 'height': '20px' }" />
                  </a>
                </a-tooltip>
              </div>
              <div v-if="record.feed">
                <a-tag v-if="record.feed.step === 'SUCCESS'" color="success"> 标发成功 </a-tag>
                <a-tag v-else-if="record.feed.step === 'ERROR'" color="error"> 标发失败 </a-tag>
                <a-tag v-else color="processing"> 标发中 </a-tag>
              </div>
              <div v-else>
                <a-tag color="warning"> 未标发 </a-tag>
              </div>
            </template>
            <div v-else>
              <a-tag color="error"> 物流单号获取失败 </a-tag>
              <span class="text-error"> {{ record.logistics_reason }} </span>
            </div>
          </div>
        </template>
        <span v-else class="text-error">物流渠道未选择</span>
      </template>
      <template v-if="column.dataIndex === 'buyer'">
        <p>{{ record.shipping_country }}</p>
        <p>{{ record.shipping_state }}</p>
        <p>{{ record.shipping_postalcode }}</p>
        <p>{{ record._shipping_name || record.shipping_name }}</p>
      </template>
      <template v-if="column.dataIndex === 'order_amount'">
        <p>{{ record.amount }} {{ record.order_currency }}</p>
        <p>{{ record.freight }} {{ record.order_currency }}</p>
      </template>
      <template v-if="column.dataIndex === 'time'">
        <p title="创建时间">{{ record.create_time }}</p>
        <p title="付款时间">{{ record.purchase_time }}</p>
        <div title="剩余发货">
          <template v-if="record.order_status === 'Unshipped'">
            <p v-if="record.ship_timeout" style="font-size:16px;font-weight:bold;color:#ed5466">
              过期：{{ record.ship_time }}
            </p>
            <template v-else>
              <p v-if="record.latest_ship_date" style="font-size:16px;font-weight:bold;color:#91c854">
                最晚发货：{{ record.latest_ship_date }}
              </p>
              <p style="font-size:16px;font-weight:bold;color:#91c854">
                剩余时间：{{ record.ship_time }}
              </p>
            </template>
          </template>
          <template v-else> - </template>
        </div>
        <p title="交运时间">{{ record.delivery_time || '-' }}</p>
        <p title="打印时间">{{ record.print_time || '-' }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <!-- <a-button type="primary" size="small" danger>拆分</a-button> -->
          <a-tooltip>
            <template #title>
              {{ record.remark || '' }}
              <template v-if="record.order_remark || record.shipping_remark">
                <p v-if="record.order_remark">订单备注：{{ record.order_remark }}</p>
                <p v-if="record.shipping_remark">订单发货备注：{{ record.shipping_remark }}</p>
              </template>
              <span v-else>暂无备注</span>
            </template>
            <a-button type="link" size="small" @click="showRemark(record)">备注</a-button>
          </a-tooltip>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="itemcolumns" :data-source="record.items" :scroll="{ x: iwidth }" :pagination="false"
        size="small">
        <template #bodyCell="{ record: irecord, column: icolumn }">
          <div v-if="icolumn.dataIndex === 'cover'" class="text-center" style="width:80px;">
            <a-tooltip v-if="irecord.product?.cover || irecord.shoplisting?.image_url" placement="rightBottom"
              color="#fff" overlayClassName="coverimage" :overlayStyle="{ width: '500px', height: '500px' }">
              <template #title>
                <div style="position: absolute;z-index: 2000; border:3px solid #ddd;">
                  <img :src="irecord.product?.cover || irecord.shoplisting?.image_url"
                    style="width:500px;height:500px;object-fit: contain;position: relative;z-index: -1;" />
                  <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 360px;">
                    <a-tag color="#ff7039">
                      <span style="font-size:18px;cursor:pointer"
                        @click="gettaobaolist(irecord.product?.cover || irecord.shoplisting?.image_url)"> 淘宝
                      </span>
                    </a-tag>
                  </div>
                  <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 420px;">
                    <a-tag color="#ff7039">
                      <span style="font-size:18px;cursor:pointer"
                        @click="getImagesBase64(irecord.product?.cover || irecord.shoplisting?.image_url)"> 1688
                      </span>
                    </a-tag>
                  </div>
                </div>
              </template>
              <div class="image-header">
                <img :src="irecord.product?.cover || irecord.shoplisting?.image_url" style="object-fit: contain;" />
              </div>
            </a-tooltip>
            <div v-else class="image-header">
              <img :src="nosku" style="object-fit: contain;" />
            </div>
          </div>
          <template v-if="icolumn.dataIndex === 'info'">
            <div>
              <a-tooltip color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.product?.sku || irecord.seller_sku)">复制</a>
                </template>
                <a href="javascript:;"
                  @click="editProduct(irecord.product?.sku || irecord.seller_sku, irecord.product?.is_combine)">
                  <span v-if="irecord.product?.is_combine" class="text-error">(组合)</span>
                  {{ irecord.product?.sku || irecord.seller_sku }}
                </a>
              </a-tooltip>
              <span class="text-error text-h6">×{{ irecord.quantity_ordered }}</span>
              <a-tooltip v-if="record.amazon_product_url" color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.asin)">复制</a>
                </template>
                <a :href="`${record.amazon_product_url}${irecord.asin}`" target="_blank" class="underline ml-1">
                  {{ irecord.asin }}
                </a>
                <a-tag color="error" v-if="irecord.genmai"> 疑似被跟卖 </a-tag>
              </a-tooltip>
              <a-tooltip v-if="record.ozon_product_url" color="white">
                <template #title>
                  <a href="javascript:;" @click="doCopy(irecord.orderitemid)">复制</a>
                </template>
                <a :href="`${record.ozon_product_url}${irecord.orderitemid}`" target="_blank" class="underline ml-1">
                  {{ irecord.orderitemid }}
                </a>
              </a-tooltip>
            </div>
            <p>{{ irecord.product?.name_cn || irecord.title }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'cinfo'">
            <template v-if="irecord.product?.combine_list?.length > 0">
              <div v-for="item in irecord.product?.combine_list" :key="item.sku" class="d-flex justify-space-between"
                style="border-bottom:1px solid #ececec;">
                <div class="text-center" style="width:80px;">
                  <a-tooltip v-if="item.cover" placement="right" color="#fff"
                    :overlayStyle="{ width: '500px', height: '500px' }">
                    <template #title>
                      <div style="position: absolute;z-index: 2000; border:3px solid #ddd;">
                        <img :src="item.cover"
                          style="width:500px;height:500px;object-fit: contain;position: relative;z-index: -1;" />
                        <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 360px;">
                          <a-tag color="#ff7039">
                            <span style="font-size:18px;cursor:pointer" @click="gettaobaolist(item.cover)"> 淘宝
                            </span>
                          </a-tag>
                        </div>
                        <div style="position: absolute;z-index: 1;display: inline;top: 20px;right: 0;left: 420px;">
                          <a-tag color="#ff7039">
                            <span style="font-size:18px;cursor:pointer" @click="getImagesBase64(item.cover)"> 1688
                            </span>
                          </a-tag>
                        </div>
                      </div>
                    </template>
                    <div class="image-header">
                      <img :src="item.cover" style="object-fit: contain;" />
                    </div>
                  </a-tooltip>
                  <div v-else class="image-header">
                    <img :src="image_placeholder" style="object-fit: contain;" />
                  </div>
                </div>
                <div style="flex:1;">
                  <div>
                    <a-tooltip color="white">
                      <template #title>
                        <a href="javascript:;" @click="doCopy(item.sku)">复制</a>
                      </template>
                      <a href="javascript:;" @click="editProduct(item.sku)">{{ item.sku }}</a>
                    </a-tooltip>
                    <span class="text-error text-h6">×{{ item.quantity * irecord.quantity_ordered }}</span>
                  </div>
                  <p>{{ item.name_cn }}</p>
                </div>
              </div>
            </template>
            <div v-else>-</div>
          </template>
          <template v-if="icolumn.dataIndex === 'purchase'">
            <p class="text-error">
              已计划采购数量：<span class="text-h6">{{ irecord.purchasequantity }}</span>
            </p>
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal v-model:visible="remarkModal" title="修改备注" :maskClosable="false" :closable="!remarkSaving"
    @cancel="() => remarkModal = false" width="750px">
    <a-tabs v-model:activeKey="remarkForm.remark_type">
      <a-tab-pane key="order_remark" tab="订单备注">
        <a-textarea v-model:value="remarkForm.order_remark" placeholder="订单备注"
          :auto-size="{ minRows: 5, maxRows: 5 }" />
      </a-tab-pane>
      <a-tab-pane key="shipping_remark" tab="订单发货备注">
        <a-textarea v-model:value="remarkForm.shipping_remark" placeholder="订单发货备注"
          :auto-size="{ minRows: 5, maxRows: 5 }" />
      </a-tab-pane>
    </a-tabs>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-button @click="saveRemark" type="primary" :loading="remarkSaving">确定</a-button>
        <a-button @click="() => remarkModal = false" :disabled="remarkSaving">关闭</a-button>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="taskModal" centered :maskClosable="false" :closable="!taskLoading" :footer="null"
    width="750px">
    <template #title>
      <a-space>
        导出任务
        <a-button :loading="taskLoading" @click="showTask">刷新</a-button>
      </a-space>
    </template>
    <a-table :columns="taskcolumns" :data-source="tasklist" :loading="taskLoading" :scroll="{ x: twidth }" size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-if="record.state === 'success'" color="success">成功</a-tag>
          <a-tag v-if="record.state === 'error'" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-button v-if="record.state === 'success'" type="link" danger @click="downloadTask(record)">下载</a-button>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, nextTick, toRaw, } from "vue";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { printBlob } from "../../../zhengbo";
const { proxy } = getCurrentInstance();
const columns = ref([
  { title: "来自平台", dataIndex: "platform", width: 100 },
  { title: "店铺/店长/订单号", dataIndex: "shop_orderid", width: 200 },
  { title: "SKU配对", dataIndex: "skumatch_status", width: 100 },
  { title: "物流渠道", dataIndex: "logistics_channel", width: 200 },
  { title: "买家", dataIndex: "buyer", width: 200 },
  { title: "订单金额", dataIndex: "order_amount", width: 150 },
  { title: "时间", dataIndex: "time", width: 240 },
  { title: "操作", dataIndex: "operation", width: 150, fixed: "right" },
]);
const itemcolumns = ref([
  { title: '图片', dataIndex: 'cover', width: 50 },
  { title: 'SKU编号/数量/中文名称', dataIndex: 'info', width: 200 },
  { title: '组合SKU编号/数量/中文名称', dataIndex: 'cinfo', width: 200 },
  { title: '采购信息', dataIndex: 'purchase', width: 100 },
]);
const taskcolumns = ref([
  { title: '任务编号', dataIndex: 'number', width: 100 },
  { title: '任务状态', dataIndex: 'state', width: 120 },
  { title: '创建时间', dataIndex: 'create_time', width: 120 },
  { title: '操作', dataIndex: 'operation', width: 120 },
])
const queryForm = ref({
  orderids: "",
  logisticsnos: "",
  skus: "",
  skuname: "",
  orderstate: 0,
  ordertype: 0,
  platform: '',
  shopid: [],
  skumatch_status: 0,
  daterange: [undefined, undefined],
});
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const selectedRowKeys = ref([]);
const orderprinting = ref(false);
const expandedRowKeys = ref([]);
const expanded = ref(false);
const shops = ref([]);
const platforms = ref([]);
const remarkForm = ref({});
const remarkModal = ref(false);
const remarkSaving = ref(false);
const tasklist = ref([]);
const taskModal = ref(false);
const taskLoading = ref(false);

const width = computed(() => proxy.$utils.twidth(columns.value));
const iwidth = computed(() => proxy.$utils.twidth(itemcolumns.value));
const twidth = computed(() => proxy.$utils.twidth(taskcolumns.value));

watch(() => page.value, () => {
  expanded.value = false;
  getData()
});
watch(() => limit.value, () => {
  expanded.value = false;
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.platform, () => {
  expanded.value = false;
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.ordertype, () => {
  expanded.value = false;
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.skumatch_status, () => {
  expanded.value = false;
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => queryForm.value.shopid, () => {
  expanded.value = false;
  if (page.value !== 1) page.value = 1;
  else getData();
});

onActivated(() => {
  getShops();
});
const changePlatform = (item) => {
  queryForm.value.platform = item;
  getShops();
};
const changeOrdertype = (item) => {
  queryForm.value.ordertype = item;
};
const change_skumatch_status = (item) => {
  queryForm.value.skumatch_status = item;
};
const changeShop = (item) => {
  queryForm.value.shopid = item;
};
const getShops = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/product/shops", { platform: queryForm.value.platform, }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      shops.value = res.data.shops;
      platforms.value = [{ label: "全部", value: "" }, ...res.data.platforms];
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getData = () => {
  selectedRowKeys.value = [];
  dataLoading.value = true;
  let order_date_range_mark = localStorage.getItem('order_date_range_mark');
  if (order_date_range_mark) {
    let daterange = null;
    if (order_date_range_mark === 'today') {
      daterange = [
        proxy.$utils.moment().format('YYYY-MM-DD'),
        proxy.$utils.moment().format('YYYY-MM-DD'),
      ];
    }
    else if (order_date_range_mark === 'yesterday') {
      daterange = [
        proxy.$utils.moment().subtract(1, 'day').format('YYYY-MM-DD'),
        proxy.$utils.moment().subtract(1, 'day').format('YYYY-MM-DD'),
      ];
    }
    if (daterange) {
      queryForm.value = { ...toRaw(queryForm.value), daterange }
      console.log(daterange, queryForm.value);
    }
    localStorage.removeItem('order_date_range_mark');
  }
  let params = {
    page: page.value, limit: limit.value, ...queryForm.value
  };
  proxy.$api.doAPI("/order/list", params).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const exportData = (ids) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/order/exportlist", { ids, ...queryForm.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      showTask();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const showTask = () => {
  taskLoading.value = true;
  proxy.$api.doAPI("/order/exporttask").then((res) => {
    taskLoading.value = false;
    if (res.code === 0) {
      tasklist.value = res.data.list;
      taskModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    taskLoading.value = false;
    console.error(err);
  });
}
const downloadTask = (item) => {
  proxy.$utils.download(item.remoteurl, item.originalname);
}
const handleExportClick = ({ key }) => {
  console.log('handleExportClick', key);
  if (key === '1') {//导出勾选订单
    if (selectedRowKeys.value.length === 0) return message.error("未勾选订单");
    exportData(selectedRowKeys.value);
  }
  if (key === '2') {//导出全部页订单
    exportData([]);
  }
  if (key === '3') {//查看导出任务
    showTask();
  }
}
const showorderitems = ({ items }) => {
  return _.reduce(items, (a, b) => a.concat(`${b.seller_sku || b.asin}*${b.quantity_ordered}`), []).join(';')
}
const changeSelectedRows = (keys) => {
  let list = _.filter(data.value, n => keys.indexOf(n._id) > -1);
  selectedRowKeys.value = _.pluck(list, "_id");
  console.log(toRaw(selectedRowKeys.value));
}
const printorder = (item) => {
  _printorder([item._id]);
}
const _printorder = (order_ids) => {
  orderprinting.value = true;
  proxy.$api.doAPI("/order/printorder", { order_ids }).then((res) => {
    orderprinting.value = false;
    if (res.code === 0) {
      let urls = res.data.urls;
      proxy.$utils.mergePDF(urls).then((blob) => {
        printBlob(blob);
      });
      // selectedRowKeys.value = [];
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    orderprinting.value = false;
    console.error(err);
  });
}
const expandall = () => {
  expandedRowKeys.value = _.pluck(data.value, "_id");
  expanded.value = true;
}
const foldall = () => {
  expandedRowKeys.value = [];
  expanded.value = false;
}
const gettaobaolist = (url) => {
  proxy.$utils.downloadBrowser({ href: `/orders/manage/tblist?url=${url}` });
}
const getImagesBase64 = (url) => {
  message.loading("", 0);
  let image = new Image();
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = url + '?tamp=' + (new Date()).valueOf();
  image.onload = () => {
    let canvas = document.createElement("canvas");
    let width = image.width > 500 ? 500 : image.width;
    let height = image.height > 500 ? 500 : image.height;
    canvas.width = width;
    canvas.height = height;
    let context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, width, height);
    let dataURL = canvas.toDataURL("image/png");
    dataURL = dataURL.split(",")[1];
    proxy.$api.doAPI("/ae/uploadImage", { base64File: dataURL }).then((res) => {
      if (res.code === 0) {
        message.destroy();
        let { success, code, result, message: msg } = res.data.data.result;
        if (code === '200') {
          if (result) {
            window.open(`https://ifenxiao.1688.com/hostList.html?tab=imageSearch&imageId=${result}`, '_blank')
          }
        } else {
          message.error(msg);
        }
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      message.destroy();
      console.error(err);
    });
  }
}
const editProduct = (sku, is_combine) => {
  if (is_combine) {
    proxy.$utils.downloadBrowser({ href: `/product/manage/skus?sku=${sku}` });
  } else {
    proxy.$utils.downloadBrowser({ href: `/product/manage/sku?sku=${sku}` });
  }
}
const showRemark = (item) => {
  remarkForm.value = {
    _id: item._id,
    order_remark: item.order_remark || '',
    shipping_remark: item.shipping_remark || '',
    remark_type: 'order_remark',
  };
  remarkModal.value = true;
}
const saveRemark = () => {
  remarkSaving.value = true;
  proxy.$api.doAPI("/order/saveremark", remarkForm.value).then((res) => {
    remarkSaving.value = false;
    if (res.code === 0) {
      message.success("保存成功");
      remarkModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    remarkSaving.value = false;
    console.error(err);
  });
}

const doCopy = proxy.$utils.doCopy;
const image_placeholder = ref(proxy.$constant.image_placeholder);
const nosku = ref(proxy.$constant.nosku);

</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
