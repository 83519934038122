<template>
  <div class="header">
    <a-row :gutter="24">
      <a-col :span="6">
        <a-form-item name="shop" label="选择店铺">
          <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops"></a-select>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item name="createtime" label="统计时间">
          <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" :allowClear="false" />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-button type="primary" @click="getData">查询</a-button>
      </a-col>
    </a-row>
  </div>
  <div class="content">
    <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }" :loading="dataLoading"
      :pagination="false" size="small">
      <template #title>
        <div class="theader">
          <span class="title">订单状态报表</span>
          <!-- <a-button type="primary">导出订单状态报表</a-button> -->
        </div>
      </template>
      <template #headerCell="{ column }">
        <template v-if="column.dataIndex === 'count'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天下单数量</span>
            </template>
            下单数
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'order_amount'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天下单付款总金额</span>
            </template>
            总金额
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'biaofa_count'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天标发数量</span>
            </template>
            标发数
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'unshipped_count'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天下单未发货数量</span>
            </template>
            未发货数
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'shipped_count'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天通过TMS已发货数</span>
            </template>
            已发货数
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'refund_count'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天退款数量</span>
            </template>
            退款数
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'refund_amount'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天退款金额</span>
            </template>
            退款金额
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'canceled_count'">
          <a-tooltip placement="bottom">
            <template #title>
              <span>当天作废订单数量</span>
            </template>
            作废数
          </a-tooltip>
        </template>
      </template>
      <template #bodyCell="{ column, record, index }">
        <template v-if="column.dataIndex === 'index'">
          <p>{{ index + 1 }}</p>
        </template>
        <template v-if="column.dataIndex === 'country'">
          <span style="color: red;"
            v-if="record.country === undefined || record.country === null || record.country === ''">未匹配</span>
          <span v-else>{{ record.country }}</span>
        </template>
        <template v-if="column.dataIndex === 'count'">
          {{ record.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'order_amount'">
          {{ record.order_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'biaofa_count'">
          {{ record.biaofa_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'unshipped_count'">
          {{ record.unshipped_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'shipped_count'">
          {{ record.shipped_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'refund_count'">
          {{ record.refund_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'refund_amount'">
          {{ record.refund_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
        <template v-if="column.dataIndex === 'canceled_count'">
          {{ record.canceled_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, align: 'center' },
  { title: '统计时间', dataIndex: 'date', width: 150, align: 'center' },
  { title: '下单数', dataIndex: 'count', width: 150, align: 'center' },
  { title: '总金额', dataIndex: 'order_amount', width: 150, align: 'center' },
  { title: '标发数', dataIndex: 'biaofa_count', width: 150, align: 'center' },
  { title: '未发货数', dataIndex: 'unshipped_count', width: 150, align: 'center' },
  { title: '已发货数', dataIndex: 'shipped_count', width: 150, align: 'center' },
  { title: '退款数', dataIndex: 'refund_count', width: 150, align: 'center' },
  { title: '退款金额', dataIndex: 'refund_amount', width: 150, align: 'center' },
  { title: '作废数', dataIndex: 'canceled_count', width: 150, align: 'center' }
];
export default defineComponent({
  name: 'FinanceOrderState',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data: [],
      shopid: undefined,
      shops: [],
      daterange: [],
      dataLoading: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onActivated(() => {
      let beforeDay = proxy.$utils.moment().subtract(0, "days").format('YYYY-MM-DD');
      let before7Day = proxy.$utils.moment().subtract(6, "days").format('YYYY-MM-DD');
      state.daterange = [];
      state.daterange.push(before7Day);
      state.daterange.push(beforeDay);
      getShops();
    });
    const getShops = () => {
      proxy.$api.doAPI("/product/shops", { platform: "", }).then((res) => {
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/finance/salesreport/orderstatusrepost", {
        daterange: state.daterange,
        shopid: state.shopid,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>