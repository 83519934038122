import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
// import ECharts from "vue-echarts";
// import { use } from "echarts/core";
// import { CanvasRenderer } from "echarts/renderers";
// import { BarChart } from "echarts/charts";
// import { GridComponent, TooltipComponent } from "echarts/components";
// use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);
// app.component("v-chart", ECharts);

const app = createApp(App);

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
app.use(Antd);
import VueApexCharts from 'vue3-apexcharts';
app.use(VueApexCharts);
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
app.use(VXETable);
import vPreviewImage from 'v-preview-image';
app.use(vPreviewImage);

app.config.productionTip = false;
import BasicLayout from "./layouts/BasicLayout";
import EmptyLayout from "./layouts/EmptyLayout";
app.component("basic-layout", BasicLayout);
app.component("empty-layout", EmptyLayout);
import api from './request'
app.config.globalProperties.$api = api;
import utils from './utils';
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$d = utils.d;
import async from 'async';
app.config.globalProperties.$async = async;
import axios from 'axios'
app.config.globalProperties.$axios = axios;
import constant from './constant';
app.config.globalProperties.$constant = constant;

app.config.globalProperties.$filters = {
  dateFormat: utils.dateTime,
  dateTime: utils.dateTime,
  fromNow: utils.fromNow,
  filterOption: utils.filterOption,
}
import './icons'
import SvgIcon from './components/svgicon/Index.vue'
app.component('svg-icon', SvgIcon)
app.use(router);
app.use(store);
app.mount("#app");
