<template>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col>
        <span style="width:75px;">货件状态：</span>
      </a-col>
      <a-col>
        <div>
          <a-tag :color="inventorystate === '0' ? 'processing' : 'default'" @click="changeInventorystate('0')"
            class="click">全部</a-tag>
          <a-tag :color="inventorystate === '1' ? 'processing' : 'default'" @click="changeInventorystate('1')"
            class="click">待装箱({{ stayEncaseNum }})</a-tag>
          <a-tag :color="inventorystate === '2' ? 'processing' : 'default'" @click="changeInventorystate('2')"
            class="click">待发货({{ stayDeliNum }})</a-tag>
          <a-tag :color="inventorystate === '3' ? 'processing' : 'default'" @click="changeInventorystate('3')"
            class="click">已发货({{ shippedNum }})</a-tag>
          <a-tag :color="inventorystate === '4' ? 'processing' : 'default'" @click="changeInventorystate('4')"
            class="click">已完成({{ completedNum }})</a-tag>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">选择店铺：</span></a-col>
      <a-col :span="18">
        <div>
          <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops" style="width: 1000px"
            @change="(v) => changeShop(v)"></a-select>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入SKU、ASIN、FNSKU查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="generateShipment" v-if="inventorystate === '2'">生成发货</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleBulkClick">
            <a-menu-item-group title="批量编辑">
              <a-menu-item key="1" :disabled="selectedRowKeys.length === 0">打印商品标签</a-menu-item>
              <a-menu-item key="2" :disabled="selectedRowKeys.length === 0">打印外箱标签</a-menu-item>
              <a-menu-item key="3" :disabled="selectedRowKeys.length === 0">批量编辑</a-menu-item>
              <a-menu-item key="4" :disabled="selectedRowKeys.length === 0">批量作废</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          批量操作
          <DownOutlined />
        </a-button>
      </a-dropdown>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="processImportExport">
            <a-menu-item key="1" :disabled="selectedRowKeys.length === 0">导出勾选</a-menu-item>
            <a-menu-item key="2">导出所有页</a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          导出数据
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <a-button type="primary" @click="getData">刷新数据</a-button>
    </a-space>
  </div>
  <a-table :columns="columnsf" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'shop'">
        <p>{{ record.shop?.name || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <p v-if="record.approve_status === 'Processed'" style="font-size: 12px; color: #67C23A;">待装箱</p>
        <p v-if="record.approve_status === 'StayDeliGoods'" style="font-size: 12px; color: #67C23A;">待发货</p>
        <p v-if="record.approve_status === 'Shipped'" style="font-size: 12px; color: #67C23A;">已发货</p>
        <p v-if="record.approve_status === 'Completed'" style="font-size: 12px; color: #67C23A;">已完成</p>
      </template>
      <template v-if="column.dataIndex === 'logisticsinfo'">
        <a-popover placement="right">
          <template #content>
            <p v-for="(track_number, index) in record.tracking_number" :key="index">{{ track_number }}</p>
          </template>
          <a type="primary">物流信息</a>
        </a-popover>
      </template>
      <template v-if="column.dataIndex === 'expect_time'">
        <p>发货时间：{{ record.sendtime }}</p>
        <p>到货时间：{{ record.arrivaltime }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space direction="vertical">
          <a href="javascript:;">详情</a>
          <a href="javascript:;" @click="encase(record)" v-if="record.approve_status === 'Processed'">装箱</a>
          <!-- <a href="javascript:;" v-if="record.approve_status !== 'Invalidated'"  @click="updateInboundShipment(record,'up')">编辑</a> -->
          <a-popconfirm title="作废后将无法恢复，确定作废？" @confirm="updateInboundShipment(record, 'de')" @cancel="cancel">
            <a href="javascript:;" class="text-error" v-if="record.approve_status !== 'Invalidated'">作废</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
    <template #expandedRowRender="{ record }">
      <a-table :columns="columns" :data-source="record.items" :scroll="{ x: iwidth }" :pagination="false" size="small">
        <template #bodyCell="{ record: irecord, column: icolumn }">
          <template v-if="icolumn.dataIndex === 'cover'">
            <a-image :src="irecord.product?.cover || ''" width="80px" height="80px"
              style="object-fit: contain;height:100%;" :preview="!!irecord.product.cover">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </template>
          <template v-if="icolumn.dataIndex === 'ASIN'">
            <p>{{ irecord.asin }}</p>
            <p>{{ irecord.fnsku }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'info'">
            <p>{{ irecord.product?.sku || '-' }}</p>
            <p>{{ irecord.product?.name_cn || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'price'">
            <p>{{ irecord.product?.declare_amount || '-' }}</p>
            <p>({{ irecord.product?.declare_currency || '-' }})</p>
          </template>
          <template v-if="icolumn.dataIndex === 'shop_country'">
            <p>{{ record.shop?.name || '-' }}</p>
            <p>{{ record.shop?.country || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'inventory'">
            <p>{{ irecord.warehouse?.name || '-' }}</p>
          </template>
          <template v-if="icolumn.dataIndex === 'logistics'">
            <p v-if="irecord.logistics !== undefined">{{ irecord.logistics }}</p>
            <a-select v-else v-model:value="irecord.logistics" :options="logisticsData" placeholder="物流" show-search
              :filter-option="$filters.filterOption" style="width: 150px" />
          </template>
        </template>
      </a-table>
    </template>
  </a-table>
  <a-modal :width="1600" :body-style="bodystyle" v-model:visible="encaseModal" :confirm-loading="encaseLoading"
    :cancel-button-props="{ disabled: encaseLoading }" :maskClosable="false" centered :closable="!encaseLoading">
    <template v-slot:title>
      <span style="font-size: 25px;">{{ encaseDate.shipmentId }}</span>
    </template>
    <a-form ref="encaseDateRef" name="encaseDate" :model="encaseDate">
      <div>
        <a-page-header style="background-color: #f5f5f5;border: 1px solid rgb(235, 237, 240); font-size: 10px;"
          title="货件内容" class="site-page-header">
          <a-descriptions size="small" :column="4">
            <a-descriptions-item label="店铺名称">
              <span class="sp">{{ encaseDate.shop?.name || "-" }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="申报人">
              <span class="sp">{{ encaseDate.create_by }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              <span class="sp">{{ encaseDate.create_time }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="货件名称">
              <span class="sp">{{ encaseDate.shipment_name }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="标签类型">
              <span class="sp">{{ encaseDate.label_type }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="包装类型">
              <span class="sp">{{ encaseDate.packag_type }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="物流中心编码">
              <span class="sp">{{ encaseDate.fulfillmentCenterId }}</span>
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions size="small" :column="3">
            <a-descriptions-item label="配送地址">
              <span class="sp">
                {{ encaseDate.ship_to_address?.CountryCode }}&nbsp;
                {{ encaseDate.ship_to_address?.StateOrProvinceCode }}&nbsp;
                {{ encaseDate.ship_to_address?.AddressLine1 }}&nbsp;
                {{ encaseDate.ship_to_address?.PostalCode }}&nbsp;
                {{ encaseDate.ship_to_address?.Name }}
              </span>
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions size="small" :column="3">
            <a-descriptions-item label="发货地址">
              <span class="sp">
                {{ encaseDate.ship_from_address?.countrycode }}&nbsp;
                {{ encaseDate.ship_from_address?.province }}&nbsp;
                {{ encaseDate.ship_from_address?.addressline1 }}&nbsp;
                {{ encaseDate.ship_from_address?.postalcode }}&nbsp;
                {{ encaseDate.ship_from_address?.name }}
              </span>
            </a-descriptions-item>
          </a-descriptions>
        </a-page-header>
      </div>
      <div>
        <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="配送服务" class="site-page-header">
          <a-descriptions size="small" :column="2">
            <a-descriptions-item>
              <template v-slot:label>
                <span style="color: red">*&nbsp;</span>
                <a-tooltip :overlayStyle="{ maxWidth: '400px' }">
                  <template #title>
                    <span>没有亚马逊服务的货件类型使用非AMZ承运人</span>
                  </template>
                  <QuestionCircleFilled />
                </a-tooltip>
                <span>货件类型</span>
              </template>
              <a-select v-model:value="encaseDate.shipment_type" :options="shipmentTypes" placeholder="SP/LTL"
                show-search :filter-option="$filters.filterOption" style="width: 200px" />
            </a-descriptions-item>
            <a-descriptions-item>
              <template v-slot:label>
                <span style="color: red">*&nbsp;</span><span>承运人</span>
              </template>
              <a-select v-model:value="encaseDate.carrier_name" :options="carrierNames" placeholder="承运人" show-search
                :filter-option="$filters.filterOption" style="width: 200px" dropdownClassName="drop-down-Style"
                :dropdownMatchSelectWidth="false" />
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions size="small" :column="4">
            <a-descriptions-item v-if="encaseDate.shipment_type === 'nSPD'">
              <template v-slot:label>
                <span v-if="encaseDate.shipment_type === 'nSPD' ? true : false"
                  style='color: red'>*&nbsp;</span><span>物流单号</span>
              </template>
              <a-textarea v-model:value="encaseDate.trackings" placeholder="每行一个箱子数字编号,物流单号（例：1,222333）"
                style="width: 380px;" auto-size />
            </a-descriptions-item>
            <a-descriptions-item v-if="encaseDate.shipment_type === 'nLTL'">
              <template v-slot:label>
                <span v-if="encaseDate.shipment_type === 'nLTL' ? true : false"
                  style='color: red'>*&nbsp;</span><span>PRO号</span>
              </template>
              <a-input style="width: 150px;" v-model:value="encaseDate.pro_number" />
            </a-descriptions-item>
            <a-descriptions-item v-if="encaseDate.shipment_type === 'LTL'" label="承运人姓名">
              <a-input style="width: 150px;" v-model:value="encaseDate.contactName" />
            </a-descriptions-item>
            <a-descriptions-item v-if="encaseDate.shipment_type === 'LTL'" label="承运人电话">
              <a-input style="width: 150px;" v-model:value="encaseDate.contactPhone" />
            </a-descriptions-item>
            <a-descriptions-item v-if="encaseDate.shipment_type === 'LTL'" label="承运人邮箱">
              <a-input style="width: 150px;" v-model:value="encaseDate.contactEmail" />
            </a-descriptions-item>
            <a-descriptions-item v-if="encaseDate.shipment_type === 'LTL'" label="承运人传真号码">
              <a-input style="width: 150px;" v-model:value="encaseDate.contactFax" />
            </a-descriptions-item>
          </a-descriptions>
          <span v-if="encaseDate.shipment_type === 'LTL'" style="color: red">承运人信息可填可不填，如若填，请务必填写姓名、电话、邮箱</span>
        </a-page-header>
      </div>
      <div>
        <a-page-header style="border: 1px solid rgb(235, 237, 240)" title="包装货件" class="site-page-header">
          <template #extra>
            <span>箱数：</span><a-input class="demo-input" v-model:value="encaseDate.boxNum" @change="addBox" />
            <span>总重：</span><a-input class="demo-input" v-model:value="encaseDate.weight" :defaultValue="0" />&nbsp;kg
          </template>
          <a-row>
            <a-col :span="10">
              <a-space-compact block>
                <a-table :columns="packageColumns" :data-source="encaseDate.items" :pagination="false"
                  className="boxclass" bordered style="line-height: 1;" size="small">
                  <template #bodyCell="{ record, column }">
                    <template v-if="column.dataIndex === 'cover'">
                      <a-image :src="record.product?.cover || ''" width="30px" height="30px"
                        style="object-fit: contain;height:100%;" :preview="!!record.product.cover">
                        <template #placeholder>
                          <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
                        </template>
                      </a-image>
                    </template>
                    <template v-if="column.dataIndex === 'asin'">
                      <a-tooltip placement="topLeft">
                        <template #title> {{ record.msku }} </template>
                        <p style="font-size: 12px;" class="in1line">{{ record.msku }}</p>
                      </a-tooltip>
                      <a-tooltip placement="topLeft">
                        <template #title> {{ record.fnsku }} </template>
                        <p style="font-size: 12px;" class="in1line">{{ record.fnsku }}</p>
                      </a-tooltip>
                    </template>
                    <template v-if="column.dataIndex === 'sku'">
                      <a-tooltip placement="topLeft">
                        <template #title> {{ record.product?.name_cn }} </template>
                        <p style="font-size: 12px;" class="in1line">{{ record.product?.name_cn }}</p>
                      </a-tooltip>
                      <a-tooltip placement="topLeft">
                        <template #title> {{ record.product?.sku }} </template>
                        <p style="font-size: 12px;" class="in1line">{{ record.product?.sku }}</p>
                      </a-tooltip>
                    </template>
                    <template v-if="column.dataIndex === 'box'">
                      <span
                        :style="(record.boxNum == undefined || record.boxNum == '' ? 0 : record.boxNum) > record.quantity ? 'font-size: 16px; color: #FF0000;' : 'font-size: 16px; color: #2a4aea;'">{{
                          record.boxNum == undefined || record.boxNum == '' ? 0 : record.boxNum }}</span>/{{
                          record.quantity }}
                    </template>
                  </template>
                </a-table>
                <a-row>
                  <a-col :span="4" :offset="20">
                    <span class="demo-font"
                      :style="encaseDate.packingQuantity > encaseDate.totalQuantity ? 'color:#FF0000;' : 'color:#2a4aea;'">{{
                        encaseDate.packingQuantity }}</span>/
                    <span class="demo-font" style="color: #000;">{{ encaseDate.totalQuantity }}</span>
                  </a-col>
                </a-row>
              </a-space-compact>
            </a-col>
            <a-col :span="14" style="overflow-x:auto;">
              <td v-for="(box, index) in encaseDate.box" :key="index">
                <a-table :columns="boxcolums" :data-source="box.items" :pagination="false" bordered class="boxclass"
                  style="line-height: 1;" size="small">
                  <template #headerCell="{ column }">
                    <template v-if="column.dataIndex === 'box'">
                      <span>箱子{{ index + 1 }}</span>
                    </template>
                  </template>
                  <template #bodyCell="{ record, column }">
                    <template v-if="column.dataIndex === 'box'">
                      <a-input v-model:value="record.boxNum" style="height: 29.55px;" class="demo-input"
                        :defaultValue="0" @change="inputBoxNum" />
                    </template>
                  </template>
                </a-table>
                <label style="display: inline-block;min-width: 50px;">重(kg):</label><a-input class="demo-input"
                  v-model:value="box.weight" :defaultValue="0" @change="inputBoxNum" />
                <br />
                <label style="display: inline-block;min-width: 50px;">长(cm):</label><a-input class="demo-input"
                  v-model:value="box.length" :defaultValue="0" />
                <br />
                <label style="display: inline-block;min-width: 50px;">宽(cm):</label><a-input class="demo-input"
                  v-model:value="box.width" :defaultValue="0" />
                <br />
                <label style="display: inline-block;min-width: 50px;">高(cm):</label><a-input class="demo-input"
                  v-model:value="box.height" :defaultValue="0" />
              </td>
            </a-col>
          </a-row>
        </a-page-header>
      </div>
    </a-form>
    <template #footer>
      <a-button @click="cancelEncaseModal">取消</a-button>
      <a-popconfirm title="确定装箱？" @confirm="okEncaseModal" @cancel="cancel">
        <a-button type="primary">装箱</a-button>
      </a-popconfirm>
    </template>
  </a-modal>
  <a-modal :width="1300" v-model:visible="productlabelModal" @ok="okPrint" @cancel="cancelPrint" ok-text="打印"
    cancel-text="取消" centered :confirm-loading="productlabelLoading"
    :cancel-button-props="{ disabled: productlabelLoading }" :maskClosable="false" :closable="!productlabelLoading">
    <div :margin-bottom="32">
      <span>标签规格：默认60*30</span>
    </div>
    <a-table :columns="productLabelcolumns" bordered :data-source="printLabelData" :loading="productlabelLoading"
      :scroll="{ x: productLabelcolumnswidth, y: 600 }" row-key="_id"
      :row-selection="{ selectedRowKeys: productLabelRowKeys, onChange: onSelectChange }" size="small">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.product?.cover || ''" width="80px" height="80px"
            style="object-fit: contain;height:100%;" :preview="!!record.cover">
            <template #placeholder>
              <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'msku'">
          {{ record.msku }}<br />
          {{ record.fnsku }}
        </template>
        <template v-if="column.dataIndex === 'name'">
          <a-tooltip placement="topLeft">
            <template #title> {{ record.name }} </template>
            <p class="in1line">{{ record.name }}</p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'sku'">
          {{ record.product?.sku || "" }}<br />
          {{ record.product?.name_cn || "" }}
        </template>
        <template v-if="column.dataIndex === 'printnum'">
          <a-input v-model:value="record.printnum" style="text-align: center;"></a-input>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal :width="1300" title="生成发货" v-model:visible="generateShipmentModal" centered
    :confirm-loading="generateShipmentLoading" :cancel-button-props="{ disabled: generateShipmentLoading }"
    :maskClosable="false" :closable="!generateShipmentLoading">
    <a-form ref="generateShipmentDataRef" name="generateShipmentData" :model="generateShipmentData">
      <div>
        <a-page-header title="基本信息" class="site-page-header">
          <a-descriptions size="small" :column="3">
            <a-descriptions-item label="店铺">
              <span class="sp">{{ generateShipmentData.shop?.name }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="货件单号">
              <span class="sp">{{ generateShipmentData.shipmentId }}</span>
            </a-descriptions-item>
            <a-descriptions-item>
              <template v-slot:label>
                <span style='color: red'>*&nbsp;</span><span>发货仓库</span>
              </template>
              <a-select v-model:value="generateShipmentData.warehouseid" :options="warehouses" placeholder="发货仓库"
                show-search :filter-option="$filters.filterOption" style="width: 150px"
                dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" />
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions size="small" :column="3">
            <a-descriptions-item label="物流方式">
              <a-select v-model:value="generateShipmentData.logistics" :options="logisticsData" placeholder="物流方式"
                show-search :filter-option="$filters.filterOption" style="width: 150px"
                dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" />
            </a-descriptions-item>
            <a-descriptions-item label="物流渠道">
              <a-select v-model:value="generateShipmentData.channel" :options="logisticsData" placeholder="物流渠道"
                show-search :filter-option="$filters.filterOption" style="width: 150px"
                dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" />
            </a-descriptions-item>
            <a-descriptions-item label="分摊方式">
              <a-select v-model:value="generateShipmentData.allocationWay" :options="allocationWay" placeholder="分摊方式"
                show-search :filter-option="$filters.filterOption" style="width: 150px"
                dropdownClassName="drop-down-Style" :dropdownMatchSelectWidth="false" />
            </a-descriptions-item>
          </a-descriptions>
        </a-page-header>
        <a-page-header title="物流信息" class="site-page-header">
          <div v-for="(track, index) in generateShipmentData.track" :key="track.id">
            <a-descriptions size="small" :column="4">
              <a-descriptions-item label="跟踪号">
                <a-form-item :name="['track', index, 'tracking_number']">
                  <a-input :disabled="track.tracking_number !== undefined && track.tracking_number !== ''"
                    v-model:value="track.tracking_number" style="width: 150px"></a-input>
                </a-form-item>
              </a-descriptions-item>
              <a-descriptions-item label="物流费用">
                <a-form-item :name="['track', index, 'logistics_cost']">
                  <a-input v-model:value="track.logistics_cost" style="width: 150px"></a-input>&nbsp;CNY
                </a-form-item>
              </a-descriptions-item>
              <a-descriptions-item label="出货重量">
                <a-form-item :name="['track', index, 'weight']">
                  <a-input :disabled="track.weight !== undefined && track.weight !== ''" v-model:value="track.weight"
                    style="width: 150px"></a-input>&nbsp;Kg
                </a-form-item>
              </a-descriptions-item>
              <a-descriptions-item>
                <a-space>
                  <MinusCircleOutlined
                    v-if="(track.tracking_number === undefined || track.tracking_number === '') && index !== 0"
                    @click="deleteEdu(track)" />
                  <PlusCircleOutlined v-if="index === generateShipmentData.track.length - 1" @click="addEduView()" />
                </a-space>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-page-header>
      </div>
      <a-table :columns="generateShipmentcolums" bordered :data-source="generateShipmentData.items"
        :loading="generateShipmentLoading" :scroll="{ x: productLabelcolumnswidth, y: 600 }" size="small">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'shipmentnum'">
            <span style="color: red">*&nbsp;</span><span>发货量</span>
          </template>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'cover'">
            <a-image :src="record.product?.cover || ''" width="80px" height="80px"
              style="object-fit: contain;height:100%;" :preview="!!record.cover">
              <template #placeholder>
                <a-image :src="image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </template>
          <template v-if="column.dataIndex === 'msku'">
            {{ record.msku }}<br />
            {{ record.fnsku }}
          </template>
          <template v-if="column.dataIndex === 'sku'">
            {{ record.product?.sku || "" }}<br />
            {{ record.product?.name_cn || "" }}
          </template>
          <template v-if="column.dataIndex === 'shipmentnum'">
            <a-input v-model:value="record.shipmentnum" style="text-align: center;" />
          </template>
        </template>
      </a-table>
    </a-form>
    <template #footer>
      <a-button @click="cancelShipment">取消</a-button>
      <a-popconfirm title="确认发货？" @confirm="okShipment" @cancel="cancel">
        <a-button type="primary">发货</a-button>
      </a-popconfirm>
    </template>
  </a-modal>
  <a-modal :width="800" title="打印外箱标签" v-model:visible="printBoxLabelModal" centered @ok="printBoxLabel"
    @cancel="cancelprintBoxLabel" :confirm-loading="printBoxLoading"
    :cancel-button-props="{ disabled: printBoxLoading }" :maskClosable="false" :closable="!printBoxLoading">
    <a-form ref="printBoxDataRef" name="printBoxData" :model="printBoxData">
      <a-page-header class="site-page-header">
        <a-descriptions size="small" :column="2">
          <a-descriptions-item label="货件单号">
            <span class="sp">{{ printBoxData.shipmentId }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="纸张类型">
            <a-select v-model:value="printBoxData.paperType" :options="paperType" show-search
              :filter-option="$filters.filterOption" style="width: 150px" dropdownClassName="drop-down-Style"
              :dropdownMatchSelectWidth="false" />
          </a-descriptions-item>
          <!-- <a-descriptions-item label="打印数量">
              <a-input v-model:value="printBoxData.box_num" style="text-align: center;" :defaultValue="1"></a-input>
            </a-descriptions-item> -->
        </a-descriptions>
      </a-page-header>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, QuestionCircleFilled, MinusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import Boxchild from "./boxchild";
import { printUrl, printBlob, download } from "../../../zhengbo";
import utils from "../../../utils";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columnsf = [
  { title: '货件编号', dataIndex: 'shipmentId', width: 150, align: 'center', },
  { title: '创建人', dataIndex: 'create_by', width: 100, align: 'center', },
  { title: '店铺', dataIndex: 'shop', width: 150, align: 'center', },
  { title: '商品总重量(kg)', dataIndex: 'weight', width: 150, align: 'center', },
  { title: '箱子数量', dataIndex: 'box_num', width: 150, align: 'center', },
  { title: '物流信息', dataIndex: 'logisticsinfo', width: 150, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '备注', dataIndex: 'mark', width: 100, align: 'center', },
  { title: '创建时间', dataIndex: 'create_time', width: 150, align: 'center', },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right', align: 'center', },
];
const columns = [
  { title: '补货单号', dataIndex: 'replen_no', width: 150, align: 'center', },
  { title: '图片', dataIndex: 'cover', width: 80, align: 'center', },
  { title: 'ASIN/FNSKU', dataIndex: 'ASIN', width: 150, align: 'center', },
  { title: '商品信息', dataIndex: 'info', width: 150, align: 'center', },
  { title: '价格', dataIndex: 'price', width: 150, align: 'center', },
  { title: '店铺/国家', dataIndex: 'shop_country', width: 150, align: 'center', },
  { title: '发货仓库', dataIndex: 'inventory', width: 150, align: 'center', },
  { title: '7｜14｜30｜90天销量', dataIndex: 'sales', width: 200, align: 'center', },
  { title: '日均销量', dataIndex: 'date_sale', width: 150, align: 'center', },
  { title: 'FBA库存', dataIndex: 'FBA_store', width: 150, align: 'center', },
  { title: '计划采购量', dataIndex: 'quantity', width: 150, align: 'center', },
  { title: '物流方式', dataIndex: 'logistics', width: 100, align: 'center', },
  { title: '包装类型', dataIndex: 'packag_type', width: 100, align: 'center', },
  { title: '采购单号/采购量', dataIndex: 'buyorderID', width: 150, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '备注', dataIndex: 'mark', width: 150, align: 'center', }
];
const packageColumns = [
  { title: '图片', dataIndex: 'cover', width: 80, align: 'center', },
  { title: 'MSKU/FNSKU', dataIndex: 'asin', width: 150, align: 'center', },
  { title: '品名/SKU', dataIndex: 'sku', width: 150, align: 'center', },
  { title: '装箱数量/申报量', dataIndex: 'box', width: 150, align: 'center', },
];
const productLabelcolumns = [
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center', },
  { title: 'MSKU/FNSKU', dataIndex: 'msku', width: 200, align: 'center', },
  { title: '标题', dataIndex: 'name', width: 200, align: 'center', },
  { title: 'SKU/品名', dataIndex: 'sku', width: 200, align: 'center', },
  { title: '申报量', dataIndex: 'quantity', width: 100, align: 'center', },
  { title: '打印数量', dataIndex: 'printnum', width: 100, align: 'center', },
];
const generateShipmentcolums = [
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center', },
  { title: 'MSKU/FNSKU', dataIndex: 'msku', width: 200, align: 'center', },
  { title: 'SKU/品名', dataIndex: 'sku', width: 200, align: 'center', },
  { title: '申报量', dataIndex: 'quantity', width: 100, align: 'center', },
  { title: '发货量', dataIndex: 'shipmentnum', width: 100, align: 'center', },
];
const boxcolums = [
  { title: '箱子', dataIndex: 'box', width: 80, align: 'center', },
];
export default defineComponent({
  name: 'InventoryDeliverFBA',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    QuestionCircleFilled,
    Boxchild,
    MinusCircleOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const encaseDateRef = ref();
    const generateShipmentDataRef = ref();
    const state = reactive({
      warehouses: [],
      warehouseid: "",
      searchText: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      inventorystate: "1",
      stayEncaseNum: 0,
      stayDeliNum: 0,
      shippedNum: 0,
      completedNum: 0,
      shops: [],
      shopid: [],
      encaseModal: false,
      encaseLoading: false,
      encaseDate: {},
      shipmentTypes: [
        { label: "AMZ承运人小包(SPD)", value: "SPD", },
        { label: "非AMZ承运人小包(SPD)", value: "nSPD" },
        { label: "AMZ承运人货载(LTL)", value: "LTL" },
        { label: "非AMZ承运人货载(LTL)", value: "nLTL" }
      ],
      carrierNames: [
        { label: "DHL_EXPRESS_USA_INC", value: "DHL_EXPRESS_USA_INC" },
        { label: "FEGERAL_EXPRESS_CORP", value: "FEGERAL_EXPRESS_CORP" },
        { label: "UNITED_STATES_POSTAL_SERVICE", value: "UNITED_STATES_POSTAL_SERVICE" },
        { label: "UNITED_PARCEL_SERVICE_INC", value: "UNITED_PARCEL_SERVICE_INC" },
        { label: "OTHER", value: "OTHER" }
      ],
      logisticsData: [
        { label: "港通-海运-美森限时达", value: "港通-海运-美森限时达" },
        { label: "港通-空运-香港DHL", value: "港通-空运-香港DHL" },
        { label: "港通-空运-大陆UPS", value: "港通-空运-大陆UPS" }
      ],
      allocationWay: [
        { label: "重量", value: "重量" },
        { label: "体积重", value: "体积重" },
        { label: "发货数量", value: "发货数量" },
        { label: "按msku头程运费更新", value: "按msku头程运费更新" },
        { label: "按最大值", value: "按最大值" },
        { label: "按申报金额", value: "按申报金额" },
        { label: "按发货批次成本", value: "按发货批次成本" },
        { label: "按箱重", value: "按箱重" },
      ],
      paperType: [
        { label: "每张美国信纸上打印1个标签", value: "PackageLabel_Plain_Paper" },
        { label: "每张美国信纸上打印2个标签", value: "PackageLabel_Letter_2" },
        { label: "每张美国信纸上打印4个标签", value: "PackageLabel_Letter_4" },
        { label: "每张美国信纸上打印6个标签", value: "PackageLabel_Letter_6" },
        { label: "每张美国信纸上打印6个标签(承运人在左)", value: "PackageLabel_Letter_6_CarrierLeft" },//
        { label: "每张A4纸上打印2个标签", value: "PackageLabel_A4_2" },
        { label: "每张A4纸上打印4个标签", value: "PackageLabel_A4_4" },
      ],
      formState: {},
      boxs: [],
      productlabelModal: false,
      productlabelLoading: false,
      productLabelRowKeys: [],
      printLabelData: [],
      generateShipmentModal: false,
      generateShipmentLoading: false,
      generateShipmentData: {},
      printBoxLabelModal: false,
      printBoxData: {},
      printBoxLoading: false,
      deliveryPlanParam: {},
    });
    const width = computed(() => proxy.$utils.twidth(columnsf));
    const iwidth = computed(() => proxy.$utils.twidth(columns));
    const packageColumnswidth = computed(() => proxy.$utils.twidth(packageColumns));
    const productLabelcolumnswidth = computed(() => proxy.$utils.twidth(productLabelcolumns));
    const generateShipmentcolumswidth = computed(() => proxy.$utils.twidth(generateShipmentcolums));
    const boxcolumswidth = computed(() => proxy.$utils.twidth(boxcolums));
    onMounted(() => {
    });
    onActivated(() => {
      getShops();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const getShops = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/shops", { platform: "" }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/fba/FBAPackages", {
        page: state.page,
        limit: state.limit,
        shopid: state.shopid,
        status: state.inventorystate,
        searchText: state.skus,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.warehouses = res.data.warehouses;
          state.length = res.data.length;
          state.count = res.data.count;
          state.stayEncaseNum = res.data.stayEncaseNum;
          state.stayDeliNum = res.data.stayDeliNum;
          state.shippedNum = res.data.shippedNum;
          state.completedNum = res.data.completedNum;
          state.data = res.data.list;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.dataLoading = false;
      });
    };
    const changeShop = (shopid) => {
      state.shopid = shopid;
      getData();
    }
    const changeInventorystate = (item) => {
      state.inventorystate = item;
      getData();
    };
    const encase = (data) => {
      if (!data.shipmentId) return message.warn("未货件编号信息");
      state.encaseModal = true;
      state.encaseDate = _.pick(data, "shop", "_id", "shipmentId", "shipment_name", "shopid", "packag_type", "label_type", "create_by", "create_time", "fulfillmentCenterId", "source", "ship_from_address", "ship_to_address");
      let items = [];
      let boxs = [];
      for (let i = 0; i < data.items.length; i++) {
        let { asin, fbabatchid, fnsku, msku, _id, name, packag_type, parent_sku, sku, warehouse, product, quantity, warehouseid } = _.pick(data.items[i], "asin", "fbabatchid", "fnsku", "msku", "_id", "name", "packag_type", "parent_sku", "sku", "warehouse", "product", "quantity", "warehouseid");
        items.push({ asin, fbabatchid, fnsku, msku, _id, name, packag_type, parent_sku, sku, warehouse, product, quantity, warehouseid });
        boxs.push({ _id: data.items[i]._id, sku: msku });
      }
      state.encaseDate.box = [{
        boxId: '1',
        items: boxs,
      }];
      state.encaseDate.items = items;
      state.encaseDate.totalQuantity = _.reduce(items, function (a, b) { return a + b.quantity; }, 0);
      state.encaseDate.packingQuantity = 0;
      state.encaseDate.boxNum = 1;
    };
    const inputBoxNum = () => {
      for (let i = 0; i < state.encaseDate.items.length; i++) {
        let { _id } = state.encaseDate.items[i];
        let boxNums = 0;
        let weights = 0;
        for (let j = 0; j < state.encaseDate.box.length; j++) {
          let { boxId, items, weight } = state.encaseDate.box[j];
          let item = _.findWhere(items, { _id });
          boxNums = boxNums + Number(item.boxNum == undefined ? 0 : item.boxNum);
          weights = Number(Number(weights) + Number(weight == undefined ? 0 : weight)).toFixed(2);
        }
        state.encaseDate.items[i].boxNum = boxNums;
        state.encaseDate.weight = weights;
      }

      state.encaseDate.packingQuantity = _.reduce(state.encaseDate.items, function (a, b) { return a + Number(b.boxNum || 0); }, 0);
    };
    const addBox = () => {
      if (state.encaseDate.boxNum > 1) {
        let list = [];
        for (let i = 0; i < state.encaseDate.boxNum; i++) {
          let items = [];
          for (let j = 0; j < state.encaseDate.items.length; j++) {
            delete state.encaseDate.items[j].boxNum;
            let { _id, msku } = state.encaseDate.items[j];
            items.push({ _id, sku: msku });
          }
          list.push({ boxId: i + 1 + '', items });
        }
        state.encaseDate.box = list;
        state.encaseDate.packingQuantity = 0;
      } else {
        let items = [];
        for (let i = 0; i < state.encaseDate.items.length; i++) {
          delete state.encaseDate.items[i].boxNum;
          let { _id, msku } = state.encaseDate.items[i];
          items.push({ _id, sku: msku });
        }
        state.encaseDate.box = [{ boxId: '1', items }];
        state.encaseDate.packingQuantity = 0;
      }
    };
    const okEncaseModal = () => {
      if (state.encaseDate.shipment_type == undefined || state.encaseDate.shipment_type == '') return message.warn("请选择货件类型");
      if (state.encaseDate.carrier_name == undefined || state.encaseDate.carrier_name == '') return message.warn("请选择承运人");
      if (state.encaseDate.packingQuantity !== state.encaseDate.totalQuantity) return message.warn("装箱数量和申报量不一致");
      let size = _.filter(state.encaseDate.box, n =>
        !utils.isDecimal(n.weight) || utils.d(n.weight).lte(0) || n.weight == undefined ||
        !utils.isDecimal(n.length) || utils.d(n.length).lte(0) || n.length == undefined ||
        !utils.isDecimal(n.width) || utils.d(n.width).lte(0) || n.width == undefined ||
        !utils.isDecimal(n.height) || utils.d(n.height).lte(0) || n.height == undefined
      ).length;
      if (size > 0) return message.warn("请填写有关箱子信息");
      if (state.encaseDate.shipment_type === 'nSPD') {
        if (state.encaseDate.trackings == '' || state.encaseDate.trackings == undefined) return message.warn("请填写物流单号信息");
        let trackingIds = [];
        let trackings = state.encaseDate.trackings.split('\n').filter(i => i && i.trim());
        if (trackings.length !== Number(state.encaseDate.boxNum)) return message.warn("箱子号和物流单号不匹配");
        for (let i = 0; i < trackings.length; i++) {
          let trackings_boxId = trackings[i].split(',')[0];
          let trackings_track = trackings[i].split(',')[1];
          let box = _.findWhere(state.encaseDate.box, { boxId: trackings_boxId });
          if (box == undefined) return message.warn(`没有箱子${trackings_boxId}`);
          box.trackNumber = trackings_track;
          trackingIds.push({ TrackingId: trackings_track });
        }
        state.encaseDate.trackingIds = trackingIds;
        state.encaseDate.transportDetail = "PartneredSmallParcelData",
          state.encaseDate.isPartnered = true;
      }
      if (state.encaseDate.shipment_type === 'nLTL') {
        if (state.encaseDate.pro_number == '' || state.encaseDate.pro_number == undefined) return message.warn("请填写PRO号");
        state.encaseDate.transportDetail = "NonPartneredLtlData",
          state.encaseDate.isPartnered = false;
      }
      if (state.encaseDate.shipment_type === 'nSPD') state.encaseDate.transportDetail = "NonPartneredSmallParcelData", state.encaseDate.isPartnered = false;
      if (state.encaseDate.shipment_type === 'LTL') state.encaseDate.transportDetail = "PartneredLtlData", state.encaseDate.isPartnered = true;
      state.encaseLoading = true;
      //请求发送入站货物运输信息
      proxy.$api.doAPI("/fba/loadingBox", {
        encaseDate: state.encaseDate,
      }).then((res) => {
        state.encaseLoading = false;
        if (res.code === 0) {
          state.encaseModal = false;
          state.encaseDate = {};
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.encaseLoading = false;
      });
    };
    const cancelEncaseModal = () => {
      state.encaseDate = {};
      state.encaseModal = false;
    };
    const updateInboundShipment = (data, type) => {
      console.log(data);
      state.deliveryPlanParam = _.pick(data, "shop", "_id", "shipmentId", "shopid", "packag_type", "deliver_time", "arrive_time", "mark", "ship_from_address", "fulfillmentCenterId", "label_type", "shipment_name", "source");
      let items = [];
      for (let i = 0; i < data.items.length; i++) {
        items.push(_.pick(data.items[i], "replen_no", "asin", "fbabatchid", "_id", "fnsku", "msku", "name", "packag_type", "parent_sku", "sku", "warehouse", "product", "quantity", "warehouseid", "create_time", "mark"));
      }
      state.deliveryPlanParam.items = items;
      state.deliveryPlanParam.removeProductId = [];
      state.deliveryPlanParam.addProduct = [];
      state.deliveryPlanParam.type = type;
      if (type === 'up') {
        // state.deliveryPlanModal = true;
      } else {
        proxy.$api.doAPI("/fba/updateInbound", {
          data: state.deliveryPlanParam,
        }).then((res) => {
          if (res.code === 0) {
            state.deliveryPlanParam = {},
              getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          console.error(err);
        });
      }
    };
    const bodystyle = {
      height: '800px',
      overflow: 'hidden',
      overflowY: 'scroll',
    }
    const changeWarehouse = (e) => {
      console.log('changeWarehouse', e);
      if (state.page !== 1) state.page = 1;
      else getData();
    };
    const handleBulkClick = ({ key }) => {
      console.log('handleBulkClick', key);
      switch (key) {
        case "1":
          productlabel();
          break;
        case "2":
          boxLabel();
          break;
        case "3":
        case "4":
          break;
      }
    }
    const boxLabel = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("请选择需要打印的商品信息");
      if (state.selectedRowKeys.length > 1) return message.warn("一次只能选择一个");
      let list = _.filter(state.data, n => state.selectedRowKeys.indexOf(n._id) > -1);
      let { _id, shopid, shipmentId, boxs } = list[0];
      if (boxs || boxs.length === 0) return message.warn("未查询到装箱信息");
      let PackageLabelsToPrint = [];
      for (let i = 0; i < boxs.length; i++) {
        const { cartonid } = boxs[i];
        PackageLabelsToPrint.push(cartonid);
      }
      state.printBoxData = {
        _id,
        shopid,
        shipmentId,
        PackageLabelsToPrint
      };
      state.printBoxLabelModal = true;
      state.productLabelRowKeys = [];
    };
    const printBoxLabel = () => {
      console.log(state.printBoxData);
      proxy.$api.doAPI("/fba/printBoxTag", state.printBoxData).then((res) => {
        if (res.code === 0) {
          let url = res.data.url;
          let localpath = res.data.localpath;
          printUrl(url);
          state.printBoxLabelModal = false;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const cancelprintBoxLabel = () => {
      state.productlabelModal = false;
      state.printBoxData = {};
      state.selectedRowKeys = [];
    };
    //打印商品标签
    const productlabel = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("请选择需要打印的商品信息");
      if (state.selectedRowKeys.length > 1) return message.warn("一次只能选择一个");
      let list = _.filter(state.data, n => state.selectedRowKeys.indexOf(n._id) > -1);
      let { _id, items, } = list[0];
      state.printLabelData = _.map(items, n => {
        return {
          _id: n._id,
          asin: n.asin,
          fnsku: n.fnsku,
          msku: n.msku,
          name: n.name,
          parent_sku: n.parent_sku,
          product: n.product,
          quantity: n.quantity,
          seller_sku: n.seller_sku,
          printnum: n.quantity,
          condition: n.condition,
        }
      });
      state.productlabelModal = true;
      state.productLabelRowKeys = [];
    };
    const onSelectChange = (selectedRowKeys) => {
      console.log('productLabelRowKeys changed: ', selectedRowKeys);
      state.productLabelRowKeys = selectedRowKeys;
    };
    const okPrint = async () => {
      if (state.productLabelRowKeys.length === 0) return message.warn("未选择需要打印的商品");
      let list = [];
      if (state.productLabelRowKeys.length === state.printLabelData.length) {
        list = state.printLabelData;
      } else {
        list = _.filter(state.printLabelData, n => state.productLabelRowKeys.indexOf(n._id) > -1);
      }
      //请求打印API
      await proxy.$api.doAPI("/fba/printTag", { list, type: 'FBA' }).then((res) => {
        if (res.code === 0) {
          printUrl(res.data.url);
          state.productlabelModal = false;
          state.productLabelRowKeys = [];
          state.selectedRowKeys = [];
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const cancelPrint = () => {
      state.productlabelModal = false;
      state.productLabelRowKeys = [];
      state.printLabelData = [];
      state.selectedRowKeys = [];
    };
    //生成发货
    const generateShipment = () => {
      if (state.selectedRowKeys.length === 0) return message.warn("请选择需要发货的商品信息");
      if (state.selectedRowKeys.length > 1) return message.warn("目前一次只能选择一个");
      let list = _.filter(state.data, n => state.selectedRowKeys.indexOf(n._id) > -1);
      let { _id, shop, shopid, orgid, label_type, batchNo, shipmentId, shipment_name, shipment,
        items, warehouseid, boxs, tracking_number, ship_from_address, fulfillmentCenterId, label_value } = list[0];
      items = _.map(items, n => {
        return {
          ...n,
          shipmentnum: n.quantity,
        }
      });
      state.generateShipmentData = { _id, orgid, shopid, shop, label_type, batchNo, shipmentId, shipment_name, shipment, items, warehouseid, allocationWay: "重量", ship_from_address, fulfillmentCenterId, label_value };
      if (boxs.length > 0) {
        let tracks = [];
        for (let i = 0; i < boxs.length; i++) {
          tracks.push(_.pick(boxs[i], "tracking_number", "_id", "weight"));
        }
        state.generateShipmentData.track = tracks;
      } else {
        state.generateShipmentData.track = [{}];
      }
      state.generateShipmentModal = true;
    };
    const addEduView = () => {
      state.generateShipmentData.track.push({
        id: Date.now(),//标识
        tracking_number: undefined,//跟踪号
        logistics_cost: undefined,//物流费用
        weight: undefined//出货重量

      });
    };
    const deleteEdu = (item) => {
      const index = state.generateShipmentData.track.indexOf(item);
      if (index !== -1 && index !== 0) {
        state.generateShipmentData.track.splice(index, 1);
      }
    };
    const canceShipment = e => {
      console.log(e);
      message.warn('取消操作');
    };
    const cancelShipment = () => {
      state.generateShipmentModal = false;
      state.generateShipmentData = {};
    };
    const okShipment = () => {
      if (!state.generateShipmentData.warehouseid) return message.warn("请选择发货仓库");
      let size = _.filter(state.generateShipmentData.items, n => !utils.isDecimal(n.shipmentnum) || utils.d(n.shipmentnum).lte(0)).length;
      if (size > 0) return message.warn("发货量必填");
      for (let i = 0; i < state.generateShipmentData.track.length; i++) {
        if (state.generateShipmentData.track[i] == null ||
          state.generateShipmentData.track[i] == "" ||
          JSON.stringify(state.generateShipmentData.track[i]) == "{}") {
          state.generateShipmentData.track.splice(i, 1);
          i = i - 1;
        }
      }
      state.generateShipmentLoading = true;
      proxy.$api.doAPI("/fba/shipments", state.generateShipmentData).then((res) => {
        if (res.code === 0) {
          state.generateShipmentModal = false;
          state.generateShipmentData = {};
          state.generateShipmentLoading = false;
          getData();
        } else {
          message.error(res.msg);
          state.generateShipmentLoading = false;
        }
      }).catch((err) => {
        console.error(err);
        state.generateShipmentLoading = false;
      });
    };
    const processImportExport = ({ key, }) => {
      console.log('processImportExport', key);
    }
    const addToWarehouse = ({ key, }) => {
      console.log('addToWarehouse', key);
    }
    ////同步到FBA，同时创建了FBA货件
    const syncFBA = ({ key, }) => {
      console.log('syncFBA', key);
    }
    ////生成采购计划
    const makePurchasePlan = ({ key, }) => {
      console.log('makePurchasePlan', key);
    }
    return {
      ...toRefs(state),
      formRef,
      encaseDateRef,
      generateShipmentDataRef,
      columnsf,
      columns,
      packageColumns,
      boxcolums,
      boxcolumswidth,
      width,
      iwidth,
      packageColumnswidth,
      getData,
      changeWarehouse,
      handleBulkClick,
      syncFBA,
      makePurchasePlan,
      processImportExport,
      addToWarehouse,
      image_placeholder: proxy.$constant.image_placeholder,
      changeShop,
      changeInventorystate,
      encase,
      inputBoxNum,
      addBox,
      okEncaseModal,
      cancelEncaseModal,
      bodystyle,
      productLabelcolumns,
      productLabelcolumnswidth,
      onSelectChange,
      okPrint,
      cancelPrint,
      generateShipment,
      generateShipmentcolums,
      generateShipmentcolumswidth,
      canceShipment,
      cancelShipment,
      okShipment,
      addEduView,
      deleteEdu,
      boxLabel,
      updateInboundShipment,
      printBoxLabel,
      cancelprintBoxLabel,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.sp {
  font-size: 15px;
  font-weight: bold
}

.demo-input {
  width: 80px;
  text-align: center;
}

.demo-font {
  font-size: 18px;
}

.drop-down-Style {
  width: auto;
}

.boxclass .ant-table-tbody>tr>td {
  padding: 0px 0px;
  line-height: 1;
}
</style>
