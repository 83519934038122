<template>
  <div class="workbench">
    <div class="ant-row-flex">
      <a-row>
        <a-col :span="5" class="search-card">
          <a-card class="leftcard" title="欢迎使用正店通ERP" :bordered="false"
            style="width: 100%; border-bottom: 1px solid #ddd;">
            <p>企业编号：
              <LoadingOutlined v-if="loading" />
              <template v-else>{{ corp_number }}</template>
            </p>
          </a-card>
          <div class="feature">
            <div class="title">特色功能</div>
            <div class="fitems">
              <div class="fitem">
                <img src="~@/assets/features/xueyuan.png" style="height: 50px;" />
                <p>学员模式</p>
              </div>
              <div class="fitem">
                <img src="~@/assets/features/ad.png" style="height: 45px;" />
                <p>AI广告</p>
              </div>
            </div>
            <div class="fitems">
              <div :span="12" class="fitem">
                <img src="~@/assets/features/jinrong.png" style="height: 58px;" />
                <p>金融服务</p>
              </div>
              <div :span="12" class="fitem">
                <img src="~@/assets/features/wuliu.png" style="height: 50px;" />
                <p>物流服务</p>
              </div>
            </div>
            <div class="title">通知消息</div>
            <div class="notice">
              <a-list>
                <a-list-item>
                  <a-list-item-meta description="智能云广告正式内测">
                    <template #title>
                      <a href="#">正店通ERP广告功能上线啦</a>
                    </template>
                    <template #avatar>
                      <img src="~@/assets/notice.png" style="height: 36px;" />
                    </template>
                  </a-list-item-meta>
                  <div class="time">
                    <p style="font-size: 14px; font-weight: 500;">09-22</p>
                    <p style="font-size: 12px;">10:12:15</p>
                  </div>
                </a-list-item>
              </a-list>
            </div>
          </div>
        </a-col>
        <a-col :span="1" class="search-card">
          <div style="width: 100%; height:100%;"></div>
        </a-col>
        <a-col :span="18" class="search-card">
          <div class="contenting">
            <a-row class="hitems">
              <a-col :span="4" class="hitem">
                <div class="head">
                  <img src="~@/assets/features/dianpu.png" style="height: 58px;" />
                  <p>店铺</p>
                </div>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日活跃</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="dianpu" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ active_shop_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      已授权店铺
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ auth_shop_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">最近新增</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="pingtai" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ new_platform_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      已授权平台
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ auth_platform_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日销售额</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="sales" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ today_sales }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      总销售额
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ total_sales }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
            <a-row class="hitems">
              <a-col :span="4" class="hitem">
                <div class="head">
                  <img src="~@/assets/features/order.png" style="height: 58px;" />
                  <p>订单</p>
                </div>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增订单</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="dingdan" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else :class="{ 'click underline': new_order_count > 0 }"
                        @click="() => { new_order_count > 0 && showorder('today') }">
                        {{ new_order_count }}
                      </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      总订单数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ total_order_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">昨日订单</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="dingdan" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else :class="{ 'click underline': yesterday_order_count > 0 }"
                        @click="() => { yesterday_order_count > 0 && showorder('yesterday') }">
                        {{ yesterday_order_count }}
                      </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      截止昨日总订单数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ yesterday_total_order_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增待发货订单</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else class="text-error"> {{ new_unshipped_order_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      待发货订单
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ unshipped_order_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日发货订单</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ new_shipped_order_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      已发货订单
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ shipped_order_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
            <a-row class="hitems">
              <a-col :span="4" class="hitem">
                <div class="head">
                  <img src="~@/assets/features/product.png" style="height: 58px;" />
                  <p>商品</p>
                </div>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增SKU</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="shangpin" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ sku_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      库存SKU数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ sku_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增组合SKU</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else class="text-error"> {{ combinesku_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      组合SKU
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ combinesku_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日上线产品</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ online_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      在线商品数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ online_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">疑似被跟卖商品</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else :class="{ 'click underline text-error': genmai_count > 0 }"
                        @click="() => { genmai_count > 0 && showgenmailist() }">
                        {{ genmai_count }}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="chart-card-footer">
                    <div class="filed">
                      在线商品数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ online_count }} </span>
                    </div>
                  </div> -->
                </a-card>
              </a-col>
            </a-row>
            <a-row class="hitems">
              <a-col :span="4" class="hitem">
                <div class="head">
                  <img src="~@/assets/features/zhinengcaigou.png" style="height: 58px;" />
                  <p>采购</p>
                </div>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增采购</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="caigou" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ purchase_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      总采购数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ purchase_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增入库</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else class="text-error"> {{ arrival_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      已入库数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ arrival_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增授权</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ ae1688_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      1688授权账户
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ ae1688_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增供应商</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ supplier_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      供应商数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ supplier_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
            <a-row class="hitems">
              <a-col :span="4" class="hitem">
                <div class="head">
                  <img src="~@/assets/features/ads.png" style="height: 58px;" />
                  <p>广告</p>
                </div>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增广告</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <svg-icon iconClass="guanggao" :style="{ 'width': '32px', 'height': '32px' }" />
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_today_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      总广告数
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增进行中</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else class="text-error"> {{ adv_today_enable_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      进行中广告
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_enable_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增暂停</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_today_paused_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      已暂停
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_paused_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
              <a-col :span="5" class="hitem">
                <a-card :bordered="false" style="width: 100%;">
                  <div class="chart-card-header">
                    <div class="meta">
                      <div class="chart-card-title">今日新增超预算</div>
                      <div class="chart-card-action"></div>
                    </div>
                    <div class="total">
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_today_superBudget_count }} </span>
                    </div>
                  </div>
                  <div class="chart-card-footer">
                    <div class="filed">
                      超预算
                      <LoadingOutlined v-if="loading" />
                      <span v-else> {{ adv_superBudget_count }} </span>
                    </div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <a-modal v-model:visible="showUnAuthorizedShops" width="600px" :footer="null">
    <template #title>
      <a-alert message="以下店铺授权异常" type="error"></a-alert>
    </template>
    <a-table bordered :columns="columns" :data-source="unauthorizedshops" :scroll="{ y: 400 }" :pagination="false"
      size="small">
    </a-table>
  </a-modal>
</template>
<script>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useRouter } from "vue-router";
import _ from "underscore";
const columns = [
  { title: "平台", dataIndex: "platform", width: 100, },
  { title: "店铺名称", dataIndex: "name", width: 100, },
]
export default defineComponent({
  components: { LoadingOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      loading: false,
      corp_number: 0,
      auth_shop_count: 0,
      active_shop_count: 0,
      auth_platform_count: 0,
      new_platform_count: 0,
      total_sales: 0,
      today_sales: 0,
      total_order_count: 0,
      yesterday_total_order_count: 0,
      yesterday_order_count: 0,
      new_order_count: 0,
      unshipped_order_count: 0,
      new_unshipped_order_count: 0,
      shipped_order_count: 0,
      new_shipped_order_count: 0,
      sku_count: 0,
      sku_today_count: 0,
      combinesku_count: 0,
      combinesku_today_count: 0,
      online_count: 0,
      online_today_count: 0,
      genmai_count: 0,
      adv_count: 0,
      adv_today_count: 0,
      adv_enable_count: 0,
      adv_today_enable_count: 0,
      adv_paused_count: 0,
      adv_today_paused_count: 0,
      adv_superBudget_count: 0,
      adv_today_superBudget_count: 0,
      purchase_count: 0,
      purchase_today_count: 0,
      arrival_count: 0,
      arrival_today_count: 0,
      ae1688_count: 0,
      ae1688_today_count: 0,
      supplier_count: 0,
      supplier_today_count: 0,
      unauthorizedshops: [],
      showUnAuthorizedShops: false,
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
      getUnauthorizedShops();
    });
    const getUnauthorizedShops = () => {
      proxy.$api.doAPI("/shop/getunauthorized").then((res) => {
        state.unauthorizedshops = res.data.shops;
        state.showUnAuthorizedShops = state.unauthorizedshops.length > 0;
      });
    }
    const getData = () => {
      state.loading = true;
      proxy.$api.doAPI("/statistics/workbench").then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.corp_number = res.data.corp_number;
          state.auth_shop_count = proxy.$utils.formatMoney(res.data.auth_shop_count, 0, '');
          state.active_shop_count = proxy.$utils.formatMoney(res.data.active_shop_count, 0, '');
          state.auth_platform_count = proxy.$utils.formatMoney(res.data.auth_platform_count, 0, '');
          state.new_platform_count = proxy.$utils.formatMoney(res.data.new_platform_count, 0, '');
          state.total_sales = proxy.$utils.formatMoney(res.data.total_sales, 2, '');
          state.today_sales = proxy.$utils.formatMoney(res.data.today_sales, 2, '');
          state.total_order_count = proxy.$utils.formatMoney(res.data.total_order_count, 0, '');
          state.yesterday_order_count = proxy.$utils.formatMoney(res.data.yesterday_order_count, 0, '');
          state.new_order_count = proxy.$utils.formatMoney(res.data.new_order_count, 0, '');
          state.unshipped_order_count = proxy.$utils.formatMoney(res.data.unshipped_order_count, 0, '');
          state.new_unshipped_order_count = proxy.$utils.formatMoney(res.data.new_unshipped_order_count, 0, '');
          state.shipped_order_count = proxy.$utils.formatMoney(res.data.shipped_order_count, 0, '');
          state.new_shipped_order_count = proxy.$utils.formatMoney(res.data.new_shipped_order_count, 0, '');
          state.yesterday_total_order_count = proxy.$utils.formatMoney(proxy.$utils.d(res.data.total_order_count).sub(res.data.new_order_count), 0, '');
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
      proxy.$api.doAPI("/statistics/workbench_product").then((res) => {
        if (res.code === 0) {
          state.sku_count = proxy.$utils.formatMoney(res.data.sku_count, 0, '');
          state.sku_today_count = proxy.$utils.formatMoney(res.data.sku_today_count, 0, '');
          state.combinesku_count = proxy.$utils.formatMoney(res.data.combinesku_count, 0, '');
          state.combinesku_today_count = proxy.$utils.formatMoney(res.data.combinesku_today_count, 0, '');
          state.online_count = proxy.$utils.formatMoney(res.data.online_count, 0, '');
          state.online_today_count = proxy.$utils.formatMoney(res.data.online_today_count, 0, '');
          state.genmai_count = proxy.$utils.formatMoney(res.data.genmai_count, 0, '');
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
      proxy.$api.doAPI("/statistics/workbench_purchase").then((res) => {
        if (res.code === 0) {
          state.purchase_count = proxy.$utils.formatMoney(res.data.purchase_count, 0, '');
          state.purchase_today_count = proxy.$utils.formatMoney(res.data.purchase_today_count, 0, '');
          state.arrival_count = proxy.$utils.formatMoney(res.data.arrival_count, 0, '');
          state.arrival_today_count = proxy.$utils.formatMoney(res.data.arrival_today_count, 0, '');
          state.ae1688_count = proxy.$utils.formatMoney(res.data.ae1688_count, 0, '');
          state.ae1688_today_count = proxy.$utils.formatMoney(res.data.ae1688_today_count, 0, '');
          state.supplier_count = proxy.$utils.formatMoney(res.data.supplier_count, 0, '');
          state.supplier_today_count = proxy.$utils.formatMoney(res.data.supplier_today_count, 0, '');
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
      proxy.$api.doAPI("/statistics/workbench_adv").then((res) => {
        if (res.code === 0) {
          state.adv_count = proxy.$utils.formatMoney(res.data.adv_count, 0, '');
          state.adv_today_count = proxy.$utils.formatMoney(res.data.adv_today_count, 0, '');
          state.adv_enable_count = proxy.$utils.formatMoney(res.data.adv_enable_count, 0, '');
          state.adv_today_enable_count = proxy.$utils.formatMoney(res.data.adv_today_enable_count, 0, '');
          state.adv_paused_count = proxy.$utils.formatMoney(res.data.adv_paused_count, 0, '');
          state.adv_today_paused_count = proxy.$utils.formatMoney(res.data.adv_today_paused_count, 0, '');
          state.adv_superBudget_count = proxy.$utils.formatMoney(res.data.adv_superBudget_count, 0, '');
          state.adv_today_superBudget_count = proxy.$utils.formatMoney(res.data.adv_today_superBudget_count, 0, '');
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const showorder = (value) => {
      localStorage.setItem("order_date_range_mark", value);
      router.push({ path: "/orders/manage/list" }).catch(console.error);
    }
    const showgenmailist = () => {
      router.push({ path: "/product/manage/genmai" }).catch(console.error);
    }
    return {
      ...toRefs(state),
      columns,
      showorder,
      showgenmailist,
    };
  },
});
</script>
<style lang="less" scoped>
.feature {
  .title {
    font-size: 24px;
    font-weight: 600;
    padding: 20px 0;
  }

  .fitems {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }

  .fitem {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
  }
}

.notice {
  .time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.contenting {
  .hitems {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }

  .hitem {
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .hitem:first-child {
    padding-left: 0;
  }

  .hitem:last-child {
    padding-right: 0;
  }

  .meta {
    color: rgba(0, 0, 0, .45);
    font-size: 14px;
    line-height: 22px;
  }

  .total {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    color: #000;
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 38px;
    height: 38px;
  }

  .chart-card-content {
    margin-bottom: 12px;
    position: relative;
    height: 46px;
    width: 100%;

    .content-fix {
      position: absolute;
      left: -25px;
      bottom: 0;
      width: 100%;
      height: 46px;
    }
  }

  .chart-card-footer {
    border-top: 1px solid #f2f2f2;
    padding-top: 9px;
    margin-top: 8px;
  }

  .chart-card-footer .filed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}

.sales-card {
  .sales-card-content {
    display: flex;

    .chart {
      flex: 9;
    }

    .ranking {
      flex: 3;
    }
  }
}

:deep(.ant-card-body) {
  padding: 20px 24px 8px;
}

:deep(.search-card .ant-btn) {
  border: none;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.leftcard .ant-card-body) {
  font-size: 20px;
  padding-left: 0;
  padding-top: 10px;
}

:deep(.ant-card-head) {
  font-size: 26px;
  color: #FF7F02;
  padding: 0;
}

:deep(.ant-card-head-title) {
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>
