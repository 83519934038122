import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import store from "../store";
import api from "../request";
import utils from "../utils";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Cookies from "js-cookie";
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});

import Login from "@/views/login.vue";
import Register from "@/views/register.vue";
import LoginA2J from "@/views/login_an2ji.vue";
import RegisterA2J from "@/views/register_an2ji.vue";
import NotFound from "@/views/404.vue";

//首页
import WorkBench from "@/views/workbench.vue";

//商品管理
// //库存商品
// import product_manage_inventory from "@/views/product/manage/inventory.vue";
//库存SKU
import product_manage_sku from "@/views/product/manage/sku.vue";
//组合SKU
import product_manage_skus from "@/views/product/manage/skus.vue";
//商品配对
import product_manage_pairs from "@/views/product/manage/pairs.vue";
//跟卖提醒
import product_manage_genmai from "@/views/product/manage/genmai.vue";
//基础数据管理
//商品分类
import product_basic_categorys from "@/views/product/basic/categorys.vue";
//商品属性
import product_basic_properties from "@/views/product/basic/properties.vue";
//商品品牌
import product_basic_brands from "@/views/product/basic/brands.vue";

//订单管理
//全部订单
import orders_manage_list from "@/views/orders/manage/list.setup.vue";
//待处理
import orders_manage_pending from "@/views/orders/manage/pending.setup.vue";
//配货中
import orders_manage_distribution from "@/views/orders/manage/distribution.setup.vue";
//已发货
import orders_manage_finished from "@/views/orders/manage/finished.setup.vue";
//已作废
import orders_manage_cancelled from "@/views/orders/manage/cancelled.setup.vue";
//订单出库
import orders_manage_outstock from "@/views/orders/manage/outstock.vue";
//手工录单
import orders_manage_manualrecord from "@/views/orders/manage/manualrecord.vue";
//淘宝商品
import orders_manage_tblist from "@/views/orders/manage/tblist.vue";
//其他操作
//补录单号
import orders_tools_supplementrecord from "@/views/orders/tools/supplementrecord.vue";

//库存管理
//库存查看
import inventory_manage_inventoryview from "@/views/inventory/manage/inventoryview.vue";
//库存盘点
import inventory_manage_check from "@/views/inventory/manage/check.vue";
//库龄查看
import inventory_manage_inventoryage from "@/views/inventory/manage/inventoryage.vue";
//缺货商品
import inventory_manage_stockout from "@/views/inventory/manage/stockout.vue";
//发货管理
//智能补货单
import inventory_deliver_replenishment from "@/views/inventory/deliver/replenishment.vue";
//发货计划
import inventory_deliver_plan from "@/views/inventory/deliver/plan.vue";
//FBA货件
import inventory_deliver_FBA from "@/views/inventory/deliver/FBA.vue";

import inventory_deliver_fbatrack from "@/views/inventory/deliver/fbatrack.vue";
//海外仓发货
import inventory_deliver_oversea from "@/views/inventory/deliver/oversea.vue";

//仓库管理
//仓库列表
import inventory_warehouse_list from "@/views/inventory/warehouse/list.vue";
//海外仓
import inventory_warehouse_oversea from "@/views/inventory/warehouse/oversea.vue";

//物流渠道
//物流商授权
import logistics_supplier_authorize from "@/views/logistics/supplier/authorize.vue";
//物流订单统计
import logistics_supplier_orderstatics from "@/views/logistics/supplier/orderstatics.vue";
//物流追踪
import logistics_supplier_track from "@/views/logistics/supplier/track.vue";
//物流运费明细
import logistics_supplier_fee from "@/views/logistics/supplier/fee.vue";

//采购管理
//采购计划
import purchase_manage_plan from "@/views/purchase/manage/plan.vue";
//采购单管理
import purchase_manage_order from "@/views/purchase/manage/order.vue";
//采购单入库
import purchase_manage_instock from "@/views/purchase/manage/instock.vue";
//订单缺货匹配
import purchase_manage_ordermatch from "@/views/purchase/manage/ordermatch.vue";
//采购统计
import purchase_manage_statics from "@/views/purchase/manage/statics.vue";
//供应商管理
import purchase_supplier_list from "@/views/purchase/supplier/list.vue";
//1688授权管理
import purchase_supplier_1688 from "@/views/purchase/supplier/1688.vue";

//财务管理
//收支明细
import operation_sale_income from "@/views/operation/sale/income.vue";
//收支报表
import operation_sale_incomereport from "@/views/operation/sale/incomereport.vue";
//订单状态报表
import operation_sale_orderstate from "@/views/operation/sale/orderstate.vue";
//利润报表
import operation_finance_profit from "@/views/operation/finance/profit.vue";
//数据分析
//产品表现
import operation_analysis_sales from "@/views/operation/analysis/sales.vue";
//店铺表现
import operation_analysis_shops from "@/views/operation/analysis/shops.vue";
//经营看板
import operation_analysis_opdata from "@/views/operation/analysis/opdata.vue";
//第三方收款
//账号管理
import operation_payee_account from "@/views/operation/payee/account.vue";
//交易明细
import operation_payee_list from "@/views/operation/payee/list.vue";
//供应链金融
//供应链金融
import operation_supplychain_finance from "@/views/operation/supplychain/finance.vue";
//客服管理
//客户管理
import customerservice_customers from "@/views/customerservice/customer/customers.vue";
//客户消息管理
import customerservice_replymessage from "@/views/customerservice/customer/replymessage.vue";
//消息回复模板
import customerservice_replytemplate from "@/views/customerservice/customer/replytemplate.vue";
//Walmart平台
//买家消息
import customerservice_WalmartBuyerMsg from "@/views/customerservice/walmart/buyermessage.vue";
//平台消息
import customerservice_WalmartSysMsg from "@/views/customerservice/walmart/sysmessage.vue";

//店铺管理
//店铺授权
import shops_manage_authorize from "@/views/shops/manage/authorize.vue";
//店铺权限设置
import shops_manage_power from "@/views/shops/manage/power.vue";
//产品采集
import shops_published_gather from "@/views/shops/published/gather.vue";
import shops_published_gatherform from "@/views/shops/published/gatherform.vue";
//产品库
import shops_published_product from "@/views/shops/published/product.vue";
//亚马逊多站点刊登
import shops_published_amazon_publishregion from "@/views/shops/published/amazon_publishregion.vue";
import shops_published_amazon_publishformregion from "@/views/dev/amazon/listingformregion.vue";
//速卖通刊登
import shops_published_aliexpress_publish from "@/views/shops/published/aliexpress_publish.vue";
//图片库
import shops_published_pictures from "@/views/shops/published/pictures.vue";
//侵权词库
import shops_published_tortwords from "@/views/shops/published/tortwords.vue";
//UPC管理
import shops_published_upc from "@/views/shops/published/upc.vue";
//商标检索
import shops_published_tmsearch from "@/views/shops/published/tmsearch.vue";
//专利检索
import shops_published_uspto from "@/views/shops/published/uspto.vue";
//Amazon搜索词报告
import shops_published_searchterm from "@/views/shops/published/searchterm.vue";
//Amazon销售排名
import shops_published_bestsellers from "@/views/shops/published/best_sellers.vue";
//Amazon搜索词报表
import shops_published_searchtermreport from "@/views/shops/published/searchtermreport.vue";

//广告管理
//多店铺广告预览
import shops_advertising_adPreview from "@/views/shops/advertising/adPreview.vue";
//广告列表
import shops_advertising_list from "@/views/shops/advertising/list.vue";
//广告-关键词
import shops_advertising_keyword from "@/views/shops/advertising/keyword.vue";
//广告-sku
import shops_advertising_sku from "@/views/shops/advertising/sku.vue";
//竞品采集
import shops_advertising_competitors_collect from "@/views/shops/advertising/competitors_collect.vue";
import shops_advertising_competitors_form from "@/views/shops/advertising/competitors_form.vue";
//广告-分析
// import shops_advertising_analyze from "@/views/shops/advertising/analyze.vue";

//账户设置
//员工列表
import accountsetting_accounts_list from "@/views/accountsetting/accounts/list.vue";
//角色设置
import accountsetting_accounts_roles from "@/views/accountsetting/accounts/roles.vue";
//权限设置
import accountsetting_accounts_permissions from "@/views/accountsetting/accounts/permissions.vue";
//学员管理
//学员账号
import accountsetting_trainee_list from "@/views/accountsetting/trainee/list.vue";
//学员权限
import accountsetting_trainee_power from "@/views/accountsetting/trainee/power.vue";
//企业信息
import accountsetting_org_info from "@/views/accountsetting/org/info.vue";

import dev_amazon_feeds from "@/views/dev/amazon/feeds.vue";
import dev_amazon_reports from "@/views/dev/amazon/reports.vue";
import dev_amazon_listings from "@/views/dev/amazon/listings.vue";
import dev_amazon_shipping from "@/views/dev/amazon/shipping.vue";
import dev_amazon_browsetree from "@/views/dev/amazon/browsetree.vue";
import dev_amazon_listingformregion from "@/views/dev/amazon/listingformregion.vue";
import dev_amazon_clawler from "@/views/dev/amazon/clawler.vue";
import dev_shopee_media from "@/views/dev/shopee/media.vue";
import dev_shopee_listingform from "@/views/dev/shopee/listingform.vue";
import dev_tools from "@/views/dev/tools/index.vue";
import dev_ai_generator from "@/views/dev/ai/generator.vue";

const routes = [
  {
    name: "index",
    path: "/",
    meta: { layout: "empty-layout", requireAuth: false },
  },
  //首页
  {
    name: "workbench",
    path: "/workbench",
    component: WorkBench,
    meta: { layout: "basic-layout" },
  },
  //商品管理
  {
    name: "product",
    path: "/product",
    redirect: "/product/manage/sku",
    meta: { layout: "basic-layout" },
  },
  {
    name: "product_manage",
    path: "/product/manage",
    redirect: "/product/manage/sku",
    meta: { layout: "basic-layout" },
  },
  // //库存商品
  // {
  //   name: 'product_manage_inventory',
  //   path: "/product/manage/inventory",
  //   component: product_manage_inventory,
  //   meta: { layout: "basic-layout" },
  // },
  //库存SKU
  {
    name: "product_manage_sku",
    path: "/product/manage/sku",
    component: product_manage_sku,
    meta: { layout: "basic-layout" },
  },
  //组合SKU
  {
    name: "product_manage_skus",
    path: "/product/manage/skus",
    component: product_manage_skus,
    meta: { layout: "basic-layout" },
  },
  //在线商品匹配
  {
    name: "product_manage_pairs",
    path: "/product/manage/pairs",
    component: product_manage_pairs,
    meta: { layout: "basic-layout" },
  },
  //跟卖提醒
  {
    name: 'product_manage_genmai',
    path: "/product/manage/genmai",
    component: product_manage_genmai,
    meta: { layout: "basic-layout" },
  },
  //基础数据管理
  {
    name: "product_basic",
    path: "/product/basic",
    redirect: "/product/basic/categorys",
    meta: { layout: "basic-layout" },
  },
  //商品分类
  {
    name: "product_basic_categorys",
    path: "/product/basic/categorys",
    component: product_basic_categorys,
    meta: { layout: "basic-layout" },
  },
  //商品属性
  {
    name: "product_basic_properties",
    path: "/product/basic/properties",
    component: product_basic_properties,
    meta: { layout: "basic-layout" },
  },
  //商品品牌
  {
    name: "product_basic_brands",
    path: "/product/basic/brands",
    component: product_basic_brands,
    meta: { layout: "basic-layout" },
  },
  //订单管理
  {
    name: "orders",
    path: "/orders",
    redirect: "/orders/manage/pending",
    meta: { layout: "basic-layout" },
  },
  {
    name: "orders_manage",
    path: "/orders/manage",
    redirect: "/orders/manage/pending",
    meta: { layout: "basic-layout" },
  },
  //全部订单
  {
    name: "orders_manage_list",
    path: "/orders/manage/list",
    component: orders_manage_list,
    meta: { layout: "basic-layout" },
  },
  //待处理
  {
    name: "orders_manage_pending",
    path: "/orders/manage/pending",
    component: orders_manage_pending,
    meta: { layout: "basic-layout" },
  },
  //配货中
  {
    name: "orders_manage_distribution",
    path: "/orders/manage/distribution",
    component: orders_manage_distribution,
    meta: { layout: "basic-layout" },
  },
  //已发货
  {
    name: "orders_manage_finished",
    path: "/orders/manage/finished",
    component: orders_manage_finished,
    meta: { layout: "basic-layout" },
  },
  //已作废
  {
    name: "orders_manage_cancelled",
    path: "/orders/manage/cancelled",
    component: orders_manage_cancelled,
    meta: { layout: "basic-layout" },
  },
  //订单出库
  {
    name: "orders_manage_outstock",
    path: "/orders/manage/outstock",
    component: orders_manage_outstock,
    meta: { layout: "basic-layout" },
  },
  //手工录单
  {
    name: "orders_manage_manualrecord",
    path: "/orders/manage/manualrecord",
    component: orders_manage_manualrecord,
    meta: { layout: "basic-layout" },
  },
  {
    name: "orders_manage_tblist",
    path: "/orders/manage/tblist",
    component: orders_manage_tblist,
    meta: { layout: "empty-layout" },
  },
  //其他操作
  {
    name: "orders_tools",
    path: "/orders/tools",
    redirect: "/orders/tools/track",
    meta: { layout: "basic-layout" },
  },
  //补录单号
  {
    name: "orders_tools_supplementrecord",
    path: "/orders/tools/supplementrecord",
    component: orders_tools_supplementrecord,
    meta: { layout: "basic-layout" },
  },
  //仓库管理
  {
    name: "inventory",
    path: "/inventory",
    redirect: "/inventory/manage/inventoryview",
    meta: { layout: "basic-layout" },
  },
  {
    name: "inventory_manage",
    path: "/inventory/manage",
    redirect: "/inventory/manage/inventoryview",
    meta: { layout: "basic-layout" },
  },
  //库存查看
  {
    name: "inventory_manage_inventoryview",
    path: "/inventory/manage/inventoryview",
    component: inventory_manage_inventoryview,
    meta: { layout: "basic-layout" },
  },
  //库存盘点
  {
    name: "inventory_manage_check",
    path: "/inventory/manage/check",
    component: inventory_manage_check,
    meta: { layout: "basic-layout" },
  },
  //库龄查看
  {
    name: "inventory_manage_inventoryage",
    path: "/inventory/manage/inventoryage",
    component: inventory_manage_inventoryage,
    meta: { layout: "basic-layout" },
  },
  //缺货商品
  {
    name: "inventory_manage_stockout",
    path: "/inventory/manage/stockout",
    component: inventory_manage_stockout,
    meta: { layout: "basic-layout" },
  },
  //智能补货单
  {
    name: "inventory_deliver_replenishment",
    path: "/inventory/deliver/replenishment",
    component: inventory_deliver_replenishment,
    meta: { layout: "basic-layout" },
  },
  //发货计划
  {
    name: "inventory_deliver_plan",
    path: "/inventory/deliver/plan",
    component: inventory_deliver_plan,
    meta: { layout: "basic-layout" },
  },
  //FBA货件
  {
    name: "inventory_deliver_FBA",
    path: "/inventory/deliver/FBA",
    component: inventory_deliver_FBA,
    meta: { layout: "basic-layout" },
  },
  //FBA追踪
  {
    name: "inventory_deliver_fbatrack",
    path: "/inventory/deliver/fbatrack",
    component: inventory_deliver_fbatrack,
    meta: { layout: "basic-layout" },
  },
  //海外仓发货
  {
    name: "inventory_deliver_oversea",
    path: "/inventory/deliver/oversea",
    component: inventory_deliver_oversea,
    meta: { layout: "basic-layout" },
  },
  //仓库管理
  {
    name: "inventory_warehouse",
    path: "/inventory/warehouse",
    redirect: "/inventory/warehouse/list",
    meta: { layout: "basic-layout" },
  },
  //仓库列表
  {
    name: "inventory_warehouse_list",
    path: "/inventory/warehouse/list",
    component: inventory_warehouse_list,
    meta: { layout: "basic-layout" },
  },
  //海外仓
  {
    name: "inventory_warehouse_oversea",
    path: "/inventory/warehouse/oversea",
    component: inventory_warehouse_oversea,
    meta: { layout: "basic-layout" },
  },
  //物流渠道
  {
    name: "logistics",
    path: "/logistics",
    redirect: "/logistics/supplier/authorize",
    meta: { layout: "basic-layout" },
  },
  {
    name: "logistics_supplier",
    path: "/logistics/supplier",
    redirect: "/logistics/supplier/authorize",
    meta: { layout: "basic-layout" },
  },
  //物流商授权
  {
    name: "logistics_supplier_authorize",
    path: "/logistics/supplier/authorize",
    component: logistics_supplier_authorize,
    meta: { layout: "basic-layout" },
  },
  //物流订单统计
  {
    name: "logistics_supplier_orderstatics",
    path: "/logistics/supplier/orderstatics",
    component: logistics_supplier_orderstatics,
    meta: { layout: "basic-layout" },
  },
  //物流追踪
  {
    name: "logistics_supplier_track",
    path: "/logistics/supplier/track",
    component: logistics_supplier_track,
    meta: { layout: "basic-layout" },
  },
  //物流明细
  {
    name: "logistics_supplier_fee",
    path: "/logistics/supplier/fee",
    component: logistics_supplier_fee,
    meta: { layout: "basic-layout" },
  },
  //采购管理
  {
    name: "purchase",
    path: "/purchase",
    redirect: "/purchase/manage/plan",
    meta: { layout: "basic-layout" },
  },
  {
    name: "purchase_manage",
    path: "/purchase/manage",
    redirect: "/purchase/manage/plan",
    meta: { layout: "basic-layout" },
  },
  //采购计划
  {
    name: "purchase_manage_plan",
    path: "/purchase/manage/plan",
    component: purchase_manage_plan,
    meta: { layout: "basic-layout" },
  },
  //采购单管理
  {
    name: "purchase_manage_order",
    path: "/purchase/manage/order",
    component: purchase_manage_order,
    meta: { layout: "basic-layout" },
  },
  //采购单入库
  {
    name: "purchase_manage_instock",
    path: "/purchase/manage/instock",
    component: purchase_manage_instock,
    meta: { layout: "basic-layout" },
  },
  //订单缺货匹配
  {
    name: "purchase_manage_ordermatch",
    path: "/purchase/manage/ordermatch",
    component: purchase_manage_ordermatch,
    meta: { layout: "basic-layout" },
  },
  //采购统计
  {
    name: "purchase_manage_statics",
    path: "/purchase/manage/statics",
    component: purchase_manage_statics,
    meta: { layout: "basic-layout" },
  },
  //供应商管理
  {
    name: "purchase_supplier",
    path: "/purchase/supplier",
    redirect: "/purchase/supplier/list",
    meta: { layout: "basic-layout" },
  },
  {
    name: "purchase_supplier_list",
    path: "/purchase/supplier/list",
    component: purchase_supplier_list,
    meta: { layout: "basic-layout" },
  },
  {
    name: "purchase_supplier_1688",
    path: "/purchase/supplier/1688",
    component: purchase_supplier_1688,
    meta: { layout: "basic-layout" },
  },
  //报表管理
  {
    name: "operation",
    path: "/operation",
    redirect: "/operation/sale",
    meta: { layout: "basic-layout" },
  },
  //销售报表
  {
    name: "operation_sale",
    path: "/operation/sale",
    redirect: "/operation/sale/income",
    meta: { layout: "basic-layout" },
  },
  //收支明细
  {
    name: "operation_sale_income",
    path: "/operation/sale/income",
    component: operation_sale_income,
    meta: { layout: "basic-layout" },
  },
  //收支报表
  {
    name: "operation_sale_incomereport",
    path: "/operation/sale/incomereport",
    component: operation_sale_incomereport,
    meta: { layout: "basic-layout" },
  },
  //订单状态报表
  {
    name: "operation_sale_orderstate",
    path: "/operation/sale/orderstate",
    component: operation_sale_orderstate,
    meta: { layout: "basic-layout" },
  },
  //财务报表
  {
    name: "operation_finance",
    path: "/operation/finance",
    redirect: "/operation/finance/profit",
    meta: { layout: "basic-layout" },
  },
  //利润报表
  {
    name: "operation_finance_profit",
    path: "/operation/finance/profit",
    component: operation_finance_profit,
    meta: { layout: "basic-layout" },
  },
  //数据分析
  {
    name: "operation_analysis",
    path: "/operation/analysis",
    redirect: "/operation/analysis/sales",
    meta: { layout: "basic-layout" },
  },
  //产品表现
  {
    name: "operation_analysis_sales",
    path: "/operation/analysis/sales",
    component: operation_analysis_sales,
    meta: { layout: "basic-layout" },
  },
  //店铺表现
  {
    name: "operation_analysis_shops",
    path: "/operation/analysis/shops",
    component: operation_analysis_shops,
    meta: { layout: "basic-layout" },
  },
  //经营看板
  {
    name: "operation_analysis_opdata",
    path: "/operation/analysis/opdata",
    component: operation_analysis_opdata,
    meta: { layout: "basic-layout" },
  },
  //第三方收款
  //收款账号管理
  {
    name: "operation_payee_account",
    path: "/operation/payee/account",
    component: operation_payee_account,
    meta: { layout: "basic-layout" },
  },
  //交易明细
  {
    name: "operation_payee_list",
    path: "/operation/payee/list",
    component: operation_payee_list,
    meta: { layout: "basic-layout" },
  },
  //供应链金融
  {
    name: "operation_supplychain_finance",
    path: "/operation/supplychain/finance",
    component: operation_supplychain_finance,
    meta: { layout: "basic-layout" },
  },
  //客服服务
  {
    name: "customerservice",
    path: "/customerservice",
    redirect: "/customerservice/customer",
    meta: { layout: "basic-layout" },
  },
  {
    name: "customerservice_customer",
    path: "/customerservice/customer",
    redirect: "/customerservice/customer/customers",
    meta: { layout: "basic-layout" },
  },
  //客户管理
  {
    name: "customerservice_customers",
    path: "/customerservice/customer/customers",
    component: customerservice_customers,
    meta: { layout: "basic-layout" },
  },
  //客户消息管理
  {
    name: "customerservice_replymessage",
    path: "/customerservice/customer/replymessage",
    component: customerservice_replymessage,
    meta: { layout: "basic-layout" },
  },
  //消息回复模板
  {
    name: "customerservice_replytemplate",
    path: "/customerservice/customer/replytemplate",
    component: customerservice_replytemplate,
    meta: { layout: "basic-layout" },
  },
  //Walmart平台
  ///买家消息
  {
    name: "customerservice_WalmartBuyerMsg",
    path: "/customerservice/walmart/buyermessage",
    component: customerservice_WalmartBuyerMsg,
    meta: { layout: "basic-layout" },
  },
  ///平台消息
  {
    name: "customerservice_WalmartSysMsg",
    path: "/customerservice/walmart/sysmessage",
    component: customerservice_WalmartSysMsg,
    meta: { layout: "basic-layout" },
  },
  //店铺管理
  {
    name: "shops",
    path: "/shops",
    redirect: "/shops/manage/authorize",
    meta: { layout: "basic-layout" },
  },
  {
    name: "shops_manage",
    path: "/shops/manage",
    redirect: "/shops/manage/authorize",
    meta: { layout: "basic-layout" },
  },
  //店铺授权
  {
    name: "shops_manage_authorize",
    path: "/shops/manage/authorize",
    component: shops_manage_authorize,
    meta: { layout: "basic-layout" },
  },
  //店铺权限设置
  {
    name: "shops_manage_power",
    path: "/shops/manage/power",
    component: shops_manage_power,
    meta: { layout: "basic-layout" },
  },
  //产品采集
  {
    name: "shops_published_gather",
    path: "/shops/published/gather",
    component: shops_published_gather,
    meta: { layout: "basic-layout" },
  },
  {
    name: "shops_published_gatherform",
    path: "/shops/published/gatherform",
    component: shops_published_gatherform,
    meta: { layout: "empty-layout" },
  },
  ///产品库
  {
    name: "shops_published_product",
    path: "/shops/published/product",
    component: shops_published_product,
    meta: { layout: "basic-layout" },
  },
  //亚马逊多站点刊登
  {
    name: "shops_published_amazon_publishregion",
    path: "/shops/published/amazonpublishregion",
    component: shops_published_amazon_publishregion,
    meta: { layout: "basic-layout" },
  },
  {
    name: "shops_published_amazon_publishformregion",
    path: "/shops/published/amazonpublishformregion",
    component: shops_published_amazon_publishformregion,
    meta: { layout: "empty-layout" },
  },
  //速卖通刊登
  {
    name: "shops_published_aliexpress_publish",
    path: "/shops/published/aliexpresspublish",
    component: shops_published_aliexpress_publish,
    meta: { layout: "basic-layout" },
  },
  //图片库
  {
    name: "shops_published_pictures",
    path: "/shops/published/pictures",
    component: shops_published_pictures,
    meta: { layout: "basic-layout" },
  },
  //侵权词库
  {
    name: "shops_published_tortwords",
    path: "/shops/published/tortwords",
    component: shops_published_tortwords,
    meta: { layout: "basic-layout" },
  },
  //UPC管理
  {
    name: "shops_published_upc",
    path: "/shops/published/upc",
    component: shops_published_upc,
    meta: { layout: "basic-layout" },
  },
  //商标检索
  {
    name: "shops_published_tmsearch",
    path: "/shops/published/tmsearch",
    component: shops_published_tmsearch,
    meta: { layout: "basic-layout" },
  },
  //专利检索
  {
    name: "shops_published_uspto",
    path: "/shops/published/uspto",
    component: shops_published_uspto,
    meta: { layout: "basic-layout" },
  },
  //Amazon搜索词报告
  {
    name: "shops_published_searchterm",
    path: "/shops/published/searchterm",
    component: shops_published_searchterm,
    meta: { layout: "basic-layout" },
  },
  //Amazon销售排名
  {
    name: "shops_published_bestsellers",
    path: "/shops/published/bestsellers",
    component: shops_published_bestsellers,
    meta: { layout: "basic-layout" },
  },
  //Amazon搜索词报表
  {
    name: "shops_published_searchtermreport",
    path: "/shops/published/searchtermreport",
    component: shops_published_searchtermreport,
    meta: { layout: "basic-layout" },
  },
  //多店铺广告预览
  {
    name: "shops_advertising_adPreview",
    path: "/shops/advertising/adPreview",
    component: shops_advertising_adPreview,
    meta: { layout: "basic-layout" },
  },
  //广告列表
  {
    name: "shops_advertising_list",
    path: "/shops/advertising/list",
    component: shops_advertising_list,
    meta: { layout: "basic-layout" },
  },
  //广告-关键词
  {
    name: "shops_advertising_keyword",
    path: "/shops/advertising/keyword",
    component: shops_advertising_keyword,
    meta: { layout: "basic-layout" },
  },
  //广告-SKU
  {
    name: "shops_advertising_sku",
    path: "/shops/advertising/sku",
    component: shops_advertising_sku,
    meta: { layout: "basic-layout" },
  },
  //广告-竞品采集
  {
    name: "shops_advertising_competitors_collect",
    path: "/shops/advertising/competitors_collect",
    component: shops_advertising_competitors_collect,
    meta: { layout: "basic-layout" },
  },
  {
    name: "shops_advertising_competitors_form",
    path: "/shops/advertising/competitors_form",
    component: shops_advertising_competitors_form,
    meta: { layout: "empty-layout" },
  },
  //广告-分析
  // {
  //   name: "shops_advertising_analyze",
  //   path: "/shops/advertising/analyze",
  //   component: shops_advertising_analyze,
  //   meta: { layout: "basic-layout" },
  // },
  //账户设置
  {
    name: "accountsetting",
    path: "/accountsetting",
    redirect: "/accountsetting/accounts/list",
    meta: { layout: "basic-layout" },
  },
  {
    name: "accountsetting_accounts",
    path: "/accountsetting/accounts",
    redirect: "/accountsetting/accounts/list",
    meta: { layout: "basic-layout" },
  },
  //员工列表
  {
    name: "accountsetting_accounts_list",
    path: "/accountsetting/accounts/list",
    component: accountsetting_accounts_list,
    meta: { layout: "basic-layout" },
  },
  //角色设置
  {
    name: "accountsetting_accounts_roles",
    path: "/accountsetting/accounts/roles",
    component: accountsetting_accounts_roles,
    meta: { layout: "basic-layout" },
  },
  //权限设置
  {
    name: "accountsetting_accounts_permissions",
    path: "/accountsetting/accounts/permissions",
    component: accountsetting_accounts_permissions,
    meta: { layout: "basic-layout" },
  },
  //学员管理
  {
    name: "accountsetting_trainee",
    path: "/accountsetting/trainee",
    redirect: "/accountsetting/trainee/list",
    meta: { layout: "basic-layout" },
  },
  //学员账号
  {
    name: "accountsetting_trainee_list",
    path: "/accountsetting/trainee/list",
    component: accountsetting_trainee_list,
    meta: { layout: "basic-layout" },
  },
  //学员权限
  {
    name: "accountsetting_trainee_power",
    path: "/accountsetting/trainee/power",
    component: accountsetting_trainee_power,
    meta: { layout: "basic-layout" },
  },
  //商户信息
  {
    name: "accountsetting_org",
    path: "/accountsetting/org",
    component: accountsetting_org_info,
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev",
    path: "/dev",
    redirect: "/dev/amazon/feeds",
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_amazon",
    path: "/dev/amazon",
    redirect: "/dev/amazon/feeds",
    meta: { layout: "basic-layout" },
  },
  //Amazon Feeds API
  {
    name: "dev_amazon_feeds",
    path: "/dev/amazon/feeds",
    component: dev_amazon_feeds,
    meta: { layout: "basic-layout" },
  },
  //Amazon Reports API
  {
    name: "dev_amazon_reports",
    path: "/dev/amazon/reports",
    component: dev_amazon_reports,
    meta: { layout: "basic-layout" },
  },
  //Amazon Listings API
  {
    name: "dev_amazon_listings",
    path: "/dev/amazon/listings",
    component: dev_amazon_listings,
    meta: { layout: "basic-layout" },
  },
  //Amazon Shipping API
  {
    name: "dev_amazon_shipping",
    path: "/dev/amazon/shipping",
    component: dev_amazon_shipping,
    meta: { layout: "basic-layout" },
  },
  //Amazon Browse Tree
  {
    name: "dev_amazon_browsetree",
    path: "/dev/amazon/browsetree",
    component: dev_amazon_browsetree,
    meta: { layout: "basic-layout" },
  },
  //Amazon Listing Form Region
  {
    name: "dev_amazon_listingformregion",
    path: "/dev/amazon/listingformregion",
    component: dev_amazon_listingformregion,
    meta: { layout: "basic-layout" },
  },
  //Amazon Listing Publish Region
  {
    name: "dev_amazon_listingpublishregion",
    path: "/dev/amazon/listingpublishregion",
    component: shops_published_amazon_publishregion,
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_amazon_clawler",
    path: "/dev/amazon/clawler",
    component: dev_amazon_clawler,
    meta: { layout: "empty-layout" },
  },
  {
    name: "dev_shopee",
    path: "/dev/shopee",
    redirect: "/dev/shopee/media",
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_shopee_media",
    path: "/dev/shopee/media",
    component: dev_shopee_media,
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_shopee_listingform",
    path: "/dev/shopee/listingform",
    component: dev_shopee_listingform,
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_tools",
    path: "/dev/tools",
    component: dev_tools,
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_ai",
    path: "/dev/ai",
    redirect: "/dev/ai/generator",
    meta: { layout: "basic-layout" },
  },
  {
    name: "dev_ai_generator",
    path: "/dev/ai/generator",
    component: dev_ai_generator,
    meta: { layout: "basic-layout" },
  },
  //登录
  {
    name: "login",
    path: "/login",
    component: process.env.DEPLOY_TARGET === "an2ji" ? LoginA2J : Login,
    meta: { layout: "empty-layout", requireAuth: false },
  },
  //注册
  {
    name: "register",
    path: "/register",
    component: process.env.DEPLOY_TARGET === "an2ji" ? RegisterA2J : Register,
    meta: { layout: "empty-layout", requireAuth: false },
  },
  //404
  {
    name: "notfound",
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: { layout: "empty-layout", requireAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});
router.beforeEach((to, from, next) => {
  console.log(from, to);
  NProgress.start(); // 路由跳转前钩子函数中 - 执行进度条开始加载
  let v = localStorage.getItem("zberp_client.version") || "0";
  api
    .get(`/api/erp/init?t=${Date.now()}&v=${v}`)
    .then((data) => {
      // console.log(data);
      NProgress.done();
      if (data.code === "newversion") {
        localStorage.setItem("zberp_client.version", data.version);
        window.location.href = to.fullPath;
        return;
      }
      setTimeout(async () => {
        let {
          userid = "",
          username = "",
          name = "",
          level = "",
          role = "",
          permissions = [],
          needlogin,
        } = data.data;
        await utils.setStore({
          userid,
          username,
          name,
          level,
          role,
          permissions,
        });
        if (needlogin) localStorage.clear();
        if (
          !localStorage.getItem("zberp_client.token") &&
          to.meta?.requireAuth !== false
        ) {
          //需要登录 无token
          return next("/login");
        }
        if (
          localStorage.getItem("zberp_client.token") &&
          (to.path === "/login" || to.path === "/")
        ) {
          let { ae1688authcode, ae1688reauth } = Cookies.get();
          console.log("cookies", Cookies.get());
          if (ae1688authcode) {
            Cookies.remove("ae1688authcode");
            Cookies.remove("ae1688reauth");
            let query = { code: ae1688authcode };
            if (ae1688reauth) query.reauth = ae1688reauth;
            return next({ path: "/purchase/supplier/1688", query });
          }
          return next("/workbench");
        }
        if (to.meta?.requireAuth !== false) {
          //需要登录
          store.dispatch("menu/checkMenu", to.path).then((authorized) => {
            if (authorized === true) next();
            else if (typeof authorized === "string") next(authorized);
            else next("/workbench");
          });
        } else if (to.path === "/") {
          if (
            window.location.host.indexOf("zdterp.com") > -1 ||
            window.location.host.indexOf("an2ji.com") > -1
          ) {
            window.location.href = "/index.htm";
          } else {
            next("/login");
          }
        } else {
          next();
        }
      }, 0);
    })
    .catch((e) => {
      NProgress.done();
      console.error(e);
    });
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});
window._doRouterPush = (path) => {
  if (router.currentRoute.path === path) return;
  router.push({ path }).catch(console.error);
};
window._doRouterReplace = (path) => {
  if (router.currentRoute.path === path) return;
  router.replace({ path }).catch(console.error);
};

export default router;
