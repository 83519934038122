import { v4 as uuidv4 } from 'uuid';
import { Base64 } from 'js-base64';
import moment from 'moment';
moment.locale('zh_cn');
import _ from "underscore";
import axios from 'axios';
import { message, Modal } from "ant-design-vue";
import Decimal from 'decimal.js';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import store from './store';
import PDFMerger from 'pdf-merger-js/browser';
import { copyText } from "vue3-clipboard";
import urlencode from "urlencode";
import ExcelJS from "exceljs";

function sliceFile(size) {
  let chunksize = 512 * 1024;//切片大小 512k
  let res = [];
  if (size <= chunksize) {
    res.push({ chunk: 0, chunks: 1 });
  } else {
    let chunks = size % chunksize == 0 ? Math.floor(size / chunksize) : Math.floor(size / chunksize) + 1;
    for (let i = 0; i < chunks; i++) {
      res.push({ chunk: i, chunks, from: chunksize * i, to: (i == chunks - 1) ? size : chunksize * (i + 1) });
    }
  }
  return res;
}
function makeUUID() {
  return uuidv4().replace(/-/g, '');
}
function dateTime(date, pattern = "YYYY-MM-DD") {
  date = moment(date);
  if (date.isValid()) return date.format(pattern);
  return "";
}
function fromNow(date) {
  return moment(date).fromNow();
}
function callPhone(phone) {
  let a = document.createElement("a"), //创建a标签
    e = document.createEvent("MouseEvents"); //创建鼠标事件对象
  e.initEvent("click", false, false); //初始化事件对象
  a.href = 'tel:' + phone; //设置下载地址
  a.target = "_blank";
  a.dispatchEvent(e); //给指定的元素，执行事件click事件
}
function sleep(miliseconds = 1500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, miliseconds);
  });
}
function toBase64String(str) {
  return Base64.encodeURI(str);
}
function shareVersion() {
  return moment().format('YYYYMMDDHHmmss');
}
function downloadBrowser({ name, href }) {
  var a = document.createElement("a"),
    e = document.createEvent("MouseEvents");
  e.initEvent("click", false, false);
  a.href = href;
  a.target = "_blank";
  if (name) a.download = encodeURIComponent(name);
  a.dispatchEvent(e);
}
function randomPassword() {
  return (Math.random() + "").substring(2, 8);
}
function alert(text) {
  return Modal.info({ title: "提示", content: text });
}
function isDecimal(n) {
  try {
    new Decimal(n);
    return true;
  } catch (e) { console.error(e) }
  return false;
}
function d(n = 0) {
  return new Decimal(n);
}
function ds(n, l = 0) {
  if (!n) return "";
  return d(n).toFixed(l);
}
function dsp(n, l = 0) {
  if (!n) return "";
  let v = d(n).times(100).toFixed(l);
  if (v === 'NaN') return '-';
  return v + '%';
}
function textToColor(str) {
  if (!str || str.length === 0)
    return false;
  for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));
  for (var j = 0, color = '#'; j < 3; color += ('00' + ((hash >> j++ * 2) & 0xFF).toString(16)).slice(-2));
  return color;
}
async function setStore({ userid, username, name, level, role, permissions }) {
  localStorage.setItem("zberp_client.userid", userid);
  localStorage.setItem("zberp_client.username", username);
  localStorage.setItem("zberp_client.name", name);
  localStorage.setItem("zberp_client.level", level);
  localStorage.setItem("zberp_client.role", role);
  localStorage.setItem("zberp_client.permissions", JSON.stringify(permissions));
  await store.dispatch("user/setUser", { userid, username, name, level, role, permissions });
}
function formatMoney(number, places, symbol, thousand, decimal) {
  number = Number(number) || 0;
  places = !isNaN(places = Math.abs(places)) ? places : 0;
  symbol = symbol !== undefined ? symbol : "￥";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "";
  var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
}
function getHtml(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'text'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      console.error(error);
      resolve(null);
    })
  })
}
function getBlob(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'blob'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      console.error(error);
      resolve(null);
    })
  })
}
function saveBlob(blob, name) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, name);
    message.destroy();
    message.success("下载完成");
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob); // 创建对象url
    link.download = name;
    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href); // 通过调用 URL.createObjectURL() 创建的 URL 对象
    message.destroy();
    message.success("下载完成");
  }
}
function download(url, name = '') {
  message.loading("正在下载", 0);
  return new Promise(resolve => {
    getBlob(url).then((blob) => {
      saveBlob(blob, name);
      resolve();
    }).catch(() => {
      message.destroy();
      message.fail("下载失败");
      resolve();
    });
  })
}
/**
 * 批量打包zip包下载
 * @param files Array [{url: 下载文件的路径, name: 下载文件名称}]
 * @param zipname zip文件名
 */
async function downloadZip(files, zipname) {
  message.loading("正在下载", 0);
  const zip = new JSZip();
  for (let i = 0; i < files.length; i++) {
    let { name, url } = files[i];
    let data = await getBlob(url);
    if (data) {
      zip.file(name, data, { binary: true })
    }
  }
  console.log(zip);
  zipname = zipname || "打包下载";
  const content = await zip.generateAsync({ type: 'blob' });
  FileSaver.saveAs(content, `${zipname}.zip`); // 利用file-saver保存文件
  message.destroy();
  message.success("下载完成");
}
async function mergePDF(urls) {
  message.loading("正在下载", 0);
  const merger = new PDFMerger();
  let files = await Promise.all(_.map(urls, n => getBlob(n)));
  console.log(files);
  files = _.filter(files, n => !!n);
  for (let i = 0; i < files.length; i++) {
    await merger.add(files[i]);
  }
  const mergedPdf = await merger.saveAsBlob();
  message.destroy();
  return mergedPdf;
}
function speak(text) {
  if ("speechSynthesis" in window) {
    var synthesis = window.speechSynthesis;
    // Get the first `en` language voice in the list
    var voices = synthesis.getVoices().filter(function (voice) {
      console.log(voice);
      return voice.lang === "zh-CN";
    });
    console.log(voices);
    // Create an utterance object
    var utterance = new SpeechSynthesisUtterance(text);

    // Set utterance properties
    utterance.voice = voices[0];
    utterance.pitch = 1.5;
    utterance.rate = 1.25;
    utterance.volume = 0.8;

    // Speak the utterance
    synthesis.speak(utterance);
  } else {
    console.log("Text-to-speech not supported.");
  }
}
function sublist(list, n = 100) {
  let data = [];
  let item = [];
  for (let i = 0; i < list.length; i++) {
    item.push(list[i]);
    if (item.length === n || i === list.length - 1) {
      data.push([...item]);
      item = [];
    }
  }
  return data;
}
function sizeToText(size) {
  let b = 1;
  let k = 1024;
  let m = 1024 * k;
  if (size >= m) return d(size).dividedBy(m).toFixed(2) + 'mb';
  else if (size >= k) return d(size).dividedBy(k).toFixed(2) + 'kb';
  else if (size >= b) return size + 'b';
  return '';
}
function twidth(columns) {
  let total_width = 0;
  for (let i = 0; i < columns.length; i++) {
    let { width = 100, children } = columns[i];
    if (children?.length > 0) {
      for (let j = 0; j < children.length; j++) {
        let { width = 100 } = children[j];
        total_width += width;
      }
    } else {
      total_width += width;
    }
  }
  return total_width;
}
function doCopy(text) {
  copyText(text, undefined, (error, event) => {
    if (error) {
      console.log(error)
    } else {
      message.success(`复制成功：${text}`);
      console.log(event)
    }
  });
}
function choose(arr, size) {
  var allResult = [];
  (function _ppchoose_(arr, size, result) {
    var arrLen = arr.length;
    if (size > arrLen) {
      return;
    }
    if (size == arrLen) {
      allResult.push([].concat(result, arr))
    } else {
      for (var i = 0; i < arrLen; i++) {
        var newResult = [].concat(result);
        newResult.push(arr[i]);
        if (size == 1) {
          allResult.push(newResult);
        } else {
          var newArr = [].concat(arr);
          newArr.splice(0, i + 1);
          _ppchoose_(newArr, size - 1, newResult);
        }
      }
    }
  })(arr, size, []);
  return allResult;
}
function u8length(text) {
  const code = encodeURIComponent(text);
  const bytes = [];
  for (let i = 0; i < code.length; i++) {
    const c = code.charAt(i);
    if (c === '%') {
      const hex = code.charAt(i + 1) + code.charAt(i + 2);
      const hexval = parseInt(hex, 16);
      bytes.push(hexval);
      i += 2;
    } else {
      bytes.push(c.charCodeAt(0));
    }
  }
  return bytes;
}
function downloadImages(list) {
  let files = {};
  for (let i = 0; i < list.length; i++) {
    let { item_sku = "_NULL_", platform, images, variation_list } = list[i];
    if (!files[item_sku]) files[item_sku] = { item_sku, list: [] }
    if (variation_list.length > 0) {
      if (platform === 'amz') {
        let n = 0, m = 0;
        for (let j = 0; j < variation_list.length; j++) {
          let { images } = variation_list[j];
          for (let k = 0; k < images.length; k++) {
            let url = images[k];
            if (k === 0) {
              files[item_sku].list.push({ name: n === 0 ? `橱窗主图.jpg` : `橱窗图${n + 1}.jpg`, url });
            } else {
              files[item_sku].list.push({ name: `多属性图${++m}.jpg`, url });
            }
          }
          n++;
        }
      } else if (platform === '1688') {
        files[item_sku].list.push(..._.map(images, (url, n) => {
          return {
            name: n === 0 ? `橱窗主图.jpg` : `橱窗图${n + 1}.jpg`, url,
          }
        }));
        let n = 1;
        for (let j = 0; j < variation_list.length; j++) {
          let { images } = variation_list[j];
          files[item_sku].list.push(..._.map(images, url => {
            return {
              name: `多属性图${n++}.jpg`, url,
            }
          }));
        }
      }
    } else {
      files[item_sku].list.push(..._.map(images, (url, n) => {
        return {
          name: n === 0 ? `橱窗主图.jpg` : `橱窗图${n + 1}.jpg`, url,
        }
      }));
    }
  }
  files = _.values(files);
  let downloadlist = [];
  for (let i = 0; i < files.length; i++) {
    let { item_sku, list } = files[i];
    for (let j = 0; j < list.length; j++) {
      let { name, url } = list[j];
      if (item_sku && item_sku !== '_NULL_') name = `${item_sku}_${name}`;
      downloadlist.push({ name, url });
    }
  }
  console.log(downloadlist);
  return downloadZip(downloadlist);
}
async function saveListAsExcel(columns, list) {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "正店通ERP";
  workbook.created = new Date();
  const sheet = workbook.addWorksheet('Sheet1');
  sheet.columns = columns;
  sheet.addRows(list);
  const buffer = await workbook.xlsx.writeBuffer();
  const link = document.createElement("a");
  const blobData = new Blob([buffer], {
    type: "application/vnd.ms-excel;charset=utf-8;"
  });
  link.download = `图片链接${Date.now()}.xlsx`;
  link.href = URL.createObjectURL(blobData);
  link.click();
}
function filterOption(input, option) {
  // console.log('filterOption', input, option);
  return option.value?.toUpperCase()?.indexOf(input.toUpperCase()) >= 0 || option.label?.toUpperCase()?.indexOf(input.toUpperCase()) >= 0;
}
export default {
  sliceFile,
  makeUUID,
  uuid: makeUUID,
  dateFormat: dateTime,
  dateTime,
  fromNow,
  callPhone,
  toBase64String,
  downloadBrowser,
  sleep,
  randomPassword,
  shareVersion,
  alert,
  isDecimal,
  d,
  ds,
  dsp,
  moment,
  textToColor,
  setStore,
  formatMoney,
  getHtml,
  getBlob,
  saveBlob,
  download,
  downloadZip,
  mergePDF,
  speak,
  sublist,
  sizeToText,
  twidth,
  doCopy,
  urlencode,
  choose,
  u8length,
  downloadImages,
  saveListAsExcel,
  filterOption,
};