<template>
  <a-row :gutter="16">
    <a-col :span="3">
      <a-form-item label="报告范围" name="reportPeriod">
        <a-select v-model:value="queryForm.reportPeriod" :options="reportPeriods" style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="3">
      <a-form-item label="类目" name="sort">
        <a-select v-model:value="queryForm.sort" :options="Categorys" style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="4">
      <a-form-item label="选择日期" name="reportDay">
        <a-date-picker v-model:value="queryForm.reportDay" :locale="locale" value-format="YYYY-MM-DD"
          style="width:100%;" />
      </a-form-item>
    </a-col>
    <a-col :span="2">
      <a-button type="primary" @click="getData"> 应用 </a-button>
    </a-col>
    <!-- <a-col :span="12">
      <a-button v-if="report" type="link" @click="downloadReport" :loading="downloading">
        {{ report.originalname }}
        <DownloadOutlined />
      </a-button>
    </a-col> -->
  </a-row>
  <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: false, showTotal: (total) => ``, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'category'">
        <a-tooltip>
          <template #title> {{ record.category }} </template>
          <div class="in1line"> {{ record.category }} </div>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'image'">
        <a-image width="100%" height="100px" :src="record.image" style="object-fit: contain;height:100%;">
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'clickedItemName'">
        <a-tooltip>
          <template #title> {{ record.name }} </template>
          <div class="in1line"> <a :href="`https://www.amazon.com/dp/${record.asin}`" target="_blank">{{
            record.name }}</a> </div>
        </a-tooltip>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="reportModal" title="搜索词报告" width="960px" :footer="null">
    <a-table bordered :columns="rcolumns" :data-source="reports" :loading="showing" row-key="_id" :row-selection="{
      selectedRowKeys,
      onChange: (v) => { selectedRowKeys = v },
      getCheckboxProps: (record) => ({ disabled: record.state !== 'success' })
    }" size="small">
      <template #title>
        <a-row :gutter="8">
          <a-col :span="3">
            <a-form-item name="reportPeriod">
              <a-select v-model:value="reportForm.reportPeriod" :options="reportPeriods" placeholder="报告范围"
                style="width:100%;" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item name="reportDay">
              <a-date-picker v-model:value="reportForm.reportDay" :locale="locale" value-format="YYYY-MM-DD"
                placeholder="选择日期" style="width:100%;" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item name="shopid">
              <a-select v-model:value="reportForm.shopid" :options="shops" placeholder="搜索店铺" style="width:100%;"
                show-search :filter-option="false" :default-active-first-option="false" :show-arrow="false"
                :not-found-content="null" @search="searchShop" @change="changeShop"></a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-space>
              <a-button type="primary" @click="addReport"> 创建 </a-button>
              <a-button type="primary" @click="showReports"> 刷新 </a-button>
              <a-button type="danger" @click="combineReports" :loading="combining"
                :disabled="selectedRowKeys.length === 0"> 合并 </a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'reportPeriod'">
          {{ getperiod(record.reportPeriod) }}
        </template>
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 'pending'" color="default">排队中</a-tag>
          <a-tag v-if="record.state === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-if="record.state === 'saving'" color="orange">正在生成</a-tag>
          <a-tag v-if="record.state === 'success'" color="success">成功</a-tag>
          <a-tag v-if="record.state === 'fail'" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="record.state === 'fail'" href="javascript:;" class="text-error" @click="resetReport(record._id)">
              重试
            </a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="combineModal" title="搜索词合并报告" width="960px" :footer="null">
    <a-table bordered :columns="ccolumns" :data-source="combines" :loading="showing" size="small">
      <template #title>
        <a-space>
          <a-button type="primary" @click="showCombines"> 刷新 </a-button>
        </a-space>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 'pending'" color="default">排队中</a-tag>
          <a-tag v-if="record.state === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-if="record.state === 'success'" color="success">成功</a-tag>
          <a-tag v-if="record.state === 'fail'" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="record.state === 'fail'" href="javascript:;" class="text-error" @click="resetCombine(record._id)">
              重试
            </a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownloadOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useStore } from "vuex";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
const columns = [
  { title: '类目', dataIndex: 'category', width: 100, fixed: 'left' },
  { title: '销售排名', dataIndex: 'rank', width: 80 },
  { title: '价格', dataIndex: 'price', width: 80 },
  { title: '缩略图', dataIndex: 'image', width: 100 },
  { title: '商品名称', dataIndex: 'clickedItemName', width: 400 },
  { title: '统计日期', dataIndex: 'statistics_time', width: 100, fixed: 'right' },
];
const rcolumns = [
  { title: '报告范围', dataIndex: 'reportPeriod', width: 100, },
  { title: '开始日期', dataIndex: 'dataStartTime', width: 100, },
  { title: '结束日期', dataIndex: 'dataEndTime', width: 100, },
  { title: '请求日期', dataIndex: 'req_time', width: 100, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100 },
];
const ccolumns = [
  { title: '开始日期', dataIndex: 'start', width: 100, },
  { title: '结束日期', dataIndex: 'end', width: 100, },
  { title: '请求日期', dataIndex: 'req_time', width: 100, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100 },
];
const { proxy } = getCurrentInstance();
const store = useStore();
const queryForm = ref({
  reportPeriod: "DAY",
  sort: 0,
  reportDay: undefined,
  searchTerm: '',
});
const reportPeriods = ref([
  { value: "DAY", label: "每日" }
]);
const Categorys = ref([
  { value: 0, label: "亚马逊设备与配件" },
  { value: 1, label: "亚马逊续费" },
  { value: 2, label: "家电" },
  { value: 3, label: "应用程序和游戏" },
  { value: 4, label: "艺术、工艺品和缝纫" },
  { value: 5, label: "有声读物和原创作品" },
  { value: 6, label: "汽车" },
  { value: 7, label: "婴儿" },
  { value: 8, label: "美容与个人护理" },
  { value: 9, label: "图书" },
  { value: 10, label: "相机及摄影产品" },
  { value: 11, label: "CD和黑胶唱片" },
  { value: 12, label: "手机及配件" },
  { value: 13, label: "服装鞋类珠宝" },
  { value: 14, label: "收藏硬币" },
  { value: 15, label: "电脑及配件" },
  { value: 16, label: "数字教育资源" },
  { value: 17, label: "数字音乐" },
  { value: 18, label: "电子产品" },
  { value: 19, label: "娱乐收藏品" },
  { value: 20, label: "礼品卡" },
  { value: 21, label: "杂货和美食" },
  { value: 22, label: "手工制品" },
  { value: 23, label: "健康家居" },
  { value: 24, label: "家居和厨房" },
  { value: 25, label: "工业和科学" },
  { value: 26, label: "Kindle商店" },
  { value: 27, label: "厨房和餐厅" },
  { value: 28, label: "电影和电视" },
  { value: 29, label: "乐器" },
  { value: 30, label: "办公产品" },
  { value: 31, label: "露台、草坪和花园" },
  { value: 32, label: "宠物用品" },
  { value: 33, label: "软件" },
  { value: 34, label: "运动与户外" },
  { value: 35, label: "体育收藏品" },
  { value: 36, label: "工具和家居装修" },
  { value: 37, label: "玩具和游戏" },
  { value: 38, label: "独特发现" },
  { value: 39, label: "视频游戏" }
]);
const page = ref(1);
const limit = ref(25);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const report = ref(null);
const downloading = ref(false);
const saving = ref(false);
const showing = ref(false);
const reports = ref([]);
const reportModal = ref(false);
const reportForm = ref({
  reportPeriod: "DAY",
  reportDay: undefined,
  shopid: undefined,
});
const combining = ref(false);
const combines = ref([]);
const combineModal = ref(false);
const selectedRowKeys = ref([]);

const username = computed(() => store.state.user.username);
const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/bestseller/bestlist", { page: page.value, limit: limit.value, statisticday: queryForm.value.reportDay, category: queryForm.value.sort, searchTerm: queryForm.value.searchTerm }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      // if (length.value > 0 && page.value > length.value) page.value = 1;
      // report.value = res.data.report;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const downloadReport = () => {
  downloading.value = true;
  proxy.$utils.download(report.value.remoteurl, report.value.originalname).then(() => {
    downloading.value = false;
  });
}
const showReports = () => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/reports").then((res) => {
    showing.value = false;
    if (res.code === 0) {
      reports.value = _.map(res.data.list, n => {
        return {
          ...n,
          req_time: proxy.$utils.dateFormat(n.update_time || n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
      reportModal.value = true;
      selectedRowKeys.value = [];
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const addReport = () => {
  try {
    if (!reportForm.value.reportPeriod) return message.error("报告范围不能为空");
    if (!reportForm.value.reportDay) return message.error("报告日期不能为空");
    if (!reportForm.value.shopid) return message.error("店铺不能为空");
    saving.value = true;
    proxy.$api.doAPI("/searchterm/addreport", { reportForm: reportForm.value }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        showReports();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
    message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
  }
}
const resetReport = (_id) => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/resetreport", { _id }).then((res) => {
    showing.value = false;
    if (res.code === 0) {
      showReports();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const combineReports = () => {
  if (selectedRowKeys.value.length === 0) return message.error("未选择报告");
  combining.value = true;
  proxy.$api.doAPI("/searchterm/combinereport", { ids: selectedRowKeys.value }).then((res) => {
    combining.value = false;
    if (res.code === 0) {
      reportModal.value = false;
      showCombines();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    combining.value = false;
    console.error(err);
  });
}
const showCombines = () => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/combines").then((res) => {
    showing.value = false;
    if (res.code === 0) {
      combines.value = _.map(res.data.list, n => {
        let states = _.uniq([
          n.state1,
          n.state2,
          n.state3,
          n.state4,
          n.state5,
          n.state6,
          n.state7,
        ]);
        if (states.length === 1) n.state = states[0];
        else {
          if (states.indexOf('processing') > -1) n.state = 'processing';
          else if (states.indexOf('fail') > -1) n.state = 'fail';
        }
        return {
          ...n,
          req_time: proxy.$utils.dateFormat(n.update_time || n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
      combineModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const resetCombine = (_id) => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/resetcombine", { _id }).then((res) => {
    showing.value = false;
    if (res.code === 0) {
      showCombines();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const getperiod = (value) => {
  return _.findWhere(reportPeriods.value, { value })?.label;
}
let timeout;
let currentValue = '';
const shops = ref([]);
const shopname = ref();
function getShops(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  function fake() {
    proxy.$api.doAPI("/searchterm/shops", { value }).then((res) => {
      if (res.code === 0) {
        if (currentValue === value) {
          callback(res.data.list);
        }
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      console.error(err);
    });
  }
  timeout = setTimeout(fake, 300);
}
const searchShop = val => {
  getShops(val, d => shops.value = d);
};
const changeShop = val => {
  shopname.value = val;
  getShops(val, d => shops.value = d);
};
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.text-warning {
  color: #e6a23c !important;
}
</style>
