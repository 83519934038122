<template>
  <div class="header">
    <div class="d-flex align-center mb-2">
      <a-row :gutter="10" style="width:100%;">
        <a-col :span="1">
          <a-checkbox v-model:checked="checkAllLive" :indeterminate="indeterminatelive" @change="onCheckAllLive">
            <span style="font-size: 16px;">Live</span>
          </a-checkbox>
        </a-col>
        <a-col>
          <a-checkbox-group v-model:value="status_live">
            <a-checkbox value="REGISTERED"><span style="font-size: 16px;">Registered</span></a-checkbox>
            <a-checkbox value="PENDING"><span style="font-size: 16px;">Pending</span></a-checkbox>
          </a-checkbox-group>
        </a-col>
      </a-row>
    </div>
    <div class="d-flex align-center mb-2">
      <a-row :gutter="10" style="width:100%;">
        <a-col :span="1">
          <a-checkbox v-model:checked="checkAllDead" :indeterminate="indeterminatedead" @change="onCheckAllDead">
            <span style="font-size: 16px;">Dead</span>
          </a-checkbox>
        </a-col>
        <a-col>
          <a-checkbox-group v-model:value="status_dead">
            <a-checkbox value="CANCELLED"><span style="font-size: 16px;">Cancelled</span></a-checkbox>
            <a-checkbox value="ABANDONED"><span style="font-size: 16px;">Abandoned</span></a-checkbox>
          </a-checkbox-group>
        </a-col>
      </a-row>
    </div>
    <div class="d-flex align-center mb-2">
      <a-row style="width:100%;" justify="space-between">
        <a-col :span="10">
          <a-input v-model:value="query_value" placeholder="文字商标(Wordmark)" style="width:100%;" />
        </a-col>
        <a-col :span="10">
          <a-space>
            <a-button type="primary" danger @click="search">检索</a-button>
            <a-button type="primary" @click="getData">刷新</a-button>
          </a-space>
        </a-col>
        <a-col :offset="2">
          <a-button type="primary" @click="download">导出</a-button>
        </a-col>
      </a-row>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" row-key="_id" :scroll="{ x: width, y: 800 }"
    :locale="{ emptyText: '暂无数据' }" bordered size="small" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }">
    <template #bodyCell="{ record, column, }">
      <template v-if="column.dataIndex === 'status'">
        <div v-if="record.alive">
          <a-tag color="green">LIVE</a-tag>
          <a-tag v-if="record.status !== '' && record.status === 'PENDING'">{{ record.status }}</a-tag>
          <a-tag v-else color="green">{{ record.status }}</a-tag>
        </div>
        <div v-else>
          <a-tag color="red">DEAD</a-tag>
          <a-tag v-if="record.status !== '' && record.status === 'ABANDONED'">{{ record.status }}</a-tag>
          <a-tag v-else-if="record.status !== ''" color="red">{{ record.status }}</a-tag>
        </div>
      </template>
      <template v-if="column.dataIndex === 'goods_services'">
        <a-popover placement="bottomLeft" :overlayStyle="{ width: '800px' }">
          <template #content>
            <span v-if="record.goods_services.length > 0">{{ record.goods_services.join('\n') }}
            </span>
            <span v-else>{{ record.goods_services[0] }}</span>
          </template>
          <span v-if="record.goods_services.length > 0">{{ record.goods_services.join('\n') }}
          </span>
          <span v-else>{{ record.goods_services[0] }}</span>
        </a-popover>
      </template>
      <template v-if="column.dataIndex === 'class'">
        <span>{{ record.class }}</span>
      </template>
      <template v-if="column.dataIndex === 'owners'">
        <a-tooltip placement="bottomLeft">
          <template #title>
            <span v-if="record.owners.length > 0">{{ record.owners.join('\n') }}</span>
            <span v-else>{{ record.owners[0] }}</span>
          </template>
          <span v-if="record.owners.length > 0">{{ record.owners.join('\n') }}</span>
          <span v-else>{{ record.owners[0] }}</span>
        </a-tooltip>
      </template>
    </template>
  </a-table>
</template>
<script>
import { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, InfoCircleFilled } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import { message, Modal } from "ant-design-vue";
const columns = [
  { title: 'Wordmark', dataIndex: 'wordmark', width: 100, },
  { title: 'Status', dataIndex: 'status', width: 80, },
  { title: 'Goods & Services', dataIndex: 'goods_services', width: 150, ellipsis: true, },
  { title: 'Class', dataIndex: 'class', width: 80, },
  { title: 'Serial', dataIndex: 'serial', width: 100, },
  { title: 'Owners', dataIndex: 'owners', width: 150, ellipsis: true, },
  // { title: '操作', dataIndex: 'operation', width: 150, align: 'center', fixed: 'right' },
];
export default defineComponent({
  name: "Tmsearch",
  components: { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, InfoCircleFilled },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      query_value: "",
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      dataLoading: false,
      data: [],
      checkAllLive: true,
      indeterminatelive: false,
      checkAllDead: true,
      indeterminatedead: false,
      status_live: ['REGISTERED', 'PENDING'],
      status_dead: ['CANCELLED', 'ABANDONED'],
      status_live_all: ['REGISTERED', 'PENDING'],
      status_dead_all: ['CANCELLED', 'ABANDONED'],
      status: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => { if (state.page !== 1) state.page = 1; else getData(); });
    watch(() => state.status_live, val => {
      state.indeterminatelive = !!val.length && val.length < state.status_live_all.length;
      state.checkAllLive = val.length === state.status_live_all.length;
    });
    watch(() => state.status_dead, val => {
      state.indeterminatedead = !!val.length && val.length < state.status_dead_all.length;
      state.checkAllDead = val.length === state.status_dead_all.length;
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      state.status = state.status_live.concat(state.status_dead);
      proxy.$api.doAPI("/awswsfsearch/list", { query_value: state.query_value, status: state.status, page: state.page, limit: state.limit }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const onCheckAllLive = (val) => {
      if (val.target.checked) {
        state.status_live = state.status_live_all;
      } else {
        state.status_live = [];
        state.status_dead = state.status_dead_all;
      }
      state.indeterminatelive = false;
    };
    const onCheckAllDead = (val) => {
      if (val.target.checked) {
        state.status_dead = state.status_dead_all;
      } else {
        state.status_dead = [];
        state.status_live = state.status_live_all;
      }
      state.indeterminatedead = false;
    };
    const search = () => {
      state.status = state.status_live.concat(state.status_dead);
      if (state.status.length === 0) return message.error("请至少选择一个检索条件！");
      state.dataLoading = true;
      proxy.$api.doAPI("/awswsfsearch/search", { query_value: state.query_value, status: state.status, page: state.page, limit: state.limit }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          if (res.data?.type) {
            state.data = res.data.list;
            state.length = res.data.length;
            state.count = res.data.count;
            if (state.length > 0 && state.page > state.length) state.page = 1;
          } else {
            state.query_value = "";
            getData();
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const download = () => {
      proxy.$api.doAPI("/awswsfsearch/download",).then((res) => {
        if (res.code === 0) {
          proxy.$utils.download(res.data.url, res.data.name);
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      search,
      download,
      onCheckAllLive,
      onCheckAllDead,
    }
  }
});
</script>
<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}

.loader-bar {
  width: 300px;
  height: 20px;
  margin: 0 auto;
  background: linear-gradient(-45deg, #4183d7 25%, #52b3d9 0, #52b3d9 50%, #4183d7 0, #4183d7 75%, #52b3d9 0, #52b3d9);
  background-size: 20px 20px;
  box-shadow: inset 0 10px 0 hsla(0, 0%, 100%, .2), 0 0 0 5px rgba(0, 0, 0, .2);
  border-radius: 15px;
  animation: moveBar 1.5s linear infinite;
}

@keyframes moveBar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 20px 20px;
  }
}
</style>
