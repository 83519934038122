<template>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col><span style="width:75px;">选择店铺：</span></a-col>
      <a-col :span="22">
        <div>
          <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops" style="width: 1000px" @change="(v) => changeShop(v)"></a-select>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">查询搜索：</span>
    <a-input-search v-model:value="skus" placeholder="输入SKU、ASIN、FNSKU查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="danger" @click="makePlan">生成发货计划</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleBulkClick">
            <a-menu-item-group title="批量编辑">
              <a-menu-item key="3" :disabled="selectedRowKeys.length === 0">批量编辑</a-menu-item>
              <a-menu-item key="4" :disabled="selectedRowKeys.length === 0">批量删除</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
        <a-button type="primary">
          批量操作
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
    <a-space>
      <a-button type="primary" @click="getData">刷新数据</a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="processImportExport">
            <a-menu-item key="1" :disabled="selectedRowKeys.length === 0">导出勾选</a-menu-item>
            <a-menu-item key="2">导出所有页</a-menu-item>
          </a-menu>
        </template>
        <a-button type="primary">
          导出数据
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'cover'">
        <a-image :src="record.cover || image_placeholder" :width="50" :height="50"
          style="object-fit: contain;height:100%;" :preview="false" />
      </template>
      <template v-if="column.dataIndex === 'info'">
        <a href="javascript:;">{{ record.sku }}</a>
        <p>{{ record.name_cn }}</p>
        <p style="color:#a0a3a6;">{{ record.number }}</p>
      </template>
      <template v-if="column.dataIndex === 'inventory'">
        <div v-for="inventory in record.inventorys" :key="inventory.slotid" class="d-flex">
          <span>{{ inventory.slot }}</span>
          (<a href="javascript:;">{{ inventory.quantity }}</a>)
        </div>
      </template>
      <template v-if="column.dataIndex === 'lasttime'">
        {{ record.lasttime }}
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" class="text-error">删除</a>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'cover', width: 80 },
  { title: 'ASIN/FNSKU', dataIndex: 'ASIN', width: 150, },
  { title: '商品信息', dataIndex: 'info', width: 200, },
  { title: '价格', dataIndex: 'price', width: 150, },
  { title: '店铺/国家', dataIndex: 'shop_country', width: 150, },
  { title: '库存｜在途｜不可售', dataIndex: 'inventory', width: 200, },
  { title: '7｜14｜30｜90天销量', dataIndex: 'sales', width: 200, },
  { title: '日均销量', dataIndex: 'date_sale', width: 150, },
  { title: '建议补货量', dataIndex: 'recommed_count', width: 150, },
  { title: '备注', dataIndex: 'mark', width: 150, },
  { title: '更新时间', dataIndex: 'lasttime', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right', },
];
export default defineComponent({
  name: 'InventoryDeliverReplenishment',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      warehouses0: [],
      warehouses1: [],
      warehouseid: "",
      skus: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      selectedRowKeys: [],
      stores: 'all',
      shops: [],
      shopid: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onMounted(() => {
    });
    onActivated(() => {
      getShops();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const getShops = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/product/shops", { platform: "" }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/inventory/list", {
        page: state.page,
        limit: state.limit,
        skus: state.skus,
        warehouseid: state.warehouseid,
        shopid: state.shopid,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.warehouses0 = res.data.warehouses0;
          state.warehouses1 = res.data.warehouses1;
          if (!state.warehouseid && res.data.warehouseid) {
            state.warehouseid = res.data.warehouseid;
          }
          state.data = _.map(res.data.list, n => {
            let total_amount = "";
            if (n.purchase_price && n.inventorys?.length > 0) {
              let quantity = _.reduce(n.inventorys, (a, b) => a + b.quantity, 0);
              total_amount = proxy.$d(n.purchase_price).times(quantity).toString();
            }
            return {
              ...n,
              total_amount,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          // state.shops = [..._.map(res.data.shops || [], n => ({ ...n, label: n.name, value: n._id }))];
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const changeShop = () => {
      getData();
    }
    const changeWarehouse = (e) => {
      console.log('changeWarehouse', e);
      if (state.page !== 1) state.page = 1;
      else getData();
    };
    const handleBulkClick = ({ key }) => {
      console.log('handleBulkClick', key);
      switch (key) {
        case "1":
        case "2":
        case "3":
        case "4":
          break;
      }
    }
    const processImportExport = ({ key, }) => {
      console.log('processImportExport', key);
    }
    const addToWarehouse = ({ key, }) => {
      console.log('addToWarehouse', key);
    }
    return {
      ...toRefs(state),
      formRef,
      columns,
      width,
      getData,
      changeWarehouse,
      handleBulkClick,
      processImportExport,
      addToWarehouse,
      image_placeholder: proxy.$constant.image_placeholder,
      changeShop,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

